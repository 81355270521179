// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-interview-page-view', function(Y) {
    "use strict";

    var Slang = Y.smst.Lang;


	/**
	 * This is the base class of all page views of the interview
	 */
	(function() {
		Y.CaseEditorAppInterviewPageView = Y.Base.create('CaseEditorAppInterviewPageView', Y.View, [], {


			// --- lifecycle methods ----------------------------------------------------------------------------------
			initializer: function() {
				var that = this,
					handlers = that.get('handlers');


				// we have to wait for the 'real' content to get inserted.
				handlers.push( that.after('pageContentChange', that._initPage, that));

				/**
				 * Our parent might fire this event. When it does we are simply going to recalculate and reload.
				 * The _save-method can be overwritten by extending classes though.
				 * (like in case-editor-app-interview-page-form)
				 */
				handlers.push( Y.on('*:save', that._save, that));

				/**
				 * If we are triggering a request which changes the server site state, we have to fire this event
				 * to let our parent app know about it and have it handle the needed updates.
				 */
				that.publish('updateState', {
					emitFacade: true,
					preventable: false
				});

				that.publish('nextPage', {
					emitFacade: true,
					preventable: false
				});


			},

			render : function(e) {
				this.fire('waiting',{msg:'Bitte warten …'});
				return this;
			},

			destructor : function() {
				var handlers = this.get('handlers');
				Y.log("destroying event handlers...", "DEBUG", "CaseEditorAppInterviewPageView");
				Slang.detachEventListener(handlers);
			},

			// --- protected API --------------------------------------------------------------------------------------

			_initPage : function(e) {
				Slang.notImplementedMethod('_initPage');
			},

			addNavigationButtons: function (content) {
				Y.HandlebarsTemplates.append('case-editor-interview-navigation-chapters', content);
			},

			/**
			 * This is the default action for the save event. This event is fired at the interview app.
			 * @param e
			 * @private
			 */
			_save : function(e) {
				var that = this,
					cfg,
					navInfo = e.navInfo;
				Y.log("save:: reloading...", "DEBUG", "CaseEditorAppInterviewPageView");
				cfg = {
					on: {
						start: function () {
							that.fire('waiting', {msg: 'Bitte warten …'});
						},
						success: function(id, res) {
							Y.log('save:: successfully calculated.', 'DEBUG', 'CaseEditorAppInterviewPageView');
							if(navInfo) {
								Y.log('save:: found navinfo - redirecting...', 'DEBUG', 'CaseEditorAppInterviewPageView');
								that.fire('updateState', {raw:res.responseText,src:'navigate',navInfo:navInfo});
							}
							else {
								Y.log('save:: updating state', 'DEBUG', 'CaseEditorAppInterviewPageView');
								that.fire('updateState', {raw:res.responseText,src:'navigate'});
							}
						},
						failure: function(id, o) {
							Y.log('save:: failed to save changes: \n' + o.responseText, 'ERROR', 'CaseEditorAppInterviewPageView');
							that.fire('alert', {msg:"Es gab einen Fehler bei der Kommunikation mit dem Server. " +
									"Bitte prüfen Sie Ihre Internetverbindung, aktualisieren Sie anschließend die Seite " +
									"und versuchen es erneut."});
						}
					},
					context: that
				};

				Y.io("api/state/recalculate", cfg);
			}

		}, {
			ATTRS : {

				/**
				 * This attribute will store the 'real' content of this page. As the views are rendered server side we
				 * only get the result of this rendering. When the page content gets set this will call _initPage.
				 */
				pageContent : {},

				/**
				 * For all our navigation needs we store the current state in each page.
				 */
				pageState : {},

				/**
				 * Our event handlers. We need to store them as attributes.
				 */
				handlers : {
					value : []
				}
			}
		});



	})();


}, '1.0.0', {
    requires:[
        'view',
        'base-build',
        'io-base',
        'json-parse',
        'node',
        'smst-lang'
    ]});