YUI.add('smst-overlays', function(Y) {
    "use strict";

    function Overlays() {}

    /**
     * Creates a standard overlay with plugins and appearance common to all overlays of our app.
     */
    // TODO: refactore this to use a parameter object.
    Overlays.newDefault = function(header, footer, content, notEscapable, config) {

        var c = config || {};
        var overlay;

        if(Y.one('#ui-is-mobile').get('value') == 'true' && config.mobileNode) {

            if(!Y.one(config.mobileNode)) {
                //fallback for dashboard
                config.mobileNode = '#app-body';
            }

            overlay = new Y.Overlay({
                headerContent: header,
                bodyContent: content,
                footerContent: footer,
                visible: false,
                constrain: config.mobileNode,
                width: Y.one(config.mobileNode).getComputedStyle('width'),
                zIndex: 99,
                plugins: c.plugins || [
                    Y.gorilla.overlay.AnimPlugin
                ]
            });

            window.scrollTo(0, Y.one(config.mobileNode).getY() > 40 ? (Y.one(config.mobileNode).getY()-40) : 0);
        }
        else {
            overlay = new Y.Overlay({
                headerContent: header,
                bodyContent: content,
                footerContent: footer,
                visible: false,
                width: c.width || "540px",
                zIndex: 99,
                plugins: c.plugins || [
                    Y.gorilla.overlay.AnimPlugin
                ]
            });
        }

        if(!notEscapable) {
            overlay.plug(Y.gorilla.widget.EscapeHidePlugin);
            overlay.plug(Y.gorilla.widget.XButtonPlugin);
            overlay.plug({
                fn:Y.gorilla.widget.CancelButtonPlugin,
                cfg: {
                    template: '<button type="button" class="btn ui-cancel-btn">Abbrechen</button>',
                    section: Y.WidgetStdMod.FOOTER
                }
            });
        }

        if(Y.one('#ui-is-mobile').get('value') == 'true' && config.mobileNode) {
            overlay.render();
        }
        else {
            overlay.render("body");
            overlay.centered();

            /*
            (new Y.DD.Drag({
                node : overlay.get('boundingBox'),
                handles : ['.yui3-widget-hd']
            })).plug(Y.Plugin.DDConstrained, { constrain2view : true });
             */
        }

        return overlay;
    };


    Overlays.showOverlayError = function(overlay, msg, data) {
        // TODO: create the element if it does not exist!
        var el = overlay.get('contentBox').one('.app-alert-holder');

        if( el ) {
            if( data ) {
                msg = Y.Lang.sub(msg,data);
            }
            el.setContent('<div class="app-alert app-alert-problem"><i class="fa fa-exclamation-circle"></i><div><h1>' + msg + '</h1></div></div>');
            el.show();
        }
    };

    Overlays.clearOverlayError = function(overlay) {
        var el = overlay.get('contentBox').one('.app-alert-holder');
        if( el ) {
            el.hide();
            el.setHTML('');
        }
    };


    Overlays.setWaiting = function(overlay) {
        //overlay._html = overlay.get('bodyContent')
        //overlay.set('bodyContent','<h1 class="app-dialog-waiting">Bitte warten...</h1>');
        overlay.get('contentBox').setStyle('opacity', 0.5);
        overlay.get('contentBox').setStyle('cursor', 'wait');
    };

    Overlays.removeWaiting = function(overlay) {
        //overlay.get('bodyContent', overlay._html);
        overlay.get('contentBox').setStyle('opacity', 1.0);
        overlay.get('contentBox').setStyle('cursor', 'auto');
    };

    // add it to the namespace
    Y.namespace("smst").Overlays = Overlays;

}, '1.0.0', {
    requires:[
        'gallery-overlay-extras',
        'gorilla-overlay-anim-plugin',
        'gorilla-widget-escape-hide-plugin',
        'gorilla-widget-x-button-plugin',
        'overlay'
    ]
});