YUI.add('case-editor-app-interview-page-summary', function(Y) {

    "use strict";

	var Slang = Y.smst.Lang;

	/**
	 * This class represents a summary in the interview.
	 *
	 * @class OnseBase
	 * @extends Base
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
    Y.CaseEditorAppInterviewPageSummary =
		    Y.Base.create('CaseEditorAppInterviewPageSummary', Y.CaseEditorAppInterviewPageView, [], {

        initializer: function() {
            this.publish('reports-rendered');
        },

        // --- event handlers --------------------------------------------------------------------------------

        _initPage: function(e) {
            var that = this,
                content = Y.one(Y.config.doc.createDocumentFragment());

	        content.setHTML(e.newVal);
	        that.addNavigationButtons(content);

	        that.get('container').setHTML(content);

            that.fire('removeWaiting');
            that.fire('interview:summaryRendered');
        }

    }, {
        ATTRS: {

        }
    });



}, '1.0.0', {
    requires:[
        'base-build',
	    'case-editor-app-interview-page-view',
	    'event',
        'smst-lang',
        'view'
    ]
});