YUI.add('case-editor-app-interview-page-chapters', function(Y) {

    "use strict";

	/**
	 * This class represents a chapters overview in the interview.
	 *
	 */
    Y.CaseEditorAppInterviewPageChapters =
		    Y.Base.create('CaseEditorAppInterviewPageChapters', Y.CaseEditorAppInterviewPageView, [], {

        _initPage: function(e) {
            var that = this,
                content = Y.one(Y.config.doc.createDocumentFragment());

            var children = [];

            if(Y._currentState) {
                Y.Array.each(Y._currentState.areaJSON.children, function(it) {
                    children.push({"label" : it.label});
                });
            }

            var template = Y.HandlebarsTemplates.lookup("case-editor-interview-area-overview");
            content.setHTML(template({"areas" : children}));

            that.addNavigationButtons(content);
            that.get('container').setHTML(content);

            that.fire('removeWaiting');
            that.fire('interview:chaptersRendered');
	    }

    }, {
        ATTRS: {

        }
    });



}, '1.0.0', {
    requires:[
        'base-build',
		'case-editor-app-interview-page-view',
	    'event',
	    'smart-handlebars-templates',
	    'smst-lang',
	    'view'
    ]
});