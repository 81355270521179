YUI.add('case-editor-app-interview-field-help', function(Y) {

    "use strict";

    /**
     * Adds the field help container to the side of the interview.
     *
     * @type {CaseEditorAppInterviewFieldHelp}
     */
    var FieldHelp = Y.Base.create('CaseEditorAppInterviewFieldHelp', Y.Base, [], {

        initializer: function() {
            Y.onceAfter('smartsteuer:ready', this._initialize, this);
        },

        _initialize: function() {

            Y.on('interview:rendering', function(e) {

                Y.one('#case-interview-form').insert(this._getTemplate(), 'after');

                if(e.state.showsImageList) {
                    //no need for field help when image list needs more space
                    var container = Y.one('#case-interview-body');
                    container.one('#case-interview-field-help').remove(true);
                    container.removeClass('case-interview-body-with-field-help');
                }
                else if(!e.state.isBase && e.state.hasVisibleFields) {
                    //active, except on mf/mfa-bases
                    Y.one('#case-interview-field-help').removeClass('ui-display-none');
                }

            }, this);
        },

        _getTemplate : function() {
            return Y.HandlebarsTemplates.lookup('case-editor-interview-field-help')();
        }

    }, {
        ATTRS : {
        }
    });

    Y.CaseEditorAppInterviewFieldHelp = new FieldHelp()

}, '1.0.0', {
    requires:[
        'base-build',
        'event',
        'smart-handlebars-templates',
        'smst-lang',
        'view'
    ]
});