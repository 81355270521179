/**
 * This is a field to cover a date range.
 */
YUI.add('interview-range-field', function(Y) {

	"use strict";

	var protos, statics,
		NAME = 'RangeField';

	statics = {
		NAME : NAME,
		ATTRS : {
		}
	};

	protos = {

		_f1 : null,

		_f2 : null,

		initializer: function(config) {
			//Y.log('init',null,NAME);
			this._f1 = this.initPart(config, '#f1_');
			this._f2 = this.initPart(config, '#f2_');
		},

		initPart:function (config, prefix) {
            // the 'config' is the config of the "real" field. Which is hidden.
			var parentNode = config.node.get('parentNode'),
                node = parentNode.one(prefix + this.nodeId),
				cfg = {};
			// fast copy
			Y.mix(cfg,config);
			cfg.node = node;
			cfg.form = this; // we want to get all event first.
			return new Y.interview.TextField(cfg);
		},

		_renderUI : function() {
			// we have to call render() on our part fields.
			this._f1.render();
			this._f2.render();
		},

		_bindUI : function() {
			this.constructor.superclass._bindUI.apply(this, arguments);
			// syncing the error state
			this._listenerHandlers.push( this.on('TextField:error', this._checkErrors, this ) );
			this._listenerHandlers.push( this.on('TextField:clear-error', this._checkErrors, this ) );
			// syncing the values
			this._listenerHandlers.push( this.after('TextField:valueChange', this._updateValue, this ) );
			this._listenerHandlers.push( this.after('valueChange', this._handleValueChange, this ));
		},

		_syncUI : function() {
			this.constructor.superclass._syncUI.apply(this, arguments);
			// read the nodes value
			this.set('value', this.node.get('value'));
			this.set('savedValue', this.get('value'));
		},

		/**
		 * We have to overwrite this here. As we are handling changes to the values a little different.
		 */
		_syncValue : function() {
			// empty implementation to not have our parent class disturb us.
		},

		_checkErrors : function(e) {
			// we are updateing the error of this class - so we don't want the events for the part fields to bubble up.
			e.halt();
			Y.log('checking for errors', null, NAME);
			var e1 = this._f1.get('error'),
				e2 = this._f2.get('error');

			if( null === e1 && null === e2) {
				Y.log('no errors', null, NAME);
				this.set('error', null);
			} else {
				// simply set the last error as the current one.
				var err = e.target.get('error');
				if( null !== err ) {
					Y.log('updating error', null, NAME);
					this.set('error', err);
				} else {
					// at least one field is filled out correct.
					// now find out which is not.
					if( null === e1 ) {
						Y.log('f2 with error', null, NAME);
						this.set('error', e2);
					} else {
						Y.log('f1 with error', null, NAME);
						this.set('error', e1);
					}
				}

			}

			Y.log('current error is: ' + this.get('error'), null, NAME);
		},

		_updateValue : function(e) {
			var value = '',
				part1 = this._f1.get('value'),
				part2 = this._f2.get('value');

			if( part1 && part2) {
				value = part1 + '-' + part2;
			}
			this.set('value', value, {src:'ui'});

            if(e.src && e.src == 'tool') {
                this._setNodeValue(value);
                this.check();
                this._fireChange();
            }
		},

		_handleValueChange : function(e) {
			if( !(e.src && 'ui' == e.src)) {
				this._updateParts(e);
			}
            else { // FIXME: der Block kann wohl raus - dieser Block ist jetzt in _updateValue. Testen: shallow/template, errors, empty-> value, value->value, empty->value->tool, value->tool
                this._setNodeValue(e.newVal);
                this.check();
                this._fireChange();
            }
		},

        _forcedRemoveShallow : function() {
            this._f1.set('templateValueHadFocus', true);
            this._f2.set('templateValueHadFocus', true);
            this._f1._removeShallow();
            this._f2._removeShallow();
        },

		_updateParts : function(e) {
			var part1 = "",
				part2 = "";

			if( e.newVal ) {
				var index = e.newVal.indexOf('-');
				if( -1 < index ) {
					part1 = e.newVal.substring(0,index);
					part2 = e.newVal.substring(index+1);
				}
			}
			this._f1.set('value', part1, {src:'tool'});
			this._f2.set('value', part2, {src:'tool'});
		},

		/**
		 * Cleanup.
		 */
		destructor: function() {
			// this._listenerHandlers is handled by our super class.
			// we only need to destroy our part fields.
			this._f1 && this._f1.destroy();
			this._f2 && this._f2.destroy();
		}

	};

	Y.namespace('interview').RangeField = Y.Base.create(NAME, Y.interview.Field, [], protos, statics);

}, '1.0.0', {requires:[
	'oop',
	'interview-fields'
]} );


