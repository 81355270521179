YUI.add('case-editor-app-back-to-est', function(Y) {
    "use strict";

    var Slang = Y.smst.Lang;

    Y.CaseEditorAppBackToEst = Y.Base.create('CaseEditorAppBackToEst', Y.smst.CaseEditorAppBase, [], {

        template: null,

        render : function() {
            this.constructor.superclass.render.apply(this);
            this.template = Y.HandlebarsTemplates.lookup('case-editor-back-container');
        },

        handleNavigation : function(navInfo) {

            this.get('container').setHTML(this.template({hasUSt: Y._currentState && Y._currentState.hasUSt, hasGewSt: Y._currentState && Y._currentState.hasGewSt}));

            return this;
         }


    }, {
        ATTRS : {

            container: {
                valueFn: function(){
                    return Y.Node.create('<div id="case-editor-back-to-est"/>');
                }
            }
        }
    });



}, '1.0.0', {
    requires:[
        'app',
        'base-build',
        'case-editor-app-base',
        'io-base',
        'node',
        'smst-lang'
    ]});