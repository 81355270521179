YUI.add('console-fix',function(Y){
    // create a console object even before document.ready and cache all calls to log()
  if (typeof (console) === 'undefined') {
    // there is no console object, this may happen in some IE Versions and firefox if no firebug is active
    // we create a dummy console object with a log() function, to prevent calls to console.log() to crash the app
    console = {
      log : function (text) {
          // do nothing
      }
    }
  }
},'1.0.0',{requires:[]});