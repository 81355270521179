YUI.add('case-editor-app-interview-registerCTA', function(Y) {
	"use strict";


	var RegisterReminder = Y.Base.create('CaseEditorAppInterviewRegisterCTA', Y.Base, [], {

		_shownInterviewPagesCount: 0,

		_miniInterviewPageCountOffset: -3,

		_initialPageCountSet: false,

		_reminderRatio: 10,

		_config : null,

		initializer: function() {
			Y.onceAfter('smartsteuer:ready', this._initialize, this);
		},

		_initialize: function(config) {
			Y.log("I am alive", "DEBUG", "CaseEditorAppInterviewRegisterCTA");

			this._config = config;

			if( config && config.anonymous ) {
				// load the template
				var viewTemplateName = "case-editor-interview-registerCTA";
				this.viewTemplate = Y.HandlebarsTemplates.lookup(viewTemplateName);

				// when do we have work to do?
				Y.on('interview:rendered', this._handleInterviewRendered, this);
			}
		},

		_handleInterviewRendered : function() {

			Y.log("Handling new rendered page", "DEBUG", "CaseEditorAppInterviewRegisterCTA");

			if( this._config && this._config.useRegistrationBarrier ) {
				//forcing the user to register on specific url by disabling the next button
				//we don't take care about any side effects because this is just for an a/b test and
				//the page is reloaded after registration
				if(window.location.href.indexOf("/case/-1/interview/4759/") > 0) { //Aktuelle Anschrift
					this.render();

					var container = Y.one('#case-editor-continue-container');
					container.one('.ui-case-editor-next').setStyle('display', 'none');
					container.append(Y.Node.create('<button class="btn btn-l btn-disabled" onclick="javascript:alert(\'Bitte legen Sie ein Benutzerkonto an, um fortzufahren.\'); return false;" title="Bitte legen Sie ein Benutzerkonto an, um fortzufahren.">Weiter <i class="fa fa-chevron-right"></i></button>'));
				}
			} else {
				this._countShownInterviewPage();
				if (this._itsTimeForAReminder()) {
					this.render();
				}
			}
		},

		// setting the initial value has to wait until the interview was rendered for the first time
		// we need a case to read from.
		// If we are in the short a.k.a. MiniInterview the very first appearance of the reminder has to wait a little
		// bit longer.
		_getInitialPageViewCount: function () {
			var initialCount = 0;

			// Marker field for the mini interview
			var miniInterviewActivated = Y.UserCase.getValueFromCase("OF0011004", "Standard", 1);
			if(miniInterviewActivated && "X" == miniInterviewActivated.value) {
				initialCount = this._miniInterviewPageCountOffset;
			}

			return initialCount;
		},

		_countShownInterviewPage: function() {
			// initialize the counter if needed.
			if( this._initialPageCountSet ) {
				this._shownInterviewPagesCount += 1
			} else {
				this._shownInterviewPagesCount = this._getInitialPageViewCount();
				this._initialPageCountSet = true;
			}

		},

		_itsTimeForAReminder: function() {
			return this._shownInterviewPagesCount > 0 && this._shownInterviewPagesCount % this._reminderRatio == 0

		},

		render : function() {
			var nextSibling = Y.one('#case-editor-continue-container'),
				container;

			Y.log("It's time.", "DEBUG", "CaseEditorAppInterviewRegisterCTA");

			if( nextSibling ) {
				Y.log("Found sibling", "DEBUG", "CaseEditorAppInterviewRegisterCTA");
				container = nextSibling.get('parentNode');
			}

			if( container ) {
				Y.log("Found container", "DEBUG", "CaseEditorAppInterviewRegisterCTA");
				container.insertBefore(this.viewTemplate(), nextSibling);
			}
		}

	}, {
		ATTRS : {
		}
	});

	// this is automatically created when it is included in any other script.
	Y.CaseEditorAppInterviewRegisterCTA = new RegisterReminder()

}, '1.0.0', {
	requires:[
		'base-build',
		'node',
		'smart-handlebars-templates'
	]});
