YUI.add('handlebars-view', function(Y) {
    "use strict";

    Y.HandlebarsView =  Y.Base.create("HandlebarsView", Y.View, [], {

    	_partials: {},

        // prepare the template
        initializer: function () {
            if(!Y.Lang.isFunction(this.template)) {
                if( !Y.Lang.isString(this.template)) {
                    Y.error("No template name provided! Please add 'template' to the config of this view.");
                }
                this.template = Y.HandlebarsTemplates.lookup(this.template);
            }
            // optionally one can define a set of partials which will get fetched and registered as well.
            if( this.partials ) {
                if (Y.Lang.isArray(this.partials)) {
	                var partials = {};
                    Y.Array.each(this.partials, function(it){
                        // we are going to look each partial up. Providing the second argument will tell
                        // HandlebarsTemplates to register this template as a partial.
	                    partials[it] = Y.HandlebarsTemplates.lookup(it, true);
                    });
	                this._partials = partials;
                } else {
                    Y.log("this.partials was provided but it's not an array!", 'WARN', 'HandlebarsView');
                }
            }
        },

        // we are not overwriting the "real" render method here. This is because we don't want to "guess" where
        // the data for the rendering comes from. Instead of this we will provide a convenient way to get
        // the template rendered with a set of given data.
        _render: function(data) {
            this.get('container').setHTML(this.template(data));
            // as a best practice we will return this.
            return this;
        },

        // ok, ok, ok, here is a default implementation of the render method. Take it or overwrite it.
        render: function() {
            return this._render( this.getAttrs() );
        },

	    getPartial: function(name) {
		    return this._partials[name];
	    }
    }, {
        // this special field will get automatically aggregated with any field in its super classes.
        _NON_ATTRS_CFG: [
            'partials'
        ]
    });

}, '1.0.0', { requires:[ "base-build", "smart-handlebars-templates", 'view' ]});