YUI.add('interview-fieldrules', function(Y) {

	// --- Helpers for STRATEGY 'FORMULA*' - END -------------------------------------------------------------------------

	function days(date) {

		if(!days.defaultYear) {
			days.defaultYear = "2013";
		}

		if(!date || date.indexOf("-") < 0 || date.indexOf("\"TT") === 0) {
			return 0;
		}

		date = date.replace(/"/g,'');
		let date1, date2;

		//Y.log(date.length+": "+date);

		const tmp = date.split("-");

		//BI-394 http://stackoverflow.com/questions/2182246/javascript-dates-in-ie-nan-firefox-chrome-ok
		function parseISO8601(dateStringInRange) {
			let isoExp = /^\s*(\d{4})-(\d\d)-(\d\d)\s*$/;
			const date = new Date(NaN);
			let month;
			const parts = isoExp.exec(dateStringInRange);

			if(parts) {
				month = +parts[2];
				date.setFullYear(parts[1], month - 1, parts[3]);
				if(month !== date.getMonth() + 1) {
					date.setTime(NaN);
				}
			}
			return date;

		}

		if(tmp[0].indexOf(".") < 0 && tmp[0].length === 8) {
			tmp[0] = tmp[0].substring(0,2)+"."+tmp[0].substring(2,4)+"."+tmp[0].substring(4);
		}
		if(tmp[1].indexOf(".") < 0 && tmp[1].length === 8) {
			tmp[1] = tmp[1].substring(0,2)+"."+tmp[1].substring(2,4)+"."+tmp[1].substring(4);
		}

		const d1 = tmp[0].split(".");
		date1 = parseISO8601((d1.length > 2 ? d1[2] : days.defaultYear)+"-"+d1[1]+"-"+d1[0]);

		const d2 = tmp[1].split(".");
		date2 = parseISO8601((d2.length > 2 ? d2[2] : days.defaultYear)+"-"+d2[1]+"-"+d2[0]);

		//Y.log(d1+" / "+d2);

		const ONE_DAY = 1000 * 60 * 60 * 24;
		const date1_ms = date1.getTime();
		const date2_ms = date2.getTime();
		const difference_ms = Math.abs(date1_ms - date2_ms);

		Y.log("calculated days: "+Math.round(difference_ms/ONE_DAY));

		return Math.round(difference_ms/ONE_DAY)
	}

	function hour(time, round) {
		if(!time || time.indexOf(":") < 0 || time.indexOf("\"HH") === 0) {
			return 0;
		}

		time = time.replace(/"/g,'').split(":");
		let hour = parseInt(time[0], 10);

		if(parseInt(time[1], 10) > 0) {
			if(round) {
				hour++;
			}
		}

		Y.log("found hour: "+hour);

		return hour;
	}

	function hourDiff(h1, h2) {

		if(!h1 || h1.indexOf(":") < 0 || h1.indexOf("\"HH") === 0 || !h2 || h2.indexOf(":") < 0 || h2.indexOf("\"HH") === 0) {
			return 0;
		}

		let tmp = h1.replace(/"/g, '').split(":");
		const t1 = parseInt(tmp[0], 10) * 60 + parseInt(tmp[1], 10);

		tmp = h2.replace(/"/g,'').split(":");
		const t2 = parseInt(tmp[0], 10) * 60 + parseInt(tmp[1], 10);

		const hour = (t1 - t2) / 60;

		Y.log("found hour diff: "+hour);

		return hour;
	}

	function table(field, key, idx, tableMapping, lookupIdx) {
		if (!lookupIdx) {
			lookupIdx = 0
		}

		if (key && (typeof key === "string")) {
			key = key.replace(/"/g, '');
		}

		Y.log("table lookup for: " + field + " with value index " + idx + " and lookupIndex " + lookupIdx);

		if (!tableMapping) {
			Y.log("no table mapping found!");

			return null;
		}

		for (const tableField in tableMapping) {

			if (field === tableField) {
				Y.log("found table for: " + tableField + ", key: " + key);

				const data = tableMapping[tableField];

				for (let i = 0; i < data.length; i++) {
					const tmp = data[i];

					if (tmp[lookupIdx] === key) {
						Y.log("found table value: " + tmp[idx])
						return tmp[idx];
					}
				}
			}
		}

		return null;
	}

	Y.namespace('interview').FieldRules =  Y.Base.create( 'FieldRules', Y.Base, [], {

        initializer : function() {
			const that = this;

			that.items = null;
            that.disabled = true;

            Y.once('smartsteuer:ready', function(config){this.config = config}, this);
        },

		updateFieldRules: function(state, fields) {
			const that = this;

			that.items = Y.JSON.parse(state.fieldRules);
            that.disabled = false;
            that.fields = fields;
            that.checkRules(state);

            // If this area has any data yet (in the dirty case) we have to check the fields
            if(state.areaIsProcessed) {
                that.checkFields();
            }
		},

        getValue : function(yuiField, selector, format) {
            //Y.log(selector+" -> "+format);

            if(format === 'X' || format === 'Y' || format === 'J') {
                if(yuiField.hasClass('select')) {
                    //return eval('Y.one(\''+selector+'\').get("value") !== ""');
                    return yuiField.get("value") !== "";
                }
                else {
                    //return eval('Y.one(\''+selector+'\').get("checked") ');
                    return yuiField.get("checked");
                }
            }
            else {
                //return eval('Y.UserCase.realValue(Y.one(\''+selector+'\').get("value"),"'+format+'") ');
                return Y.UserCase.realValue(yuiField.get("value"), format);
            }
        },

        getYuiSelector : function(name, isMultiFieldArea, index) {
			const appendix = isMultiFieldArea ? "[" + index + "]" : "";

			let selector = 'input[name="field_' + name + appendix + '"]';
			let yuiField = Y.one(selector);

			if(yuiField === null) {
                selector = 'select[name="field_'+name+appendix+'"]';
                yuiField = Y.one(selector);
                //Y.log("fallback for select: " +selector+" / "+yuiField);
            }

            if(yuiField === null) {
                selector = 'textarea[name="field_'+name+appendix+'"]';
                yuiField = Y.one(selector);
                //Y.log("fallback for textarea: " +selector+" / "+yuiField);
            }

            if(yuiField === null) {
                return null;
            }

            return selector;
        },

        checkFields : function() {

            Y.each(this.fields, function(field) {
                //Y.log("check: "+field);
                //TODO: check with inlines on invisible area
                //if(field.notVisible === false) { ... }
                field.check(true);
            });

        },

        reject : function(text) {
            if(text === '') {
                return ' '; //TODO: empty string does not trigger yet
            }

            return text+"\n\n";
        },

        getAuditedField : function(id) {
            return this.fields[id];
        },

	    checkRules : function(state, event) {

		    if(null === this.items
			   || Y.Object.size(this.items) < 1
			   || this.disabled
			   || state.isMultiFieldBase
			   || state.isMultiFormBase) {

                return null;
            }

            try {
				let txt = "";

				Y.each(this.items, function(it, idx1) {

                    let j;
					let k;
					let hasValue;
					let f;
//Y.log(">>> checking fieldrules for "+Y.Object.size(this.items)+" items on index "+idx1);

                    let yuiField;
//---------------------------------GATHER RESULTS FROM FIELDS----------------------------------------------------
					const results = [];
					const fields = [];
					const rawFields = [];
					const values = [];
					const hidden = [];

					// the strategy we are using
					const strategy = it.clauses[0].strategy;

	                // helper methods used inside the rules.
	                // we have to store them here under their names so that closure compiler won't rename them and
	                // destroy the rules at runtime.

                    days.defaultYear = this.config.vz;

	                Y.config.win['days']=days;
	                Y.config.win['table']=table;
	                Y.config.win['hour']=hour;
	                Y.config.win['hourDiff']=hourDiff;
	                Y.config.win['values']=values;
                    Y.config.win['hidden']=hidden;
	                Y.config.win['results']=results;

                    //fetch results from clause
                    Y.each(it.clauses[0].fields, function(field, idx2){

						const selector = this.getYuiSelector(field.name, state.isMultiFieldArea, state.multiFieldIndex);
						const yuiField = Y.one(selector);
						let value;
						let isHidden = false;

						if(yuiField !== null) {
                            //field-level
                            fields[idx2] = yuiField;
                            value = this.getValue(yuiField,selector,field.format);

                            //formats like K set the syntax to the node value and it's returned quoted here
                            if(value && value !== "" && value === '"'+Y.interview.FormatFactory.getInstance(field.format).syntax+'"') {
                                value = "";
                            }

							const inliner = yuiField.ancestor('li');

							if(inliner && inliner.getComputedStyle('display') === 'none') {

                                isHidden = true;
                                hidden[idx2] = true;
                                value = "";
                            }
                        }
                        else {
                            //case-level
                            fields[idx2] = null;
                            value = Y.UserCase.searchRealValueFromCase(field.name, state.formIndex, state.multiFieldIndex);
                        }

						const isEmpty = (undefined === value || value === null || Y.UserCase.isInvalidNumber(value, field.format) || String(value) === "false" || String(value) === "" || String(value) === "\"\"");

						Y.log("selector: "+selector+" / "+field.name+" ::: "+idx1+"; value: "+value+" / "+isEmpty+" -> has field on page: "+(yuiField !== null)+", is invisible in inliner: "+isHidden);

                        results[idx2] = isEmpty;
                        values[idx2] = value;
                        rawFields[idx2] = field;

                    }, this);

                    //---------------------------------EVALUATE RESULTS----------------------------------------------------
					let failed = false;
					let allEmpty = true;

					Y.log('evaluating strategy: '+ strategy);

                    if(strategy === 'REQUIRED') {
                    //---------------------------------REQUIRED----------------------------------------------------
                        //one state to rule them all
						results.every((result, j) => {
							allEmpty = allEmpty && result;

							if (result !== results[0]) {
								const f = this.getAuditedField(fields[j].get('id'));

								if (f !== undefined && f.notVisible === false) {
									failed = true;
									return false;
								}
							}

							return true;
						});

                        //TODO: phase ii: only do this for non-chained rules
                        //TODO: show errors
						results.forEach((result, j) => {
							if (fields[j] !== null) {
								const f = this.getAuditedField(fields[j].get('id'));

								if (f !== undefined) {
									const syncState = !allEmpty || f.get('defaultMandatory');

									// Y.log("syncState: "+syncState);
									// FIXME: hier muss set('mandatory',...) benutzt werden.
									f._syncMandatory(syncState);
									if (!syncState) {
										f.check(true); // remove errors but don't highlight if set mandatory but not touched yet
									}
								} else {
									Y.log("ERROR: undefined field: " + fields[j].get('id'));
								}
							}
						});


					}
                    else if(strategy === 'EXCLUDES') {
                    //---------------------------------EXCLUDES----------------------------------------------------
						failed = results.filter(result => !result).length > 1;
                    }
                    else if(strategy.indexOf('FORMULA') === 0) {
                    //---------------------------------FORMULA----------------------------------------------------
                        for(let j = 0; j<fields.length; j++) {

                            //Y.log(fields[j]+": "+(event ? event.field : ""));

                            if(!event || (event && fields[j] === event.field)) {// || it.triggerField.name === rawFields[j].name)) {

								const field = rawFields[j].name;
								const tableMapping = state.fieldRuleTableData;

								Y.config.win['field']=field;
                                Y.config.win['tableMapping']=tableMapping;

								let calculated = eval(it.clauses[0].formula);

								if(Y.Lang.isNumber(calculated)) {
                                    calculated = Math.ceil(calculated);
                                }

                                Y.log("formula for "+it.triggerField.name+": "+it.clauses[0].formula+" -> "+calculated);

								const selector = this.getYuiSelector(it.triggerField.name, state.isMultiFieldArea, state.multiFieldIndex);
								const yuiField = Y.one(selector);

								const suggest = strategy.indexOf('AUTOSUGGEST') > 0;
								const f = this.getAuditedField(yuiField.get('id'));

                                if(yuiField.getAttribute('type') === 'checkbox') {
                                    //just set the value directly, make sure to have this formula working in the backend cleanup
                                    yuiField.set('checked', calculated);

                                    this.disabled = true;
                                    f.touch();
                                    this.disabled = false;
                                }
                                else {

                                    if(!suggest) {
                                        //just set the value directly, make sure to have this formula working in the backend cleanup
                                        yuiField.set('value', calculated);

                                        this.disabled = true;
                                        f.touch();
                                        this.disabled = false;
                                    }
                                    else {
                                        //use autosuggest, as a bonus we don't have to add this to the backend cleanup
                                        Y.log("found trigger field "+it.triggerField.name+", empty: "+ yuiField.hasClass('case-editor-interview-field-empty'));

                                        //TODO: formula-autosuggest marks result field as template value even on first run, maybe? fix this

                                        if(isNaN(calculated)) {
                                            calculated = "";
                                        }

                                        Y.log('setting template value "'+calculated+'" for: '+it.triggerField.name);
                                        this.disabled = true;
                                        f.setAutoSuggestValue(calculated);
//                                             f._setTemplateValueAfterInit(calculated);
                                        this.disabled = false;
                                    }
                                }
                            }
                        }
                    }
                    else if(strategy.indexOf('RADIO') === 0) {
                    //---------------------------------RADIO----------------------------------------------------
						let isCheckbox = true;
						let onlyCheckBoxFieldsInRule = true;
						const notMultiple = strategy.indexOf('MULTIPLE') < 0;
						const lastIndex = fields.length - 1;
						let resetMandatory = false;

                        if(strategy.indexOf('TRIGGER') >= 0) {
							yuiField = Y.one(this.getYuiSelector(it.triggerField.name, state.isMultiFieldArea, state.multiFieldIndex));
							f = this.getAuditedField(yuiField.get('id'));

							if(yuiField.get('value') === '') {

                                Y.log('Input field is empty, checking mandatory rules: '+it.triggerField.name);

								hasValue = false;
								for(let x=0; x<results.length; x++) {
                                    if(!results[x]) {
                                        hasValue = true;
                                        break;
                                    }
                                }

                                if(hasValue) {
                                    Y.log('Not all fields are empty, setting mandatory for trigger field...');
                                    f._syncMandatory(true);
                                }
                                else {
                                    Y.log('All fields are empty, resetting mandatory...');
                                    f._syncMandatory(false);
									f.check(true);
                                    resetMandatory = true;
                                }
                            }
                        }

                        //group fields together
                        for(let j=0; j<=lastIndex; j++) {
                            if(isCheckbox && fields[j].getAttribute('type') !== 'checkbox') {
                                onlyCheckBoxFieldsInRule = false;
								isCheckbox = false;

                                console.log('found non-checkbox radio type');

                                Y.log("detected non-checkbox radio type");

                                //want to check !isEmpty instead of true/false
                                for(let x=0; x<results.length; x++) {
                                    values[x] = !results[x];
                                    //Y.log(""+x+": "+values[x]);
                                }
                            }

                            //check if none is checked and sync mandatory as needed
                            if(strategy.indexOf('FORCE') > 0) {
                                hasValue = resetMandatory;

                                for(let k = 0; k<values.length; k++) {
                                    if(values[k]) {
                                        hasValue = true;
                                        break;
                                    }
                                }

                                this.getAuditedField(fields[j].get('id'))._syncMandatory(!hasValue);

                                //ONSE-8300 clear error state on all fields
                                if(hasValue) {
                                    this.getAuditedField(fields[j].get('id')).check(true);
                                }
                            }
                        }

	                    // check if our checkboxes are actual radios
	                    if(onlyCheckBoxFieldsInRule && notMultiple) {
		                    for(let j=0;j<fields.length;j++) {
			                    this.getAuditedField(fields[j].get('id')).set('radio', true);
		                    }
	                    }

                        //check the checkbox states if needed
                        for(let j=0; j<fields.length; j++) {

                            if(event && fields[j] === event.field && values[j]) {
                                //found out one of the radio fields has been triggered to true

                                Y.log('RADIO: values['+j+']: '+values[j]+', is event field: '+(fields[j] === event.field)+", multiple allowed: "+(strategy.indexOf('MULTIPLE') > 0));

                                for(k = 0; k<values.length; k++) {
                                    //all other radio fields have to be set to false
                                    if(k === j) continue;

                                    if(notMultiple) {
                                        if(isCheckbox) {
                                            fields[k].set('checked', false);
                                        }
                                        else {
                                            fields[k].set('value', '');
                                        }
                                    }

                                    this.disabled = true;
                                    for(let l=0; l<fields.length; l++) {
                                        //we have to recheck all fields here since mandatory might have been cleared
                                        if(k !== l) {
                                            this.getAuditedField(fields[l].get('id')).check(true);
                                        }
                                    }
                                    f = this.getAuditedField(fields[k].get('id'));
                                    f._syncValue();
                                    f.touch();
                                    this.disabled = false;
                                }

                                break;
                            }
                         }
                    }
                    else if(strategy === 'AUTOSUGGEST_CHAINED') {
                    //---------------------------------FORMULA----------------------------------------------------
                        for(j = 0; j<fields.length; j++) {

                            if(event && fields[j] === event.field) {
                                //searched for event field

                                Y.log('AUTOSUGGEST_CHAINED: values['+j+']: '+values[j]+', is event field: '+(fields[j] === event.field));

                                for(k = 0; k<values.length; k++) {
                                    //fill all fields after the event field with shallow values
                                    if(k <= j) continue;

                                    f = this.getAuditedField(fields[k].get('id'));

									if(Y.one('#ui-is-mobile').get('value') === 'true') {
										//ONSE-12847 setting the value clashes with paypal style input
										continue;
									}

                                    if(!f.get('templateValueHadFocus') && (results[k] || f.get('templateValue'))) {
                                        //field is empty
										const eventNode = this.getAuditedField(fields[j].get('id'));
										eventNode._syncValue();
										const fVal = eventNode.get('value');

										Y.log('setting template value "'+fVal+'" for: '+rawFields[k].name);

                                        this.disabled = true;
                                        f._setTemplateValueAfterInit(fVal);
                                        this.disabled = false;
                                    }
                                    else {
                                        Y.log('found non-template value for: '+rawFields[k].name+": "+results[k]+" / "+f.get('templateValueHadFocus'));

                                        //if one field between has a non-template value we don't change the next ones
                                        break;
                                    }

                                }

                                break;
                            }
                         }
                    }
                    //---------------------------------/STRATEGIES----------------------------------------------------

                    //if failed append message
                    if(failed) {
                        //Y.log(">>> entry failed");
                        txt += this.reject(it.text);
                    }

                }, this);

                txt = txt.replace(/\n\n$/,'');

                return txt !== "" ? txt : null;
            }
            catch(ex) {
                Y.log("Fieldrules-Exception:"+ex, "error");

                if(this.config.boUser) {
                     alert("Fieldrules-Exception:"+ex);
                }
            }

            return null;
        }

	}, {});

}, '1.0.0', {
    requires:[
	    'base-build',
        'event'
]} );


