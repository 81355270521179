YUI.add('interview-autosuggest-overwrite-plugin', function(Y, NAME) {

	"use strict";

	var TEMPLATE =
				'<div class="case-interview-field-input-tool-container" style="display:none;">' +
						'<div class="case-interview-field-input-tool-btns ' +
							'case-interview-field-input-tool-btns-pointing ' +
							'ui-case-interview-autosuggest-plugin-content">' +
					'<div class="ui-case-interview-autosuggest-overwrite-plugin-actions"></div>' +
				'</div></div>',
		ACTION_TEMPLATES = {
			off:'<button type="button" class="btn btn-s">Anderen Wert eintragen</button>' +
					'<input type="hidden" value="false" name="{name}">',
		    on:'<span class="ui-case-interview-field-input-tool-btns-label">Berechneter Wert:</span><button type="button" ' +
				    'class="btn btn-s ui-case-interview-field-input-tool-btn">{value}</button>' +
				    '<input type="hidden" value="true" name="{name}">',
			noAction : '<input type="hidden" value="true" name="{name}">'
		};

	/**
	 * Set the current field readOnly to always show the autosuggested resutls. The User is able to turn this of to
	 * enter an other value.
	 * The turned on/off state is stored in the case.
	 *
	 * @class AutosuggestOverwritePlugin
	 * @extends Y.Plugin.Base
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	Y.namespace("interview").AutosuggestOverwritePlugin = Y.Base.create(NAME, Y.Plugin.Base, [], {

		_extraMarkup : null,
		_fieldName: null,

		_toggleState: function(e) {
			e.preventDefault(); // focus should be handled outside.
			this.set('toggledOn', !this.get('toggledOn'));
		},

		_update : function() {
			var that = this,
				host = that.get('host'),
				on = that.get('toggledOn'),
				value = that.get('value'),
				container = that._extraMarkup,
				template;

			template = Y.Lang.sub(ACTION_TEMPLATES[on?'on':'off'], {
				name:that._fieldName,
				value : value
			});

			if(!value) {
				template = Y.Lang.sub(ACTION_TEMPLATES['noAction'], {
					name:that._fieldName,
					value : value
				});
			}

			container.one('.ui-case-interview-autosuggest-overwrite-plugin-actions').setHTML(template);

			if(on) {
				// The user wants to use her own values.
				host.set('readOnly', false);
			} else {
				// only when overwriting is turned off (the default) we will set the value off our host.
				if( value ) {
					host.set('readOnly', true);
					this.get('host').set('value', value, {src:'tool'});
				}
			}

			if(!value) {
				host.set('readOnly', false);
				container.hide();
			}
			else {
				container.show();
			}
		},

		_updateAndFocus: function(e) {
			var node;
			this._update();
			if(e.newVal) {
				node = this.get('host').node;
				node.focus();
				node.select();
			}
		},

		_render: function() {
			var that = this,
				host = that.get('host'),
				target = host.node.get('parentNode');

			that._extraMarkup = Y.Node.create(TEMPLATE);

			if(!that.get('host').get('readOnly')) {
				target.insertBefore(that._extraMarkup, host.node);
			}

			that._handler.push( that._extraMarkup.delegate('click', that._toggleState, '.btn', that ));
		},

		_buildFieldName: function (that, host) {
			that._fieldName = Y.Lang.sub('{hostName}{mfIndex}_overwrite-on', {
				hostName: host.get('node-name'),
				mfIndex: host.get('mfIndex') || 1
			});
		},

		initializer : function() {
			var that = this,
				host = that.get('host');

			that._handler = [];

			// get the initial state from our host.
			that.set('value', host.get('value'));

			// construct the name of our hidden field.
			this._buildFieldName(that, host);

			// listen for changes
			that._handler.push(that.after('toggledOnChange', that._updateAndFocus, that));
			that._handler.push(that.after('valueChange', that._update, that));

			that._render();
			that._update();

		},

		destructor : function() {
			if( this._handler ) {
				Y.Array.each( this._handler, function(it){
					it.detach();
				});
			}

			this._extraMarkup.remove(true);
		}
	}, {
		NS: 'overwrite',
		ATTRS: {
			/**
			 * If on the user can enter her own value. Otherwise the autosuggested value will be filled into
			 * the field.
			 */
			toggledOn: {
				value: false,
				validator: Y.Lang.isBoolean
			},
			/**
			 * This will store the autosuggested value. Changing this value will be mirrored into the hosts value if this
			 * is off.
			 */
			value: {
                setter : function(value) {

                    //ONSE-8788 0 is not set because it's evaluated as false in the default yui setter!?
                    if(value !== undefined && value !== null && value == 0) {
                        return "0";
                    }

                    return value;
                }

            }
		}
	} );


}, '1.0.0', {
    requires:[
        'base-build',
        'plugin',
        'node'
    ]
} );