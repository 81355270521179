YUI.add('mobilefield', function(Y) {

    var proto = {
        _handlers: [],
        _fields : [],

        initializer: function () {
            var that = this;

            if(Y.one('#ui-is-mobile').get('value') != 'true') {
                return;
            }

            //only enable for chrome or desktop mobile view
            if(Y.one('#ui-is-force-mobile') && Y.one('#ui-is-force-mobile').get('value') == "true") {
                console.log("forcefully enabling the mobile field decorator since we're forcing a mobile view");
            }
            else {
                if(navigator.userAgent.toLowerCase().indexOf('firefox') >= 0) {
                    //mobile firefox has some bugs, try typing a comma for example
                    return;
                }
            }

            that._handlers.push(Y.after('interview:fieldChanged', function(e) {
                that._handleKeyOnCustomFormat(e);
            }, that));

            that._handlers.push(Y.after('*:field-focus', function(e) {
                that._initCustomFormat(e);
                that._placeCursor(e);
            }, that));

            that._handlers.push(Y.after('*:field-blur', function(e) {
                that._removeCustomFormat(e);
             }, that));

            that._handlers.push(Y.after('*:field-click', function(e) {
                that._placeCursor(e);
            }, that));

            that._handlers.push(
                Y.delegate(
                    'click',
                    function(e) {
                        //spreadsheets need a small delay here
                        Y.later(30, that, function() {that._invNumber(e);});
                    },
                    'body',
                    '.ui-rm-keyboard',
                    this
                ),
                that
            );

            Y._mobilefielddecorator = this;
        },

        destructor: function () {
            Y.smst.Lang.detachEventListener(this._handlers);

            Y.Object.each(this._fields, function(it){
                it.destroy();
            }, this);
        },

        _smartFieldChanged : function(e) {
            var that = this;

            //console.log(e);
            that._handleKeyOnCustomFormat(e);
        },

        _smartFieldFocus : function(e) {
            var that = this;

            //console.log(e);
            that._initCustomFormat(e);
            that._placeCursor(e);
        },

        _smartFieldBlur : function(e) {
            var that = this;

            //console.log(e);
            that._removeCustomFormat(e);
        },

        _initCustomFormat : function(e) {

            var instance = this;

            if(!e || !e.yuiInstance || e.yuiInstance._customMobileFormatInitialized || !instance._isQualified(e.yuiInstance)) {
                //console.log('mobilefielddecorator: skipping init: '+e.yuiInstance);

                return;
            }

            //console.log('mobilefielddecorator: init custom field format');

            e.yuiInstance._customMobileFormatInitialized = true;

            var fmt = instance._getFormat(e.yuiInstance);

            if(fmt != null) {
                //https://css-tricks.com/finger-friendly-numerical-inputs-with-inputmode/
                if(navigator.userAgent.toLowerCase().indexOf('iphone') > 0 ||
                    navigator.userAgent.toLowerCase().indexOf('ipad') > 0) {

                    e.yuiInstance.node.setAttribute("pattern", "[0-9]*");

                    //ios doesn't recognize it one the first node update
                    if(!e.yuiInstance._secondFocus) {
                        e.yuiInstance._secondFocus = true;
                        e.yuiInstance.node.blur();
                        e.yuiInstance.node.focus();

                        return;
                    }
                }
                else {
                    e.yuiInstance.node.setAttribute("inputmode", "numeric");
                    e.yuiInstance.node.setAttribute("x-inputmode", "numeric"); //firefox, doesn't seem to work
                }
                e.yuiInstance.node.removeAttribute("maxlength");
                e.yuiInstance.node.setAttribute("autocomplete", "off");

                //console.log("value: "+e.yuiInstance.get('value'));

                if(e.yuiInstance.get('value') == "") {
                    e.yuiInstance.set('value', fmt.trim(), {src: 'tool'});
                }

                if(instance._isNegativeAllowedFormat(e.yuiInstance)) {
                    if (!e.yuiInstance.node.ancestor("div").one('.ui-rm-keyboard')) {

                        var n = Y.Node.create(
                            '<button type="button" class="button btn-mobile-inv ui-rm-keyboard">+/-</button>'
                        );

                        e.yuiInstance.node.ancestor('div').append(n);
                    }
                }
            }

            instance._fields[e.yuiInstance.node.get("id")] = e.yuiInstance;
        },

        _invNumber : function(e) {
            var instance = this;

            e.yuiInstance = instance._fields[e.currentTarget.ancestor("div").one('.onse-field').get("id")];

            var val = e.yuiInstance.get('value');

            if(val.indexOf('-') < 0) {
                val = "-"+val;
            }
            else {
                val = val.replace(/\-/g,'');
            }

            e.yuiInstance.set('value', val, {src: 'tool'});

            //console.log(val+": "+e.yuiInstance.get('value'));
        },

        _removeCustomFormat : function(e) {
            var instance = this;

            if(!e || !e.yuiInstance) {
                console.log("field not initialized!");

                return;
            }

            if(instance._isQualified(e.yuiInstance)) {

                var fmt = instance._getFormat(e.yuiInstance);
                if(fmt == null) {
                    return;
                }

                fmt = fmt.trim();

                //console.log("ret: "+fmt+"/"+e.yuiInstance.get('value'));

                if(fmt != null && e.yuiInstance.get('value') == fmt) {
                    e.yuiInstance.set('value', "", {src: 'tool'});
                }

                //Y.all('.ui-rm-keyboard').remove(true);

                e.yuiInstance._customMobileFormatInitialized = false;

                //now it's time to show errors again
                e.yuiInstance._check();
            }
        },

        _isQualified : function(field) {
            if(field.node.get('disabled')) {
                return false;
            }

            if(field.node.ancestor("div").one('.ui-spreadsheet-open')) {
                return false;
            }

            if(field.node.getDOMNode().nodeName == "SELECT") {
                //some of the correct types may be non-invertable list fields
                return false;
            }

            if(field.node.getAttribute("data-json") && JSON.parse(field.node.getAttribute("data-json")).tools) {
                //FIXME: PLZ tools interfere by checking. Enter "31303" and press backspace to reproduce
                return false;
            }

            if(field.node.hasClass('cdf')) {
                //don't augment the inputs that combin date fields, we'll aument those visible with class half instead
                return false;
            }

            return this._getFormat(field) != null;
        },

        _getFormat : function(field) {

            if(field) {
                switch (field.node.getAttribute('data-format')) {
                    //dates
                    case "Z":
                        return "__.__.____";
                    case "B":
                        return "__.__";
                    case "T":
                        return "__.__.";
                    case "D":
                        return "__.__.____";
                    case "E":
                        return "__.__.__";
                    case "L":
                        return "__.__";
                    case "K":
                        return "__";
                    case "W":
                        return "____/____";
                    case "JAHR":
                        return "____";
                    case "ZEIT":
                        return "__:__";
                    //numbers
                    case "U":
                        return "             ";
                    case "G":
                        return "             ";
                    case "R":
                        return "          _,__";
                    case "OD":
                        return "          _,__";
                    case "CUR":
                        return "          _,__";
                    case "POS":
                        return "          _,__";
                    case "P":
                        return "          _,__";
                    case "S":
                        return "          _,__";
                    case "M":
                        return "             ";
                    case "N":
                        return "             ";
                    case "O":
                        return "             ";
                }
            }

            return null;
        },

        _isNegativeAllowedFormat : function(field) {

            if(field) {

                switch (field.node.getAttribute('data-format')) {
                    case "OD":
                        return true;
                    case "CUR":
                        return true;
                    case "POS":
                        return true;
                    case "P":
                        return true;
                    case "S":
                        return true;
                    case "M":
                        return true;
                    case "N":
                        return true;

                }
            }

            return false;
        },

        _setCharAt :  function(str,index,chr) {
            if(index > str.length-1) return str;

            return str.substr(0,index) + chr + str.substr(index+1);
        },

        _getNumbers : function(e) {
            var numbers = "";

            for(var i = 0, len = e.yuiInstance.node.get('value').length; i < len; i++) {
                if(/[0-9\-]/.test(e.yuiInstance.node.get('value')[i])) {
                    numbers += e.yuiInstance.node.get('value')[i];
                }
            }

            return numbers;
        },

        _handleKeyOnCustomFormat :  function(e) {
            var instance = this;

            //console.log(e);

            if(!e || !e.yuiInstance || (e.src && e.src == 'tool')) {
                return;
            }

            var fmt = instance._getFormat(e.yuiInstance);

            //only process for fields on whitelist
            if(instance._isQualified(e.yuiInstance) && fmt != null) {

                var numbers = instance._getNumbers(e);

                //console.log(e.field);
                //console.log(e.yuiInstance.node.getAttribute("id"));
                //console.log('number calc input: '+e.yuiInstance.node.get('value')+", numbers: "+numbers);

                for(var i=fmt.length-1; i >= 0; i--) {
                    if(fmt[i] != "," && fmt[i] != "." && fmt[i] != "-" && fmt[i] != ":" && fmt[i] != "/") {
                        if(numbers.length > 0) {
                            fmt = instance._setCharAt(fmt, i, numbers[numbers.length-1]);
                            numbers = numbers.substring(0, numbers.length-1);
                        }
                    }

                    //console.log(fmt+" @"+i);
                }

                fmt = fmt.trim();

                var tmp = e.yuiInstance.get('value');
                if(fmt == tmp) {
                    //yui does not recognize a change if the value is the same, so manually trigger two instead
                    e.yuiInstance.set('value',instance._getFormat(),{src:'tool',skipCheck:true});
                }

                e.yuiInstance.set('value',fmt,{src:'tool',skipCheck:true});

                instance._placeCursor(e);
            }

        },

        _placeCursor : function(e) {
            var instance = this;

            if(!e || !e.yuiInstance) {
                return;
            }

            //only process for fields on whitelist
            if(instance._isQualified(e.yuiInstance)) {
                var val = e.yuiInstance.node.get('value');
                //console.log(val);
                document.getElementById(e.yuiInstance.node.get('id')).setSelectionRange(val.length, val.length);
            }
        }
    };

    Y.MobileFieldDecorator = Y.Base.create('MobileFieldDecorator', Y.Base, [], proto, {});

}, '1.0.0', {requires:[
        'base-build',
        'event',
        'interview-fields',
        'interview-field-factory',
        'smst-lang',
        "smart-form"
    ]} );
