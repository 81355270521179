YUI.add('taxnumberdecorator', function(Y) {

    var proto = {
        _handlers: [],

        initializer: function () {
            var that = this;

            that._handlers.push(Y.after('interview:fieldChanged', function(e) {
                that._handleKeyOnCustomFormat(e);
            }, that));

            that._handlers.push(Y.after('interview:fieldValueChanged', function(e) {
                that._initCustomFormat(e);
            }, that));

            that._handlers.push(Y.after('*:field-focus', function(e) {
                that._placeCursor(e);
            }, that));

        },

        destructor: function () {
            Y.smst.Lang.detachEventListener(this._handlers);
        },

        _initCustomFormat : function(e) {

            var instance = this;

            if(!e || !e.yuiInstance || e.yuiInstance._customFormatInitialized) {
                return;
            }

            //only set the base value once for the right field, and only if it's empty
            if(instance._isQualified(e.yuiInstance.node.getAttribute('name')) && e.yuiInstance.get('value') == "") {
                Y.log('taxnumber: init custom field format');

                var fmt = instance._getSnrFormat(e.yuiInstance.node.getAttribute('name'));

                if(fmt != null) { //only if state has been set and a strategy can be deduced
                    e.yuiInstance.set('value', fmt, {src: 'tool'});
                }
                e.yuiInstance._customFormatInitialized = true;
            }
        },

        _getSnrFormat : function(name) {
            var val = Y.UserCase.getValueFromCase("OF0017198", "Standard", 1); //fetch state from case

            if(name && name.indexOf("OF0007185") >= 0) {
                val = Y.UserCase.getValueFromCase("OF0012423", "Betrieb", 1); //fetch state from EUER case alternatively
            }

            if(name && name.indexOf("OF0013943") >= 0) {
                val = Y.UserCase.getValueFromCase("OF0014108", "V", Y._currentState.formIndex); //fetch state from V
            }

            if(name && name.indexOf("OF0012313") >= 0) {
                var caseField = Y.UserCase.getValueFromCase("OF0012424", "Betrieb", 1);
                val = {value: Y.UserCase.getPotentialMultiFieldValueFromField(caseField, Y._currentState.multiFieldIndex)}; //fetch state from EUER case alternatively
            }

            if(name && name.indexOf("OF0012341") >= 0) {
                var caseField = Y.UserCase.getValueFromCase("OF0012426", "V", 1);
                val = {value: Y.UserCase.getPotentialMultiFieldValueFromField(caseField, Y._currentState.multiFieldIndex)}; //EUER V+V
            }

            if(name && name.indexOf("OF0007193") >= 0) {
                val = Y.UserCase.getValueFromCase("OF0012883", "Betrieb_Photovoltaik", 1); //fetch state from EUER-PV case alternatively
            }

            if(name && name.indexOf("OF0012877") >= 0) {
                var caseField = Y.UserCase.getValueFromCase("OF0012875", "Betrieb_Photovoltaik", 1);
                val = {value: Y.UserCase.getPotentialMultiFieldValueFromField(caseField, Y._currentState.multiFieldIndex)}; //EUER-PV GuE
            }

            if(!val || val.value == null || val.value == "") {
                return null;
            }

            //Y.log("snr val: "+val.value);

            switch(val.value) {
                case "11":
                    return "__/___/_____";
                case "2":
                    return "__/___/_____";
                case "3":
                    return "__/___/_____";
                case "8":
                    return "_____/_____";
                case "9":
                    return "___/___/_____";
                case "10":
                    return "___/___/_____";
                case "5":
                    return "___/____/____";
                case "12":
                    return "___/___/_____";
                case "14":
                    return "___/___/_____";
                case "15":
                    return "___/___/_____";
                case "16":
                    return "___/___/_____";
                case "4":
                    return "__ ___ _____";
                case "1":
                    return "__ ___ _____";
                case "6":
                    return "___ ___ _____";
                case "13":
                    return "___/___/_____";
                case "7":
                    return "__/___/____/_";

            }

            return null;
        },

        _setCharAt :  function(str,index,chr) {
            if(index > str.length-1) return str;

            return str.substr(0,index) + chr + str.substr(index+1);
        },

        _isQualified : function(name) {
            //TODO: mapping and unify with getSnrFormat
            return name && (name.indexOf("OF0015013") >= 0 || name.indexOf("OF0007185") >= 0 ||
                name.indexOf("OF0012313") >= 0 || name.indexOf("OF0012341") >= 0||
                name.indexOf("OF0012877") >= 0 || name.indexOf("OF0007193") >= 0 ||
                name.indexOf("OF0013943") >= 0);
        },

        _getNumbers : function(e) {
            var numbers = "";

            for(var i = 0, len = e.yuiInstance.node.get('value').length; i < len; i++) {
                if(/[0-9]/.test(e.yuiInstance.node.get('value')[i])) {
                    numbers += e.yuiInstance.node.get('value')[i];
                }
            }

            return numbers;
        },

        _handleKeyOnCustomFormat :  function(e) {
            var instance = this;

            //console.log(e);
            //console.log(e.yuiInstance);
            //console.log(e.yuiInstance.lastKeyCode);

            if(!e || !e.yuiInstance || e.yuiInstance.lastKeyCode == 8 || (e.src && e.src == 'tool')) { //prevent backspace from messing up on slashes
                return;
            }

            var fmt = instance._getSnrFormat(e.yuiInstance.node.getAttribute('name'));

            //only process for fields on whitelist
            if(instance._isQualified(e.yuiInstance.node.getAttribute('name')) && fmt != null) {

                Y.log('taxnumber calc input: '+e.yuiInstance.node.get('value'));

                var numbers = instance._getNumbers(e);
                var idx = 0;

                //move valid numbers into the template
                for(var i = 0, len = fmt.length; i < len && numbers.length > 0; i++) {

                    if(fmt[i] != "/" && fmt[i] != " ") {
                        fmt = instance._setCharAt(fmt, i, numbers[0]);
                        numbers = numbers.substr(1);
                    }

                    idx++;

                    //Y.log("fmt: "+fmt+" / "+i+" / "+idx+":"+numbers.length);
                }

                //move cursor after next char if it's a blank
                if(fmt[idx] == "/" || fmt[idx] == " ") {
                    idx++;
                }

                Y.log("snr final: "+fmt);

                var tmp = e.yuiInstance.get('value');
                if(fmt == tmp) {
                    //yui does not recognize a change if the value is the same, so manually trigger two instead
                    e.yuiInstance.set('value',instance._getSnrFormat(),{src:'tool'});
                }

                e.yuiInstance.set('value',fmt,{src:'tool'});

                //move cursor to the right offset
                document.getElementById(e.yuiInstance.node.get('id')).setSelectionRange(idx, idx);
            }

        },

        _placeCursor : function(e) {
            var instance = this;

            if(!e || !e.yuiInstance) {
                return;
            }

            //only process for fields on whitelist
            if(instance._isQualified(e.yuiInstance.node.getAttribute('name'))) {
                var numbers = instance._getNumbers(e);
                var len = 0;
                var val = e.yuiInstance.node.get('value');

                for(var i = 0; i < val.length; i++) {
                    if(!/[0-9]/.test(val[i]) && i < numbers.length+len) {
                        len++;

                        //Y.log(i+"/"+numbers.length+"/"+len);
                    }
                }

                len += numbers.length;

                //move cursor after next char if it's a blank
                if(val.length > len && val[len] == "/" || val[len] == " ") {
                    len++;
                }

                document.getElementById(e.yuiInstance.node.get('id')).setSelectionRange(len, len);
            }
        }
    };

    Y.TaxnumberDecorator = Y.Base.create('TaxnumberDecorator', Y.Base, [], proto, {});

}, '1.0.0', {requires:[
    'base-build',
    'event',
    'interview-fields',
    'interview-field-factory',
    'smst-lang'
]} );
