YUI.add('interview-tools-plugin', function(Y) {

	"use strict";

	(function(){
		var proto, statics, clazz,
			NAME             = "OnSiteTool",
			URL_TEMPLATE_KEY = 'url-template',
			BUTTON_TEMPLATE  = '<div class="case-interview-field-input-tool-container">' +
					'<div class="case-interview-field-input-tool-btns case-interview-field-input-tool-btns-pointing">' +
					'<button type="button" class="yui3-tool-plugin-button btn btn-s {clazz} ui-case-interview-field-input-tool-btn">{label}</button>' +
					'</div></div>',
			DIV_TEMPLATE     = '<div class="yui3-tool-box"></div>';

		statics = {
			ATTRS: {
				config: {},
				type : {},
				name : {
					validator: Y.Lang.isString
				},
				key : {
					validator: Y.Lang.isString
				},
				triggerField: {},
				form: {}
			}
		};
		proto = {

			_outputFields: [],

			_handlerList : [],

			_button : null,

			_toolPanel : null,

			_rendered : false,

			// create a listener for value change events on the host.
			_buildAutoTool:function (host) {
				var that = this;

				that._handlerList.push( host.tools.afterHostEvent('valueChange', function(e) {
					if (e.newVal) {
						var url = Y.Lang.sub(that.get('config.' + URL_TEMPLATE_KEY), {value:e.newVal});

						//ONSE-12716: if a field clears an error we get a valueChange to ignore
						//TODO: fix event system or make this configurable instead of using the url + fixed length
						if(url.indexOf("/geo/plz") >= 0 && e.newVal.length < 5) {
							return;
						}

						url = '/api' + url;

						// lock the output fields...
						that._lockAndClearOutputFields();

						Y.io(url, {
							on: {
								success: function(ioId, resp) {
									var parsed = Y.JSON.parse(resp.responseText),
									    result = parsed.result;

									Y.log('Processing tool result...');

									Y.Array.each(that._outputFields, function(entry) {
										if (200 == parsed.code) {
											var value = Y.Object.getValue(result, entry.key.split('/'));
											if (value) {
												entry.field.set('value', value, {src:'tool'});
											}
										}
									}, that);

                                    Y.log('done.');
								},
								end : function() {
									that._unlockOutputFields();
								}
							},
							context: that
						});

					}
				}, that));
			},

			_buildButtonTool : function(host) {
				// render the button
				var name = this.get('name'),
					node = host.get('node'),
					config = this.get('config'),
					unitNode,
					box = Y.Node.create(DIV_TEMPLATE),
					button = Y.Node.create(Y.Lang.sub( BUTTON_TEMPLATE, {
						clazz: Y.ClassNameManager.getClassName('tool', name),
						label:'Route auf Karte berechnen'
					}));

				// check for a unit node - which we need to transfer into the new box as well.
				unitNode = node.get('parentNode').one('.ui-case-interview-field-unit');

				node.replace(box);
				box.append(button);
				box.append(node);
				if( unitNode ) {
					unitNode.remove();
					box.append(unitNode);
				}

				this._button = button;

				// listen for clicks
				this._handlerList.push(
					this._button.one('.yui3-tool-plugin-button').on('click', function(e, config){

                        // TODO: hier muss ein Weg gefunden werden, wie pro Tool ein bestimmtes script aufgerufen wird.
                        // only render each panel once
                        if( null == this._toolPanel) {
                            this._toolPanel = new Y.tool.RoutesPanel(Y.mix({
                                zIndex:98,
                                headerContent: 'Entfernung berechnen',
                                visible : false
                            }, config ));
                            this._toolPanel.render('body');
                        }

                        // listen for incomming results
                        this._handlerList.push(
                            this._toolPanel.after('resultChange', function(e){
                                var val = e.newVal;
                                host.set('value', host.format.prettify(val), {src:'tool'});
                                //TODO: handle this in field by src after refactoring
                                host.set('templateValueHadFocus', true);
                                host._removeShallow();
                                host.touch();
                            }, this)
                        );

						// update the configuration
						this._toolPanel.setAttrs(config);
						this._toolPanel.show();
					}, this, config )
				);

			},

			_setup : function() {
				var that = this,
					host = that.get('triggerField'),
					config = that.get('config'),
					type = that.get('type');

				Y.Object.each(config, that._setUpConfigAttr, that);

				if( 'AUTO' === type ) {
					that._buildAutoTool( host );
				} else if( 'BUTTON' === type ) {
					that._buildButtonTool( host );
				} else {
					Y.error('Unknown tool type!');
				}
			},

			initializer: function(){
				var that = this;

				Y.log('Initializing tool: "' + that.get("name") + '"', null, NAME);

                Y.onceAfter('interview:rendered', function (e) {
					that._rendered = true;
					Y.log("Regular tool setup");

					that._setup();
             	}, that);

				//ONSE-10753 sometimes there are timing problems with the interview:rendered setup (in combination with/without inliners), fall back here
				Y.later(2000, that, function() {
					if(!that._rendered) {
						Y.log("Fallback tool setup");

						that._setup();
					}
				})
			},

			createFieldEntry:function (field, key) {
				return {
					field:field,
					key:key
				};
			},

			_setUpConfigAttr : function(config, key){
				Y.log('Adding attribute "'+key+'"', null, NAME);

				var fullKey = 'config.'+key,
					field = null,
					configObject,
					value = config.value ? config.value.replace(/^\s+|\s+$/g, '') : config.value;

				//noinspection FallthroughInSwitchStatementJS
				switch( config.type ) {
					case 'RESULT_FIELD':
						field = this._findFieldByName(value);
						this._outputFields.push(this.createFieldEntry(field, key));
						break;

					case 'CASE_VALUE':
						// read the value out of the case.
						configObject = Y.JSON.parse(value);
                        var caseField = Y.UserCase.getValueFromCase(configObject.field, configObject.form, configObject.idx);
						value = caseField != null ? caseField.value : "";

						this.set(fullKey, value);
						break;

					case 'GENERIC_VALUE':
						// ONSE-4309 -> don't set any attribute containing a '/' in its key.
						if( -1 < fullKey.indexOf('/') ) {
							Y.error('Invalid key for generic value! Can not go on.');
						}
						this.set(fullKey, value);

						break;

                    case 'INPUT_FIELD':
                        field = this._findFieldByName(value);

                        if(field) {
                           value = Y.bind(function () {
                               return this.get('value').replace(/\n/g, ', ');
                           }, field);

                           this.set(fullKey, value);
                        }
                        else {
                            value = Y.UserCase.searchRealValueFromCase(value, Y._currentState.formIndex, Y._currentState.multiFieldIndex);

                            if(!value) {
                                value = Y.UserCase.searchRealValueFromCase(value, Y._currentState.formIndex, 0);
                            }
                            if(!value) {
                                value = Y.UserCase.searchRealValueFromCase(value, 1, 0);
                            }

                            if(value) {
                                value = value.replace(/\"/g, '');
                            }

                            this.set(fullKey, value);
                        }

                        break;

				}
			},

			_findFieldByName : function(val) {
				Y.log('Looking for field "'+val+'"', null, NAME);
				var form = this.get('form'),
					result = null;

				Y.Object.some( form.fields, function(field) {
					if( field.get('name') == val) {
						result = field;
						return true;
					}
				}, this);

				if( !result ) {
					Y.log('No field with this name was found!');
				}
				return result;
			},

			_lockAndClearOutputFields: function() {
				for (var i = 0; i < this._outputFields.length; i++) {
					var field = this._outputFields[i].field;
					field.set('readOnly', true);
					field.set('value', '', {src:'tool'});
				}
			},

			_unlockOutputFields: function() {
				for (var i = 0; i < this._outputFields.length; i++) {
					var field = this._outputFields[i].field;
					field.set('readOnly', false);
					// Set the focus to the first output field.
					if( i === 0) {
						field.node.focus();
					}
				}
			},

			destructor : function() {
				for (; 0 < this._outputFields.length;) {
					this._outputFields.pop();
				}

				for (var i = 0; i < this._handlerList.length; i++) {
					this._handlerList.pop().detach();
				}

				this._button && this._button.remove(true);

				this._toolPanel && this._toolPanel.destroy();
			}

		};

		clazz = Y.Base.create(NAME, Y.Base, [], proto, statics );

		Y.namespace("interview").OnSiteTool = clazz;
	})();




	(function(){
		var proto, statics, clazz,
			NAME = "ToolsPlugin";

		statics = {
			NAME: NAME,
			NS: 'tools',
			ATTRS: {
				configs: {
					value: []
				}
			}
		};

		proto = {

			_tools: [],

			_handler : [],

            initializer: function() {
				var configs = this.get('configs');

				for (var i = 0; i < configs.length; i++) {

					var config = configs[i];
					config.form = this.get('host').get('form');
					config.triggerField = this.get('host');
                    this.get('host').tools = this;

                    //TODO: reenable mobile route tool after it's response enough to provide value
					if(!(Y.one('#ui-is-mobile').get('value') == 'true' && config.name === "route")) {
						this._tools.push( new Y.interview.OnSiteTool(config) );
					}
				}
			},

			_destroyHandler: function() {
				var i;
				for( i = 0; i < this._handler.length; i++) {
					this._handler[i] && this._handler[i].detach();
					this._handler[i] = null;
				}
			},

			/**
			 * Destroy all created tools.
			 */
			destructor: function() {
				var i;
				for (i = 0; i < this._tools.length; i++) {
					this._tools[i].destroy();
				}
				this._destroyHandler();
			}

		};


		/**
		 * When ever a field in created, the FieldFactory checks for tool configurations. If at least one is found the
		 * field will get an instance of this class plugged into it.
		 *
		 * @class ToolsPlugin
		 * @extends OnseBase
		 * @constructor
		 * @cfg {object} configuration attributes
		 */
		clazz = Y.Base.create(NAME, Y.Plugin.Base, [], proto, statics );

		Y.namespace("interview").ToolsPlugin = clazz;

	})();
}, '1.0.0', {requires:[
	'plugin',
	'io-base',
	'json-parse',
	'tool-routes-panel',
	'datatype-number-format'
]} );

