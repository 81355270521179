// --------------------------------------------------------------------------------------------------------------------
// -----   spreadsheet overlay   --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------

YUI.add('spreadsheet-overlay', function(Y) {

	var YCreate = Y.Node.create,
		LABEL_NODE = 'labelNode',
		SpreadsheetOverlay;

	SpreadsheetOverlay = Y.Base.create('SpreadsheetOverlay', Y.Overlay,[Y.AppOverlays], {

		overlays : {
			unsavedChanges : {
				header:"Ungesicherte Änderungen",
				footer:'<button type="button" class="btn" id="unsaved-discard">Verwerfen</button><button type="button" class="btn btn-hilited" id="unsaved-save">Übernehmen</button>',
				template: 'case-editor-interview-overlay-unsaved',
				events : {
					'#unsaved-save' : {
						click : '_saveAndClose',
						keypress : '_saveAndClose'
					},
					'#unsaved-discard' : {
						click : '_forceClose',
						keypress : '_forceClose'
					}
				},
				// don't add escape listeners and buttons
				notEscapable : true
			},
			confirmDeleteRow : {
				header:"Wirklich löschen?",
				footer:'<button type="button" class="btn btn-destructive notranslate" id="delete-ok">Löschen</button><button type="button" class="btn btn-hilited" id="delete-cancel">Abbrechen</button>',
				template: 'case-editor-interview-overlay-delete',
				events : {
					'#delete-ok'  : { click : '_closeConfirm' },
					'#delete-cancel' : { click : '_closeConfirm' },
					'.yui3-widget'     : { keypress : '_closeConfirm' }
				},
				// don't add escape listeners and buttons
				notEscapable : true,
			}

		},

		_closeConfirm : function(e,payload) {
			// only close on enter
			if( e.keyCode && 13 !== e.keyCode ) {
				return;
			}
			e.halt();

			payload.overlay.hide();
			payload.callback && payload.callback('delete-ok' === e.target.get('id'), payload);
		},

		/**
		 * This is an extra wrapper around the normal 'showOverlay' method to take into account that we are an overlay
		 * ourselves.
		 *
		 * @private
		 */
		_showOverlayOverlay : function(name, e) {
			var that = this;

			that.showOverlay(name, e, e);
		},

		_close : function() {
			this.set('ok',true);
			this.hide();

			if(Y.one('#ui-is-mobile').get('value') == 'true') {
				Y.one("#body").removeClass("ui-is-open-spreadsheet");
				this.fire('spreadsheetClosed');
			}
		},

		_closePrevented : function() {
			this._showOverlayOverlay('unsavedChanges');
		},

		_checkForDeleteRow : function(e) {
			this._showOverlayOverlay('confirmDeleteRow', e);
		},

		_forceClose : function(e, overlayPayload) {
			// return early if return wasn't hit.
			if( e.keyCode && 13 !== e.keyCode ) {
				return;
			}
			e.halt();

			Y.log('Forcing close...', 'INFO', "SpreadsheetOverlay");
			this.fire('close', {force: true});
			overlayPayload.overlay.hide();
		},

		_saveAndClose : function(e,overlayPayload) {
			var spreadsheet;

			// return early if return wasn't hit.
			if( e.keyCode && 13 !== e.keyCode ) {
				return;
			}
			e.halt();

			Y.log('Trying to save and close...', 'INFO', "SpreadsheetOverlay");
			spreadsheet = this.get('spreadsheet');
			if(spreadsheet._save()) {
				this.fire('close');
			}
			overlayPayload.overlay.hide();
		},

		/**
		 * This is called whenever this overlay gets closed via the ok button.
		 */
		_checkForUnsavedChanges : function(e) {
			var that = this,
				sheet = that.get('spreadsheet'),
				empty = true;

			Y.log("Checking for unsaved changes...");

			if(!e.force) {
				Y.Array.each( sheet._editorFields, function(it){
					empty = empty && ('' === it.get('value'));
				}, that);

				if(!empty) {
                    if(sheet._runValidation()) {
                        sheet._save();
                    }
                    else {
                        e.preventDefault();
                    }
				}

			}
		},

		initializer: function(config) {
			var that = this,
				c = {};

			c.width = config.width || 1000;
			c.height = config.height || 620;
			c.zIndex = config.zIndex || 98;
			c.centered = config.hasOwnProperty('centered') ? config.centered : true;
			c.headerContent = config.headerContent || '<span class="spreadsheet-header-label"></span>';
			c.bodyContent = config.bodyContent || '';
			c.footerContent = config.footerContent ||
					'<button type="button" class="btn btn-hilited ui-btn-ok app-pull-right">Ok</button>';
			// disabling the auto height adjustment.
            c.fillHeight = null;

			that.setAttrs(c);

			that.plug(Y.gorilla.widget.XButtonPlugin);
			that.plug(Y.gorilla.widget.CancelButtonPlugin, {
				template: '<button type="button" class="btn ui-cancel-btn app-pull-left">Abbrechen</button>',
				section: Y.WidgetStdMod.FOOTER
			});
			that.plug(Y.gorilla.overlay.AnimPlugin, {
				duration: 0.4
			});
			that.plug(Y.Plugin.OverlayModal);

			that.set('bodyContent', that.get('tableNode'));

			// closing this overlay will be done in two steps. Therefor we need an event to start this process.
			that.publish('close', {
				emitFacade : true,
				preventedFn : that._closePrevented,
				defaultFn : that._close,
				context: that
			});
		},

		prepare : function(headline, spreadsheet) {
			var that = this;

			if( null == that.get(LABEL_NODE)) {
				that.set(LABEL_NODE, that.getStdModNode(Y.WidgetStdMod.HEADER).one('.spreadsheet-header-label'));
			}

			if(!headline) {
				headline = "";
			} else {
				headline = Y.smst.Utils.textAreaEscape(headline);
			}

			that.get(LABEL_NODE).setContent(headline);
			that.set('ok', false);

			// To have the spreadsheet scrolling
			// we have to set the height here.
            if(Y.one('#ui-is-mobile').get('value') == 'true') {

            }
            else {
                spreadsheet.set('height', 530);
                spreadsheet.set('scrollable', 'y');
            }

			spreadsheet.render(that.get('tableNode'));

			that.set('spreadsheet', spreadsheet);


            if(Y.one('#ui-is-mobile').get('value') == 'true') {
                //the x button was fixed for other overlays, doesn't fit here and cannot easily be fixed by css inherit
				//also it can only easily override !important this way
                Y.one('.yui3-overlay-xbutton').getDOMNode().style.setProperty('position', 'absolute', 'important');
                Y.one('.yui3-overlay-xbutton').getDOMNode().style.setProperty('top', '0px', 'important');
                Y.one('.yui3-overlay-xbutton').getDOMNode().style.setProperty('left', 'auto', 'important');
            }

		},

		renderUI : function() {
			var instance = this;
			instance.get('contentBox').addClass('yui3-overlay-content');
		},

		bindUI : function() {
			var that = this;

			// prepare the event handlers - in case we have some old ones here, remove them.
			Y.Array.each( this._handlers, function(it){it.detach();});
			that._handlers = [];
			that._handlers.push(that.on('close', that._checkForUnsavedChanges, that));
			that._handlers.push(Y.on('*:confirmDeleteRow', that._checkForDeleteRow, that));

			that._handlers.push( that.get('boundingBox').delegate('click', function(e) {
					e.preventDefault();
					that.fire('close');
				},'.ui-btn-ok', that )
			);

		},

		destructor : function() {
			Y.Array.each( this._handlers, function(it){it.detach();});
		}

	}, {
		ATTRS: {
			'spreadsheet' : {},
			labelNode: {
				writeOnce: true
			},
			tableNode: {
				value : YCreate('<div class="spreadsheet-data-table-node"></div>')
			},
			ok : {
				value : false
			}

		}
	});

	Y.namespace("spreadsheet").SpreadsheetOverlay = SpreadsheetOverlay;

}, '1.0.0' ,{
	requires:[
		'app-overlays',
		'base-build',
		'node',
		'overlay',
		'dd-drag',
		'event-outside',
		'gallery-overlay-extras',
		'gorilla-widget-x-button-plugin',
		'gorilla-widget-cancel-button-plugin'
	]
});
