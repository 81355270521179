YUI.add('smart-form-choice-config', function (Y, NAME) {

"use strict";
Y.ChoiceField.Config = Y.Base.create('TableListConfig', Y.Model, [Y.ModelSync.REST], {

	root: '/combobox/data',

	getDisplayValue : function(value) {
		return this._valueHash[value];
	}

}, {
	ATTRS: {
		// id is part of the parent class and does not need to be listed here.
		name: {value: null },
		data: {
			value: [],
			setter: function (val) {
				var hash;
				// after parsing the data we have to post process the lables.
				function processEntries(entries, hash) {
					Y.Array.each(entries, function (it) {
						it.label = it.label.join(' | ');
						if(Y.Lang.isArray(it.value)) {
							processEntries(it.value, hash);
						} else {
							hash[it.value] = it.label;
						}
					});
				}

				if (val) {
					hash = {};
					processEntries(val, hash);
					this._valueHash = hash;
				}
				return val;
			}
		},
		forceSelection: {value: false }
	}
});


}, '1.0.0', {"requires": ["base-build", "json-parse", "model", "model-sync-rest", "smart-form"]});
