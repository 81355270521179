YUI.add('case-editor-app-assessment', function (Y) {
	"use strict";

	var Slang = Y.smst.Lang;

	Y.CaseEditorAppAssessmentDiffView = Y.Base.create('CaseEditorAppAssessmentDiffView', Y.HandlebarsView, [], {
		template: 'case-editor-assessment-diff'

	}, {
		ATTRS: {
			mainDiff: {},
			container:{
				valueFn:function () {
					return Y.Node.create('<div class="case-editor-assessment-result-main"/>');
				}
			}

		}
	});

	Y.CaseEditorAppAssessment = Y.Base.create('CaseEditorAppAssessment', Y.smst.CaseEditorAppBase, [], {

		_handler:[],

        markDirty : false,

		_template : null,
		_anonymous : true,

		events : {
			'#ui-case-editor-assessment-form-save' : {click:'setAssessment'},
			'#ui-case-editor-assessment-details-table-toggle' : {click:'_toggleDetails'},
			'.ui-case-editor-assessment-switch' : {click:'_toggleSelection'}
		},

		initializer:function () {
			var that = this;

            that.publish('assessmentValueChange');
			
            that._handler.push(that.get('container').delegate('change', function(e) {
                if(!e.target.hasClass('ui-case-editor-assessment-switch')) {
                    that._markDirty = true;
                }
				if(e.target.hasClass('ui-case-editor-assessment-settings-item-allocate-default')) {
					e.target.get('parentNode').next('div').setStyle('opacity', e.target.get('checked') ? '0.3' : '1.0');
				}
            }, 'input', that));

            that._handler.push( that.on('*:nextSection', that.confirmToLeave, that) );

			Y.onceAfter('smartsteuer:ready', function (e) {
				that._anonymous = e.anonymous;
			}, that);
		},

        confirmToLeave : function(e) {
			var that = this;

            if(e.force) {
                return;
            }

            e.halt();

            if(that._markDirty) {

                that.fire('confirm', {
                    msg:'Sie haben ungesicherte Änderungen -- wollen Sie diese wirklich verwerfen?',
                    callback : function(ok) {
	                    if( ok ) {
		                    that.fire('nextSection',{force:true});
	                    }
                    }
                });
            }
            else {
                this.setAssessment(null, true);
            }
        },

		render:function () {
			// first call our super classs' render method
			this.constructor.superclass.render.apply(this);

			// lookup our templates...
			this._template = Y.HandlebarsTemplates.lookup('case-editor-assessment-main');
			Y.HandlebarsTemplates.lookup('common-registerCTA',true);

			// stick to the best practice and return this at the end of render.
			return this;
		},

		destructor:function () {
			Slang.detachEventListener(this._handler);
		},

		// --- navigation handling

		// this is our new 'openArea'
		handleNavigation:function (navInfo) {
			Y.log('handleNavigation', 'DEBUG', 'CaseEditorAppAssessment');

			this.updateValues();

            if(Y.one("#case-editor-main-controls")) {
                Y.one("#case-editor-main-controls").setStyle('display', 'none');
            }
		},

        enhanceLabels : function(json, template) {

            Y.Array.each(json, function(it) {
                if(it.label == 'naming') {

                    template.all(".ui-label-male").each(function(node) {
                        node.setContent(Y.Escape.html(it.male));
                    });
                    template.all(".ui-label-female").each(function(node) {
                        node.setContent(Y.Escape.html(it.female));
                    });

                    return;
                }
            });
        },

        syncKbk : function(e) {
            var idx = 1;

            while(Y.one('#row_sa_kbk_split_'+idx) != null) {

                var cb = Y.one('#sa_kbk_half_'+idx);
                var node = Y.one('#row_sa_kbk_split_'+idx);

                if(node) {
                    var style = node.getStyle('display');

                    if(cb && cb.get('checked')) {
                        node.setStyle('display','none');
                    }
                    else {
                        node.setStyle('display','block');
                    }
                }

                idx++;
            }
        },

		updateValues:function (manualUpdate, forceNext) {
			var that = this;

            that._markDirty = false;

			var cfg = {
				on:{
					start: function () {
						this.fire('waiting', {msg: 'Bitte warten …'});
					},
					success:function (id, o) {
                        var rgv = null;
                        var rzv = null;
                        var rgv50 = null;
						var config = Y.JSON.parse(o.responseText);
						var json = config.rawResultList;

						var template = Y.Node.create(that._template({anonymous:that._anonymous}));
						var row = template.one('.case-editor-assessment-settings-item-allocation').cloneNode(true);

						var row_kind = template.one('.case-editor-assessment-settings-item-versicherung-kind').cloneNode(true);
						template.all('.case-editor-assessment-settings-item-versicherung-kind').each(function (node) {
							node.remove();
						});

						var row_kbk = template.one('.case-editor-assessment-settings-item-kbk-half').cloneNode(true);
						template.all('.case-editor-assessment-settings-item-kbk-half').each(function (node) {
							node.remove();
						});

						template.all('.case-editor-assessment-settings-item-allocation').each(function (node) {
							node.remove();
						});

						var diffView = new Y.CaseEditorAppAssessmentDiffView();
						diffView.set('mainDiff', config.mainDiff);
						diffView.render();
						template.one('.case-editor-assessment-details-result').append(diffView.get('container'));

						Y.each(json, function (obj, idx) {
							if (obj.type == 'param') {

								if (obj.param.indexOf("sa_kbk_half") == 0) {
									var id = 'row_' + obj.param;
									var child = row_kbk.cloneNode(true);
									var defValue = (obj.value && obj.value != "" && obj.value >= 0);

									child.set('id', id);
									child.one('label').set('for', obj.param);
									child.one('label').set('innerHTML', obj.label);
									child.one('input').set('name', obj.param);
                                    child.one('input').set('id', obj.param);
									if (defValue) {
										child.one('input').setAttribute('checked', defValue);
									}

                                    that._handler.push(child.one('input').on('click', that.syncKbk, that));

									template.one('.case-editor-assessment-settings-list').appendChild(child);
								}
								else {
									var id = 'row_' + obj.param;
									var child = row.cloneNode(true);
									var defValue = (obj.value && obj.value >= 0) ? obj.value : 50;

									child.set('id', id);
									child.one('label').set('for', obj.param);
									child.one('label').set('innerHTML', obj.label);
									child.one('.ui-case-editor-assessment-settings-item-allocation-value').set('name', obj.param);
									child.one('.ui-case-editor-assessment-settings-item-allocation-value').setAttribute('value', defValue);
									child.one('.ui-case-editor-assessment-settings-item-allocation-value-fc').setAttribute('value', (100 - defValue));

									if(obj.useDefaultEnabled) {
										child.one('.ui-case-editor-assessment-settings-item-allocate-default').set('name', 'default_' + obj.param);
										child.one('.ui-case-editor-assessment-settings-item-allocate-default').set('checked', obj.useDefault);
										child.one('.ui-case-editor-assessment-settings-item-allocate-manually').setStyle('opacity', child.one('.ui-case-editor-assessment-settings-item-allocate-default').get('checked') ? '0.3' : '1.0');
									}
									else {
										child.one('.ui-case-editor-assessment-settings-item-allocate-default').get('parentNode').remove();
									}
									template.one('.case-editor-assessment-settings-list').appendChild(child);

									var slider = new Y.Slider({
										axis:'x',
										clickableRail:true,
										min:0,
										max:100,
										value:parseInt(""+defValue),
										thumbUrl:'data:image/png;base64,' +
											'iVBORw0KGgoAAAANSUhEUgAAAA0AAABICAYAAAAtfn+jAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFn' +
											'ZVJlYWR5ccllPAAAARtJREFUeNrslEEKglAQhl/SARQXieBCRGjr2hu47AZdIewW0QE8hEtvIC09gIg7' +
											'0ZWC+2z+eIkElkXQZgZ+xTf/x7z3ZnA1DIP4NBTxRTDE0A+gdRRF0+8dKSD5JIdUkFJSQopHSL43pNAw' +
											'jINt24ppmkLTNNG27baqqm1Zlvu6rs/kOZGaBxS6rht6nidUVR3L6rp+l2VZSpZlYZ7nWD7iTDtUeAam' +
											'gXXk4YMfUIAtzQFTED748fBxhiUhfT4gB4deEtLnACrolhZB0lcASulaF0HSlwJKqA/XruteAsjDBz+g' +
											'GI2jPog5EOvIywbHj+ae0Li+758n4r4lVJhMxDhGDTpNiQvp7eyt+A/LEEMMMcQQQwwx9AfoJsAARul6' +
											'XRsuYQYAAAAASUVORK5CYII='	// thumburl image: case-editor-assessment-slider-thumb
									});

									slider.render(child.one('.case-editor-assessment-settings-item-allocation-slider'));

									slider.after('valueChange', function (e) {
										child.one('.ui-case-editor-assessment-settings-item-allocation-value').setAttribute('value', e.newVal);
										child.one('.ui-case-editor-assessment-settings-item-allocation-value-fc').setAttribute('value', (100 - e.newVal));

                                        that._markDirty = true;
									}, that);

									//ONSE-4722
									if (obj.param.indexOf('sa_versicherung_kind') == 0) {
										var sub = row_kind.cloneNode(true);
										sub.one('textarea').setAttribute('name', 'textarea_' + obj.param);

										if (obj.value2 && obj.value2 != null && obj.value2 != "") {
											sub.one('textarea').set('innerHTML', obj.value2);
										}

										template.one('.case-editor-assessment-settings-list').appendChild(sub);
									}
								}
							}
							else if (obj.type == 'result') {
								//Y.log('result: '+obj.label+" / "+obj.value);

								if (obj.id === 'diff' || obj.id === 'diff50') {
									// nothing happens here!
								}
								else {
									if (obj.id.indexOf('result') == 0) { //show positive result, field is negative for "good" values
										obj.value = obj.value * -1;
									}

									var cell = template.one('.' + obj.id);
									if( cell ) {
										cell.set('innerHTML', that.format(obj.value));
									}

                                    if(obj.id == 'result_gv') rgv = that.format(obj.value);
                                    if(obj.id == 'result_sc') rzv = that.format(obj.value);
                                    if(obj.id == 'result_gv50') rgv50 = that.format(obj.value);
								}
							}
							else if (obj.type == 'assessment') {
								template.all('input.assessment').each(function (it) {
									//Y.log('it: '+it.get('value')+"/"+obj.value);

									if (obj.value == it.get('value')) {
										it.setAttribute('checked', 'checked');
										it.ancestor('li').addClass('case-editor-assessment-choice-selected');

                                        var choice = 'Zusammenveranlagung';
                                        if(obj.value == 'gv') {
                                            choice = 'Einzelveranlagung mit individueller Aufteilung';
                                        }
                                        else if(obj.value == 'gv50') {
                                            choice = 'Einzelveranlagung mit 50%-Regel';
                                        }

                                        template.one('#case-editor-assessment-details-result-main-best-label').set('innerHTML', choice);
									}
									else {
										it.removeAttribute('checked');
										it.ancestor('li').removeClass('case-editor-assessment-choice-selected');
									}
								});
							}
						}, that);

                        if (manualUpdate) {
							template.one('.ui-case-editor-assessment-update-message').setStyle('display', 'block');
                            that._toggleDetails();
                        }

                        that.enhanceLabels(json, template);

                        if(template.one('.case-editor-assessment-settings-list').one('li') == null) {
                            template.one('.case-editor-assessment-settings').setStyle('display', 'none');
                            template.one('.case-editor-assessment-form-action').setStyle('display', 'none');
                        }

                        that.get('container').setHTML(template);
						that.fire('removeWaiting');

                        that.syncKbk();

                        //sync calculation result
                        var v = Y.one('#case-editor-assessment-switch-separate').get('checked');
                        if(!v) {
                            v = Y.one('#case-editor-assessment-switch-joint').get('checked')
                        }
                        if(!v) {
                            v = Y.one('#case-editor-assessment-switch-separate-50').get('checked')
                        }

                        if(v) {
                            if(Y.one('#case-editor-assessment-switch-separate').get('checked')) {
                                //that.fire('assessmentValueChange', {calculation: ''+rgv});
                                that.fire('assessmentValueChange');
                            }
                            else if(Y.one('#case-editor-assessment-switch-separate-50').get('checked')) {
                                that.fire('assessmentValueChange');
                                //that.fire('assessmentValueChange', {calculation: ''+rgv50});
                            }
                            else {
                                //that.fire('assessmentValueChange', {calculation: ''+rzv});
                                that.fire('assessmentValueChange');
                            }
                        }

                        if (manualUpdate) {
                            that._toggleDetails();
                        }

                        if(forceNext) {
                            that.fire('nextSection', {force: true});
                        }
					},
					failure:function (id, o) {
                        that.fire('alert', {msg:"Abruf der Daten fehlgeschlagen!"});
					}
				},
				context:that
			};


			Y.io("evaluation/config", cfg);
		},

		format:function (value) {
			value = parseFloat(value) + 0.00001;

			return Y.DataType.Number.format(value, {
				decimalPlaces:2,
				decimalSeparator:",",
				thousandsSeparator:"."
			});
		},

		_removeHandler:function (handler) {
			if (handler) {
				handler.detach();
				handler = null;
			}
		},

		setAssessment:function (e, forceNext) {
			var ioConfig = {
				on:{
					success:function () {
						this.updateValues(true, forceNext);
					},
					failure:function () {
						this.updateValues(false);

						alert('Setzen der Werte fehlgeschlagen, bitte prüfen Sie Ihre Eingaben!');
					}
				},
				context:this,
				method:'POST',
				form:{
					id:'case-editor-assessment-form',
					useDisabled:true
				}
			};
			Y.io('evaluation/setAssessment', ioConfig);
		},

		_toggleDetails : function(e) {

			if(e) {
                e.halt();
            }

            //switch the text
            var textNode = Y.one('#ui-case-editor-assessment-details-table-toggle').one('i')
            if (textNode) {
                if(textNode.hasClass('fa-chevron-down')) {
                    textNode.removeClass('fa-chevron-down');
                    textNode.addClass('fa-chevron-up');
                }
                else {
                    textNode.removeClass('fa-chevron-up');
                    textNode.addClass('fa-chevron-down');
                }
            }

            // toggle visibility
            var detailNode = Y.one('#ui-case-editor-assessment-details-table-container');
            if (detailNode) detailNode.toggleClass('ui-display-none');
		},

		_toggleSelection : function(e) {
			var current = e.target,
				container = this.get('container');

			container.all('.ui-case-editor-assessment-switch').each(function(it) {
				it.ancestor('li').removeClass('case-editor-assessment-choice-selected');
			});
			current.ancestor('li').addClass('case-editor-assessment-choice-selected');
		}

	}, {
		ATTRS:{

			container:{
				valueFn:function () {
					return Y.Node.create('<div id="case-assessment"/>');
				}
			}
		}
	});


}, '1.0.0', {
	requires:[
		'app',
		'base-build',
		'case-editor-app-base',
		'handlebars-view',
		'io-base',
		'node',
		'smst-lang',
		'oop',
		'slider',
		'node-load',
		'datatype-number'
	]});