YUI.add('interview-errors', function(Y) {

    "use strict";

    var proto, statics,
   		NAME = 'Errors';


   	statics = {
   		NAME:NAME,
   		ATTRS: {

   		}
   	};

   	proto = {
        _handler: [],

        initializer: function() {
            var instance = this,
                handler = instance._handler;

            // sanity check
            instance._detachAll();

            handler.push(Y.on('errors|*:error', instance._handleError, instance));
            handler.push(Y.on('errors|*:clear-error', instance._handleErrorCleared, instance));
        },

        destroy: function() {
            var instance = this;
            instance._detachAll();
        },

        _detachAll : function() {
            while( 0 < this._handler.length ) {
                 this._handler.pop().detach();
            }
        },

        _handleError : function(e) {
            var f = e.field,
                msg = f.get('error'),
                info = {msg:msg, label:f.label, y:f.node.getY(), x:f.node.getX()};
	        // before adding any new error we will remove any previous ones.
	        this._handleErrorCleared(e);
            f.parentNode.append(Y.Lang.sub('<div class="case-editor-interview-field-problem-details"><span>{msg}</span></div>', info));
        },

        _handleErrorCleared : function(e) {
            var description = e.field.parentNode.all('.case-editor-interview-field-problem-details');

            if( 0 < description.size() ) {
                description.remove(true);
            }
        }
    };

    Y.Errors = Y.Base.create(NAME, Y.Base, [], proto, statics);

}, '1.0' ,{requires:[
    'base-build'
]});