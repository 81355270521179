YUI.add('spreadsheet-definitions', function(Y) {

	var DEFINITIONS = 'definitions',
		NAME = 'SpreadsheetDefinitions';

	function SpreadsheetDefinitions() {
		SpreadsheetDefinitions.superclass.constructor.apply(this, arguments);
	}

	Y.mix(SpreadsheetDefinitions, {

		NAME: NAME,

		ATTRS: {
			definitions: {
				writeOnce: true
			}
		}
	});

	Y.extend(SpreadsheetDefinitions, Y.Base, {

		initializer: function() {
			var instance = this;
			instance._readDefinitions();
		},

		_readDefinitions : function() {
			var instance = this,
				cfg = {
					on: {
						success: function(id, o) {
							var instance = this,
								defArray = Y.JSON.parse( o.responseText ),
								def,
								defs = {};
							Y.Array.each(defArray, function(it){
								try {
                                    def = Y.JSON.parse( it.definition );

									defs[instance._getKey(it.id)] = def;
								} catch(e){
									Y.error('Spreadsheet definition can not be parsed: ' + e.message);
								}
							}, instance);

							instance.set(DEFINITIONS, defs);
						}
					},
					context: instance,
					headers: { 'Accept': 'application/json' }
				};

			Y.io('spreadsheet/list', cfg);
		},

		_getKey : function(id) {
			return 'spreadsheet_'+id;
		},

		/**
		 * Returns the definition of the spreadsheet or undefined.
		 * @param id
		 */
		getDefinition : function( id, caption ) {
			var instance = this,
					clone = Y.clone(instance.get(DEFINITIONS)[instance._getKey(id)]);

			clone.headline = clone.caption = caption || clone.value;

			return clone;
		}

	});


	Y.namespace("spreadsheet").SpreadsheetDefinitions = SpreadsheetDefinitions;

}, '1.0.0' ,{
	requires:[
		'base-build',
		'recordset-base',
		'io-base',
		'json'
	],
	skinnable : false
});
