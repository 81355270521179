//holds http-session-based configuration info like token and user-roles
YUI.add('smst-config', function(Y) {
    "use strict";

    function Config(callback, skipUpdate) {
        if(!skipUpdate) {
            this.updateSessionInfo(callback);
        }
    }

    //fetches configuration info like token and user-roles from server
    Config.prototype.updateSessionInfo = function(callback) {
        var cfg = {
            on: {
             success: function(id, o) {
                 Y.mix(this, Y.JSON.parse(o.responseText));
                 callback();
             },
             failure: function() {
                Y.error("Could no fetch session configuration!",'ERROR','Config');
             }
            },
            context: this
        };

        Y.io("/api/config", cfg);
    };

    /*
     * Add the CSRF-token to uri.
     *
     */
    Config.prototype.secureURI = function(uri, params) {
        if(params) {
            if(!Y.Lang.isObject(params)) {
                Y.error('Die Parameter muessen als Objekt uebergeben werden!');
            }
        } else {
            params = {}
        }

        params[this.tName] = this.t;

          //FIXME: calling secureURI using an already secured link should work, too
        uri += (uri.indexOf('?') != -1 ? '&' : '?');
        uri += Y.QueryString.stringify(params);

        return uri;
    };

    Y.namespace("smst").Config = Config;

}, '1.0.0', {
    requires:[
        'io',
        'json-parse'
    ]
});

