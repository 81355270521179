// collection of useful helper methods used everywhere in the code.
YUI.add('smst-lang', function(Y) {
    "use strict";

    function Lang() {}


    Lang.notImplementedEvent = function(e) {
        Y.error('Handler for event ' + e.type + ' not implemented.');
    };

    Lang.notImplementedMethod = function(name) {
        Y.error('Method ' + name + ' is not implemented.');
    };


    Lang.detachEventListener = function(handlerList) {
        var handler;
        if( handlerList ) {
            if( handlerList.pop ) {
                while( 0 < handlerList.length ) {
                    handler = handlerList.pop();
                    if( handler ) {
                        handler.detach();
                        handler = null;
                    }
                }
            } else if( handlerList.detach ) {
                handlerList.detach();
            }
        }
    };


    // add it to the namespace
    Y.namespace("smst").Lang = Lang;

}, '1.0.0', {
    requires:[
    ]
});
