YUI.add('case-editor-app-summary', function (Y) {
    "use strict";

    var Slang = Y.smst.Lang;

    Y.CaseEditorSummaryView = Y.Base.create('CaseEditorSummaryView', Y.HandlebarsView, [], {
        template: 'case-editor-summary-container',
        partials: ['common-registerCTA', 'case-editor-issues-item'],
        events: {
            '.ui-toggle-smartcheck-help': {click: '_toggleHelp'},
            '.ui-case-editor-summary-disabled-next': {click: '_nextDisabled'},
            '.ui-item-delete': {click: '_itemDelete'},
            '.ui-hint-autofill': {click: '_autoFill'},
            '.ui-item-hide': {click: '_itemHide'},
            '.ui-item-unhide': {click: '_itemUnHide'}
        },
        initializer: function () {
            Y.onceAfter('smartsteuer:ready', function (e) {
                this.set('boUser', e.boUser);
            }.bind(this));
        },
        _toggleHelp: function (e) {
            var node = e.target.get('parentNode').get('parentNode').one(".case-editor-smartcheck-issue-list-help");
            if (node) {
                if (node.getStyle('display') === 'none') {
                    node.setStyle('display', 'block');
                } else {
                    node.setStyle('display', 'none');
                }
            }
        },
        _nextDisabled: function () {
            if (this.get('anonymous')) {
                this.fire('alert', {
                        msg: 'Bitte legen Sie ein Benutzerkonto an, um fortzufahren.',
                        callback: function () {
                            this.fire('openRegistration');
                        }.bind(this)
                    }
                );
            } else {
                this.fire('alert', {msg: 'Bitte bearbeiten Sie erst alle Probleme und möglichen Probleme.'});
            }
        },
        _itemDelete: function (e) {
            this.fire('confirmDelete', {
                msg: 'Diese EÜR löschen?',
                callback: function (ok) {
                    if (ok) {
                        Y.io("api/session/clear", {
                            method: 'POST',
                            data: {
                                field: e.currentTarget.getData('field'),
                                formId: e.currentTarget.getData('formId'),
                                formIndex: e.currentTarget.getData('formIndex'),
                                multiFieldIndex: e.currentTarget.getData('multiFieldIndex')
                            },
                            on: {
                                start: function () {
                                    this.fire('waiting', {msg: 'EÜR wird gelöscht …'});
                                },
                                complete: function () {
                                    this.fire('removeWaiting');
                                },
                                success: function () {
                                    this._loadAndRefresh(e, {
                                        smartCheck: true
                                    });
                                },
                                failure: function () {
                                    this.fire('alert', {msg: "Löschen der EÜR ist fehlgeschlagen."});
                                }
                            },
                            context: this
                        })
                    }
                }.bind(this)
            });
        },
        _autoFill: function (e) {
            this._loadAndRefresh(e, {
                commands: e.currentTarget.getData('commands'),
                smartCheck: true
            });
        },
        _itemHide: function (e) {
            this._loadAndRefresh(e, {
                item: e.currentTarget.getData('unique-id'),
                smartCheck: true,
                warnings: e.currentTarget.getData('warning')
            });
        },
        _itemUnHide: function (e) {
            this._loadAndRefresh(e, {
                unhide: true,
                item: e.currentTarget.getData('unique-id'),
                smartCheck: true,
                warnings: e.currentTarget.getData('warning')
            });
        },
        _loadAndRefresh: function (e, data) {
            e.halt();

            var cfg = {
                method: 'POST',
                data: data,
                on: {
                    start: function () {
                        this.fire('waiting', {msg: 'Bitte warten …'});
                    },
                    success: function (id, o) {
                        //render first request and do state update
                        var cfg2 = {
                            on: {
                                success: function (id2, res) {
                                    this._handleSuccess(id, o);

                                    this.fire('updateState', {raw: res.responseText, src: 'navigation'});
                                },
                                failure: function () {
                                    this.fire('removeWaiting');
                                    Y.log("Request failed or was aborted", 'warn', 'CaseEditorAppSummary');
                                }
                            },
                            context: this
                        };
                        Y.io("api/state?caze=true", cfg2);
                    },
                    failure: function () {
                        this.fire('removeWaiting');
                        this.fire('alert', {msg: "Fehler bei der Anfrage an den Server!"});
                    }
                },
                context: this
            };

            Y.io("api/hints", cfg);
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-summary-view" />');
                }
            },
            boUser: false
        }
    });

    Y.CaseEditorSummaryInitialView = Y.Base.create('CaseEditorSummaryInitialView', Y.HandlebarsView, [], {
        template: 'case-editor-summary-start',
        events: {
            '.ui-case-editor-summary-start': {click: '_startSmartCheck'}
        },
        _startSmartCheck: function () {
            Y.smartCheckMode = true;

            this.fire('open', {link: Y.smst.CaseEditor.SECTIONS.getByName('summary').get('link')});
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-summary-initial" />');
                }
            }
        }
    });

    Y.CaseEditorAppSummary = Y.Base.create('CaseEditorAppSummary', Y.smst.CaseEditorAppBase, [], {
        views: {
            initial: {type: 'CaseEditorSummaryInitialView'},
            smartCheck: {type: 'CaseEditorSummaryView'}
        },

        initializer: function () {
            Y.onceAfter('smartsteuer:ready', function (e) {
                this.set('anonymous', e.anonymous);
            }, this);

            this.publish('smartCheckActive', {
                emitFacade: true,
                preventable: false
            });
        },

        handleNavigation: function (navInfo) {
            if (!Y.smartCheckMode) {
                this.showView('initial');
                this.fire('removeWaiting');

                return;
            }

            var cfg = {
                method: 'POST',
                data: {
                    logErrors: true,
                    smartCheck: true
                },
                on: {
                    start: function () {
                        this.fire('waiting', {msg: 'Bitte warten …'});
                    },
                    success: function (id, o) {
                        this._handleSuccess(id, o);

                        if (Y.one("#case-editor-main-controls")) {
                            Y.one("#case-editor-main-controls").setStyle('display', 'none');
                        }
                    },
                    failure: function () {
                        this.fire('removeWaiting');
                        this.fire('alert', {msg: "Fehler bei der Anfrage an den Server!"});
                    }
                },
                context: this
            };

            Y.io("api/hints", cfg);
        },

        _createLinks: function (arr, lst) {
            Y.Array.each(lst, function (it) {
                it.section = 'interview';
                it.area = it.id;
                it.id = Y.stamp(it);
                it.multiFieldIndex = it.multiFieldIndex;
                it.hmfas = it.type != "multiFieldBase";
                it.hmfoas = it.type != "multiFormBase";
                it.plausi = true;

                it.areaName = this.redecode(it.areaName);

                it.link = this._createLink(it);

                arr.push(it);

            }, this);

            return arr;
        },

        _createLink: function (e, id, forceFormIndex1, forceMultiFieldIndex1) {
            if (e.command && e.command != '') {
                return e.formIndex + "/" + e.command;
            }

            var formIndex = (forceFormIndex1 ? 1 : e.formIndex);

            var mfaIdx = (e.multiFieldIndex != undefined ? e.multiFieldIndex : '-1');

            if (forceMultiFieldIndex1) {
                mfaIdx = 0;
            }

            return Y.smst.CaseEditor.SECTIONS.getByName('interview').get('link') +
                "/" + (id ? id : e.area) +
                "/" + e.formId +
                "/" + formIndex +
                "/" + mfaIdx +
                "/" + (forceMultiFieldIndex1 ? false : e.hmfas) +
                "/" + (forceFormIndex1 ? false : e.hmfoas);
        },

        redecode: function (str) {
            return Y.smst.Utils.textAreaEscape(str);
        },

        _parse: function (arr, lst, isWarning, suppressed) {
            Y.Array.each(lst, function (it) {
                // link, long text, short text, backofficeExtras
                it.section = 'interview';

                if (this.get('config').boUser && it.fieldName) {
                    it.message = it.message + " [" + it.fieldName + "]";
                }

                // some transcriptions to meet the requirements of "open" events.
                it.area = it.areaId;
                it.hmfoas = true;
                it.multiFieldIndex = it.index;
                it.multiFieldIndex = it.index;
                it.hmfas = true;

                it.id = Y.stamp(it);

                if (!it.forceFormIndex1) {
                    it.forceFormIndex1 = [false];
                }

                if (!it.forceMultiFieldIndex1) {
                    it.forceMultiFieldIndex1 = [false];
                }

                it.link = this._createLink(it, null, it.forceFormIndex1[0], it.forceMultiFieldIndex1[0]);

                it.error = it.type.indexOf("ERROR") >= 0;
                it.plausi = !it.error;
                it.warning = it.type.indexOf("WARNING") >= 0;

                it.areaName = this.redecode(it.areaName);
                it.multiEntryLabel = this.redecode(it.multiEntryLabel);
                it.message = this.redecode(it.message);

                if (it.type.indexOf("ELSTER_ERROR") >= 0) {
                    it.areaData = []

                    Y.Array.each(it.areaIds, function (it2, idx) {
                        it.areaData.push({
                            link: this._createLink(it, it2, it.forceFormIndex1[idx], it.forceMultiFieldIndex1[idx]),
                            name: it.areaNames[idx],
                            message: this.redecode(it.areaMessages[idx]),
                            visible: it.areaVisibilities[idx]
                        });

                        if (idx > 0) {
                            it.multipleAreas = true;
                        }
                    }, this);
                }

                if (isWarning) {
                    it.warning = true;
                }

                if (suppressed) {
                    it.suppressed = true;
                }

                arr.push(it);
            }, this);

            return arr;
        },

        _distinctMandatory: function (errors) {
            var ret = []
            var match = "Dies ist ein Pflichtfeld."; //TODO: put messages.properties in config object

            Y.Array.each(errors, function (err) {
                var hasMatch = false;

                Y.Array.each(ret, function (it2) {
                    if (it2.areaId == err.areaId && err.message == match && it2.message == match) {
                        hasMatch = true;
                        return;
                    }
                }, this);

                if (!hasMatch) ret.push(err);
            }, this);

            Y.Array.each(ret, function (err) {
                if (err.message == match) {
                    err.message = "In dem Bereich wurden noch nicht alle Pflichtfelder ausgefüllt.";
                    err.field = "";
                }
            }, this);

            return ret;
        },

        _handleSuccess: function (id, o) {
            var that = this,
                data = Y.JSON.parse(o.responseText),
                errors = Y.JSON.parse(data.errors),
                sessionLinksWithErrors = Y.JSON.parse(data.sessionLinksWithErrors),
                hints = Y.JSON.parse(data.hints),
                warnings = Y.JSON.parse(data.warnings),
                suppressedHints = data.suppressedHints ? Y.JSON.parse(data.suppressedHints) : [],
                suppressedWarnings = data.suppressedWarnings ? Y.JSON.parse(data.suppressedWarnings) : [],
                unsubmittedAreas = data.unsubmittedAreas ? data.unsubmittedAreas : [];

            errors = that._distinctMandatory(errors);

            errors = errors.concat(Y.JSON.parse(data.elsterErrors));
            Y.globalErrors = errors;

            Y.globalErrors = Y.globalErrors.concat(hints);
            Y.globalErrors = Y.globalErrors.concat(warnings);

            if (data.suppressedErrors) {
                errors = errors.concat(Y.JSON.parse(data.suppressedErrors));
            }

            if (!sessionLinksWithErrors) {
                sessionLinksWithErrors = [];
            }

            that.showView('smartCheck', {
                hints: that._parse([], hints),
                suppressedHints: that._parse([], suppressedHints, false, true),
                warnings: that._parse([], warnings, true),
                suppressedWarnings: that._parse([], suppressedWarnings, true, true),
                errors: that._parse([], errors),
                anonymous: this.get('anonymous'),
                sessionLinksWithErrors: sessionLinksWithErrors,
                hasErrors: errors.length > 0 || sessionLinksWithErrors.length > 0,
                isEUER: data.caseType === "EUER",
                hintsOrSuppressed: suppressedHints.length !== 0 || hints.length !== 0,
                warningsOrSuppressed: suppressedWarnings.length !== 0 || warnings.length !== 0,
                unsubmittedAreas: that._createLinks([], unsubmittedAreas)
            }, function (view) {
                view._handleSuccess = that._handleSuccess.bind(that)
            });

            if (errors.length === 0) {
                Y._errorMode = false;
                Y._savedNavState = null;
            }
            if (hints.length === 0) {
                Y._hintsMode = false;
                Y._savedNavState = null;
            }
            if (warnings.length === 0) {
                Y._warningsMode = false;
                Y._savedNavState = null;
            }

            if (errors.length === 0 && sessionLinksWithErrors.length === 0 && warnings.length === 0 && !this.get('anonymous')) {
                if (hints.length === 0) {
                    Y.one('#ui-case-editor-summary-ok').setStyle('display', 'block');
                }

                if (Y.one('#ui-case-editor-summary-details-report')) {
                    that.report = new Y.CaseEditorAppInterviewPageSummary();
                    Slang.detachEventListener(that.report.get('handlers'));

                    Y.once('reports-rendered', function () {
                        that._view.get('container').one('#ui-case-editor-summary-details-report').appendChild(that.report.get('container').getContent());
                        that._view.get('container').one('#ui-case-editor-summary-details').setStyle('display', 'block');
                    }, that);

                    var id = data.caseType === "EST" ? 170 : 268;

                    that.report._initPage({newVal: '<li class="case-editor-inline-report" data-id="' + id + '" id="case-editor-inline-report-170"></li>'});
                }

                Y.one('#case-editor-continue-container').one('.ui-case-editor-next').removeClass('ui-display-none');
                Y.all('.ui-issues-present').addClass('ui-display-none');
            } else {
                if (this.get('anonymous')) {
                    Y.one('.ui-case-editor-summary-disabled-next').set('title', 'Bitte legen Sie ein Benutzerkonto an, um fortzufahren.');
                }

                Y.one('#case-editor-continue-container').one('.ui-case-editor-summary-disabled-next').removeClass('ui-display-none');
            }

            that.fire('removeWaiting');
            that.fire('smartCheckActive');
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-summary" />');
                }
            },
            anonymous: false
        }
    });
}, '1.0.0', {
    requires: [
        'app',
        'base-build',
        'case-editor-app-base',
        'case-editor-app-interview-page-summary',
        'io-base',
        'node',
        'smst-lang',
        'smst-utils',
        'oop',
        'datatype-number'
    ]
});
