YUI.add('login', function(Y) {

	"use strict";

	// handy constants and shortcuts used in the module
	var proto, statics,
		NAME = 'Login';


	// Static members here, specially:
	statics = {
		NAME: NAME,
		ATTRS: {

		}
	};

	proto = {

		handler: [],

		initializer: function () {
			var instance = this;

			// global handler to open the login.
			instance.handler.push(Y.delegate('click', instance.fireOpenLogin, 'body', '.ui-open-login', instance));

		},

		destructor: function () {
			Y.Array.each(this.handler, function (h) {
				h.detach()
			});
		},

		/**
		 * This is the click handler for any click to elements with a class name of 'ui-open-login'. It will
		 * simply stop and translate the click event into the application event 'openLogin'.
		 *
		 * We need this to be able to implement special behaviour on some of the ui-open-login elements in
		 * modules further down the tree. There we can now define our own click handler and apply speacial treatment.
		 *
		 * @param e
		 * @private
		 */
		fireOpenLogin: function (e) {
			e.halt();
			this.fire('openLogin');
		}
	};

    Y.Login = Y.Base.create(NAME, Y.Base, [], proto, statics);


}, '1.0.0', {
		requires:[
            'base-build',
            'node'
		]
	}
);

