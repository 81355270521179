// YUI boilerplate
YUI.add('_advanced-help-repository', function(Y) {

	// creates our namespace
    Y.sst = Y.sst || {};

	// The app will glue YUI to our code
	Y.sst.AdvancedHelpRepository = AdvancedHelpRepository(Y.io);

}, '1.0.0', {requires:['io']} );

var AdvancedHelpRepository = function (Yio) {

	var AdvancedHelpRepository = {};
	var io = Yio;

	function needsToRequestAdvancedHelp(fieldDefinition) {
		return  fieldDefinition &&
				true === fieldDefinition.moreHelp &&
				undefined === fieldDefinition.lexiconLinks &&
				undefined === fieldDefinition.examples;
	}

	function addAdvancedHelpAttributesToFieldDefinition(fieldDefinition, res) {
		var text = res.responseText;
		var help = JSON.parse(text);

		for( var entry in help ) {
			fieldDefinition[entry] = help[entry];
		}

		return fieldDefinition;
	}

	function buildRequestUri(fieldDefinition, formIndex, multiFieldIndex) {
		var uri = 'api/help/advanced?id=' + fieldDefinition.id + '&multiFieldIndex=' +
			multiFieldIndex + '&formIndex=' + formIndex;
		return uri;
	}

	function buildRequestConfiguration(successCallback, failureCallback) {
		var cfg = {
			headers: {"Accept": "application/json"},
			on: {
				success: successCallback,
				failure: failureCallback
			}
		};
		return cfg;
	}

	AdvancedHelpRepository.attachAdvancedHelpToField = function (fieldDefinition, formIndex, multiFieldIndex, callback) {

		if( needsToRequestAdvancedHelp(fieldDefinition) ) {
			var successCallback = function(id, res) {
				fieldDefinition = addAdvancedHelpAttributesToFieldDefinition(fieldDefinition, res);
				callback(fieldDefinition);
			};
			var failureCallback = function() {
				callback(fieldDefinition);
			};
			var cfg = buildRequestConfiguration(successCallback, failureCallback);
			var uri = buildRequestUri(fieldDefinition, formIndex, multiFieldIndex);
			io(uri, cfg);
		} else {
			// nothing to do!
			callback(fieldDefinition);
		}

	};

	return AdvancedHelpRepository;
};
