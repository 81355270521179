YUI.add('interview-field-factory', function(Y) {

	"use strict";

	// Chain the constructors
	var proto, statics, clazz,
		NAME   = "FieldFactory";

	statics = {
		NAME: NAME,

		newField: function(domField, formIdx, form) {
			var type = domField.get('tagName').toLowerCase(),
				f = null,
				config = {},
				toolConfigs, overwrite;

			if( 'input' == type ) {
				type = domField.getAttribute("type") || "text";
			}

//			Y.log("Creating field of Type: " + type);
//			Y.log("Source element: " + domField.get("id"));

			try {
				config = Y.JSON.parse( domField.getAttribute("data-json") );

				//ONSE-10177 value has to be transient as it's only used for import / export
				delete config.value;

			} catch (e) {
				Y.error("Invalid field data ("+domField.get("id")+")", e);
			}

			config.node = domField;
			config.form = form;
			config.mfIndex = formIdx;
			config.overrideFormat = domField.getAttribute("data-hint");

			switch (type) {
				case 'select':
					f = new Y.interview.SelectField(config);
					break;
				case 'checkbox':
					f = new Y.interview.CheckboxField(config);
					break;
				case 'hidden':
					f = new Y.interview.RangeField(config);
					if(config.fullYear===true) {
						f.plug(Y.interview.FullYearPlugin);
					}
					break;
				default:
					if( config.list ) {
						f = new Y.interview.PickListField(config);
					} else if (config.spreadsheetId) {
						f = new Y.interview.SheetTextField(config);
					} else if (config.launcher) {
                        f = new Y.interview.LauncherField(config);
                    } else {
						f = new Y.interview.TextField(config);
						// check for configuration of the overwrite plugin. If found plug it in.
						overwrite = config.overwrite;
						if(!Y.Lang.isUndefined(overwrite)) {
							f.plug( Y.interview.AutosuggestOverwritePlugin, {toggledOn:overwrite});
						}
					}
			}

			// check for configured tools here and plug the tools-plugin in.
			toolConfigs = f.get('tools');
			if( toolConfigs ) {
				Y.log("Tools plugin setup: "+f);
				f.plug(new Y.interview.ToolsPlugin({configs:toolConfigs, host: f}));
			}

			return f;
		}
	};

	proto = {};


	/**
	 * This class is used for creating instances of our form fields.
	 *
	 * @class FieldFactory
	 * @extends OnseBase
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	Y.namespace("interview").FieldFactory = Y.Base.create(NAME, Y.Base, [], proto, statics );

}, '1.0.0', {requires:[
	'base-build',
	'interview-fields',
	'interview-range-field',
	'interview-tools-plugin',
	'interview-full-year-plugin',
	'interview-autosuggest-overwrite-plugin',
	'json-parse'
]} );
