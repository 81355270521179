// collection of useful helper methods used everywhere in the code.
YUI.add('smst-utils', function(Y) {
    "use strict";

    function Utils() { }

	Utils.textAreaEscape = function(rawInput) {
		var textArea, escapedInput;
		if( null == rawInput ) {
			return null;
		}
	    textArea = document.createElement("textarea");
	    textArea.innerHTML = rawInput.replace(/</g,"&lt;").replace(/>/g,"&gt;");
		escapedInput = textArea.value;
		textArea = null;
	    return escapedInput;
    };

	//-------------------SHARED_JS-----------------------------
    //TODO: move this to a plain js file
	//-------------------VISIBILITIES--------------------------
	Utils.DEBUG = true;

	Utils.debug = function (msg) {
		try {
			if (!Utils.DEBUG) return;

			if(Y && Y.log) {
				Y.log(msg);
			}
			else if(console && console.log) {
				console.log(msg);
			}
		}
		catch (ex) {
			//nothing to do here
		}
	};

    //inital inline area setup after the client has received the base model with all inline areas invisible
    //
	//areaRule has propeties id and visibility
	//callback._toggleInlineArea() receives parameters id of areaRule and active (true|false). however, only active=true is actually called, since invisible is the default state (see interview-page-form)
	//usercase has to implement getValueFromCase, getPotentialMultiFieldValueFromField, realValue and isInvalidNumber like Y.UserCase
    //
	Utils.evaluateInitialInlineAreaVisibility = function (areaRule, areaManager, usercase, defaultValue, formIndex, multiFieldIndex) {

		// todo: add 'parsedRule'
		// that way we can check for that (in the second part of the else block) and don't need to set caseField
		// to null manually
		var rule = areaRule.visibility;

		Utils.debug("EVAL_STATIC: " + rule);

		if (rule == '') {
			areaManager._toggleInlineArea(areaRule.id, true);
		}
		else {
			var form = rule.substring(3);
			form = form.substring(form.indexOf('/') + 1, form.indexOf('**'));
			var field = rule.substring(3).substring(0, rule.substring(3).indexOf('@'));
			var caseField = usercase.getValueFromCase(field, form, formIndex);

			Utils.debug('EVAL_STATIC: ' + field + " -> " + form + "@" + formIndex + " is: " + caseField);

			//value is present in case
			if (caseField != null) {

				var rawValue = usercase.getPotentialMultiFieldValueFromField(caseField, multiFieldIndex);
				var value = usercase.realValue(rawValue, caseField.format);

				Utils.debug('EVAL_STATIC: ' + caseField.value + " -> " + rawValue + " -> " + value);

				if (usercase.isInvalidNumber(value, caseField.format)) {
					Utils.debug("EVAL_STATIC: NaN: " + value + ":" + caseField.format);

					caseField = null;
				}
				else {
					//fixme: not safe without spaces - von der rule nur die rechte seite nehmen und links den formatabhängigen wert setzen
					rule = value + " " + rule.substring(rule.indexOf(" ") + 1);

					Utils.debug("EVAL_STATIC: RESULT: " + rule + " >>> " + eval(rule) + ", idx: " + formIndex);

					if (eval(rule)) {
						areaManager._toggleInlineArea(areaRule.id, true);
					}
				}
			}

			//fallback: defaultvalue
			if (caseField == null) {
				var format = Utils.extractFormatFromRule(rule);

				if (defaultValue) {
					var value = usercase.realValue(defaultValue, format);

					Utils.debug("EVAL_STATIC: fallback to default value " + defaultValue + " with format " + format);

					rule = value + rule.substring(rule.indexOf(' '));
				}
				else {
					rule = usercase.realValue("", format) + rule.substring(rule.indexOf(' '));

					Utils.debug('EVAL_STATIC: fallback to "' + rule + '", no field present.');
				}

				// TODO: this can be its own function
				var ok = eval(rule);

				Utils.debug("EVAL " + ok + ": " + rule);

				if (ok) areaManager._toggleInlineArea(areaRule.id, true);

			}
		}
	};
	
	Utils.extractFormatFromRule = function (rule) {
		var format = rule.substring(rule.indexOf('@') + 1);
		format = format.substring(0, format.indexOf('/'));
		return format;
	};

	Utils.asyncIo = function (url) {
		return new Promise((resolve, reject) => {
			Y.io(url, {
				headers: {
					'Accept': 'application/json',
				},
				on: {
					success: function (id, res) {
						resolve(res)
					},
					failure: function (id, res) {
						reject(res)
					},
				},
				context: this,
			})
		})
	}

	//---------------------------------------------------------
	//-------------------/SHARED_JS----------------------------

    // add it to the namespace
    Y.namespace("smst").Utils = Utils;
	

}, '1.0.0', {
    requires:[
		'io-base'
    ]
});


