YUI.add('interview-full-year-plugin', function(Y) {

	"use strict";

	var proto, statics, clazz,
		NAME = "FullYearPlugin",
		BUTTON_TEMPLATE =
			'<div class="case-interview-field-input-tool-container">' +
				'<div class="case-interview-field-input-tool-btns case-interview-field-input-tool-btns-pointing">' +
					'<span class="ui-case-interview-field-input-tool-btns-label">Eintragen:</span> ' +
					'<button type="button" class="btn btn-s ui-full-year-btn">Ganzes Jahr</button>' +
				'</div>' +
			'</div>',
		VALUE_TEMPLATE = {
			Z:'01.01.{year}-31.12.{year}',
		    B:'01.01.-31.12.'
		};

	statics = {
		NAME: NAME,
		NS: 'fullyear',
		ATTRS: {
			configs: {
				value: []
			}
		}
	};

	proto = {

        _vz : null,

		_handler : null,

		_button : null,

		_fillUpField: function(e) {
			e.preventDefault(); // focus should be handled anyway.
			var host = this.get('host'),
				format = host.get('format'),
				template;

			template = VALUE_TEMPLATE[format];

            host._forcedRemoveShallow();

			host.set('value', Y.Lang.sub(template, {year:this._vz}), {src:'tool'} );
		},

		initializer : function() {
            Y.onceAfter('smartsteuer:ready', function(e){this._vz = e.vz}, this);
			this._handler = [];
			this._handler.push( this.afterHostMethod('render', function(e) {
				var host = this.get('host'),
					target = host.node.get('parentNode');
				this._button = Y.Node.create(BUTTON_TEMPLATE);

				this._handler.push( this._button.one('.ui-full-year-btn').on('click', this._fillUpField, this ));
				target.insertBefore(this._button, host.node);
			}));

		},

		destructor : function() {
			Y.Array.each( this._handler, function(it){
				it.detach();
			});

			this._button.remove(true);
		}
	};

	/**
	 * Adds a button to fill a range field with a full year range. (1/1-12/31).
	 *
	 * @class FullYearPlugin
	 * @extends Y.Plugin.Base
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	Y.namespace("interview").FullYearPlugin = Y.Base.create(NAME, Y.Plugin.Base, [], proto, statics );

}, '1.0.0', {
    requires:[
        'base-build',
        'plugin',
        'node'
    ]
} );