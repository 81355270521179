YUI.add('app-overlays', function (Y) {
	"use strict";

	var SLang = Y.smst.Lang;

	function AppOverlays() {
	}

	/**
	 * Tells the app not to use 'overlays' as a adhoc attribute.
	 * @type {Array}
	 * @private
	 */
	AppOverlays._NON_ATTRS_CFG = ['overlays'];

	AppOverlays.prototype = {

		_registeredEventHandler: {},

		/**
		 * This will show the named overlay and register all event listeners.
		 * @param name The name of the overlay.
		 * @param payload @optional An Object which gets
		 * @param params @optional render context for the handlebars template
		 * @param classes @optional a string containing classnames separated with spaces - they are added to the
		 *        boundingBox
		 * @return {*}
		 */
		showOverlay: function (name, payload, params, classes) {
			var template, overlay, meta;

			meta = this.overlays[name];

			if (!meta) {
				Y.error('Overlay ' + name + ' was not configured.');
			}

			//WARNING: caching does not work here: ONSE-6865 and ONSE-6887
			/*
			 // if there is an overlay yet, we are done.
			 if( meta.instance) {
			 // but we have to double check that the boundingBox of this instance still exists as it can vanish when
			 // the creating view was unloaded.
			 if( null !== meta.instance.get('boundingBox')._node) {
			 return this._doShow(meta, name, payload)
			 } else {
			 // the DOM node is gone - we have to clean up...
			 meta.instance.destroy();
			 delete meta.instance;
			 }
			 }
			 */

			if (meta.instance) {
				// the DOM node is gone - we have to clean up...
				meta.instance.destroy();
				delete meta.instance;
			}

			// if there is not - we have to create and save it.
			template = Y.HandlebarsTemplates.lookup(meta.template);
			if (!template) {
				Y.error('No template with the given name found! (' + meta.template + ')');
			}

			overlay = this.newDefault(
					meta.header,
					meta.footer,
					params ? template(params) : template(),
					meta.notEscapable,
					meta);
			meta.instance = overlay;

			// check for additional css classes to apply
			classes && overlay.get('boundingBox').addClass(classes);

			this.overlays[name] = meta;

			// returning the overview for custom handling.
			return this._doShow(meta, name, payload);
		},

		_doShow: function (meta, name, payload) {
			var overlay = meta.instance;
			// remove old errors
			this.clearOverlayError(overlay);

			// initialize event listener
			if (meta.events) {
				this._attachEvents(name, meta, payload);
			}

			Y.later(300, this, function () {
				overlay.show();
				// remove the listeners when ever this overlay is hidden.
				overlay.onceAfter('visibleChange', Y.bind(this._disableOverlay, this, name));

				Y.log("overlay position is x/y: "+overlay.get("x")+"/"+overlay.get("y"));

				//console.log(payload);

				var move = false;
				var x = overlay.get("x")
				var y = overlay.get("y")

				//we have some weird positionings in which mess with the calculation, 70 is the absolute minimum!
				if (y < 70) {
					y = (payload && payload.yOffset) ? payload.yOffset : 70;
					if(y < 70) {
						y = 70;
					}
					move = true;
				}

				if(x < 0) {
					x = 0;
					move = true;
				}

				if (move) {
					overlay.move(x, y);
					Y.log("overlay moved, position is x/y: "+overlay.get("x")+"/"+overlay.get("y"));
				}

				if (payload && payload.focusNode) {
					var node = overlay.get('boundingBox').one(payload.focusNode);
					if (node) {
						node.focus();
					}
				}
			});

			return overlay;
		},

		// taken and modified from Y.View.
		_attachEvents: function (overlayName, meta, payload) {
			var container = meta.instance.get('boundingBox'),
					owns = Y.Object.owns,
					events = meta.events || (meta.events = {}), // sanity checking.
					handler, handlers, name, selector;


			this._detachEvents(overlayName);

			// if there is a payload we want to include it in every event listener if there is non we create one
			// to add the overlay instance.
			payload || (payload = {});
			payload.overlay = meta.instance;


			for (selector in events) {
				if (!owns(events, selector)) {
					continue;
				}

				handlers = events[selector];

				for (name in handlers) {
					if (!owns(handlers, name)) {
						continue;
					}

					handler = handlers[name];
					if (typeof handler === 'string') {
						handler = this[handler];
					}
					this._registeredEventHandler[overlayName] || (this._registeredEventHandler[overlayName] = []);
					this._registeredEventHandler[overlayName].push(
							container.delegate(name, handler, selector, this, payload));
				}
			}

			return this;
		},

		_detachEvents: function (name) {
			// detach all event listener
			if (this._registeredEventHandler[name]) {
				SLang.detachEventListener(this._registeredEventHandler[name]);
				delete this._registeredEventHandler[name];
			}
		},

		_disableOverlay: function (name) {
			this._detachEvents(name);
			var ov = this.overlays[name];
			if (ov && ov.instance) {
				ov.instance.set('xy', [-10000, -10000]);
			}
		},

		destructor: function () {
			// destroy all overlays.
			Y.Object.each(this.overlays, function (it) {
				if (it.instance) {
					it.instance.destroy && it.instance.destroy();
				}
			});
		}

	};

	// We have to keep the old "Y.smst.Overlays" in place for backward compatibility
	// (at least as long the old client has to work) so we mix its functionality into this new class.
	// This will bring the following methods:
	// newDefault
	// showError
	// clearError
	Y.mix(AppOverlays.prototype, Y.smst.Overlays);


	// Add it to the namespace
	Y.AppOverlays = AppOverlays;

}, '1.0.0', {
	requires: [
		'event-custom',
		'smst-lang',
		'smst-overlays'
	]
});