YUI.add('print-preview', function(Y) {

	"use strict";

	var protos, statics,
		NAME = 'PrintPreview',
        APPENDIX_PDF_DOWNLOAD_CLASS = '.appendix-pdf-download',
        REPORT_PDF_DOWNLOAD_CLASS = '.report-pdf-download',
		ELSTER_PDF_DOWNLOAD_CLASS = '.ui-elster-pdf-download',
		MAIL_DOCUMENTS_PDF_DOWNLOAD_CLASS = '.ui-mail-documents-pdf-download';

	statics = {
		NAME: NAME,
		ATTRS: {}
	};

	protos = {
		_popup : null,
        config: null,

		initializer : function() {
            Y.once('smartsteuer:ready', function(config){this.config = config}, this);

            Y.delegate('click', this.pdfDownloadReport, '#app-body', REPORT_PDF_DOWNLOAD_CLASS, this);
            Y.delegate('click', this.pdfDownloadAppendix, '#app-body', APPENDIX_PDF_DOWNLOAD_CLASS, this);
			Y.delegate('click', this.elsterPdfDownload, '#app-body', ELSTER_PDF_DOWNLOAD_CLASS, this);
			Y.delegate('click', this.mailDocumentsDownload, '#app-body', MAIL_DOCUMENTS_PDF_DOWNLOAD_CLASS, this);
		},

        pdfDownloadReport : function (e) {
            e.halt();
            var id,assmt,
                uri='reports/render.html',
                assmtParam,
                sessionId = e.currentTarget.getData('sessionId');

            id = e.currentTarget.getData('id');

            var params = {
                id:id,
                sessionId:sessionId,
                deliverPdf:'true',
                useSessionCase: true,
                nocalc: true,
                fullScreen: true
            };

            assmt = e.currentTarget.getData('assessment-type');
            if (assmt != null) {
                assmtParam = 'use' + assmt.toUpperCase();
                params[assmtParam] = true;
            }

            location.href = this.config.secureURI(uri, params);
        },

        pdfDownloadAppendix: function(e) {
            e.halt();
            var id,
                uri='appendixes/renderAppendix',
                params,
                url;
            id = e.currentTarget.getData('id');
            //var sheetDefinitionId = id.substring(0, id.indexOf('-'));
            params = {
                id:id
            };
            url = this.config.secureURI(uri,params);
            location.href = url;
        },

		mailDocumentsDownload: function (e) {
            this.pdfDownload(e, 'mailDocumentsPdf');
		},

		elsterPdfDownload: function (e) {
			this.pdfDownload(e, 'elsterPdf');
		},

        pdfDownload: function (e, endpoint) {
            e.halt();
            var downloadToken = new Date().getTime().toString();
            var securl = this.config.secureURI("printouts/" + endpoint);
            var securlWithToken = securl + "&downloadToken="+downloadToken;
            this.triggerDownload(securlWithToken, downloadToken);

            // some users on safari are having trouble downloading the pdf. (ONSE-9744)
            // We are unable to reproduce the bug so we will create a fallback.
            // Providing a Link as an alternative should do the trick.
            this._showAlternativeDownloadLink(securl);
        },

        // Taken from:  http://stackoverflow.com/questions/1106377/detect-when-browser-receives-file-download?lq=1
        triggerDownload: function(url,downloadToken) {

            if(Y.UA.touchEnabled) {
                window.location.href = url;
                return;
            }

            this._disableDownloadButtons();

            var iframe = Y.Node.create('<iframe>');
            iframe.set('src',url);
            iframe.setStyle('display','none');
            Y.one(document.body).append(iframe);

            var attempts = 60;
            var downloadTimer = window.setInterval(function () {
                var token = Y.Cookie.get("downloadToken");
                // Re-enable the download button when the cookie has been set
                // (this happens server-side on the download response) or 60 seconds have passed
                if ((token === downloadToken) || (attempts === 0)) {
                    this._enableDownloadButtons();

                    // and clean up
                    window.clearInterval(downloadTimer);
                    Y.Cookie.remove('downloadToken');
                    iframe.remove();
                }
                attempts--;
            }.bind(this), 1000);
        },

        _disableDownloadButtons: function () {
            Y.all('.case-editor-filing-module-waiting').removeClass('ui-display-none');
            Y.all(ELSTER_PDF_DOWNLOAD_CLASS).addClass('btn-disabled');
            Y.all(MAIL_DOCUMENTS_PDF_DOWNLOAD_CLASS).addClass('btn-disabled');
            Y.all(ELSTER_PDF_DOWNLOAD_CLASS).setAttribute('disabled', 'disabled');
            Y.all(MAIL_DOCUMENTS_PDF_DOWNLOAD_CLASS).setAttribute('disabled', 'disabled');
        },

        _enableDownloadButtons: function() {
            Y.all('.case-editor-filing-module-waiting').addClass('ui-display-none');
            Y.all(ELSTER_PDF_DOWNLOAD_CLASS).removeClass('btn-disabled');
            Y.all(MAIL_DOCUMENTS_PDF_DOWNLOAD_CLASS).removeClass('btn-disabled');
            Y.all(ELSTER_PDF_DOWNLOAD_CLASS).removeAttribute('disabled');
            Y.all(MAIL_DOCUMENTS_PDF_DOWNLOAD_CLASS).removeAttribute('disabled');
        },

        _showAlternativeDownloadLink: function (securl) {
	        var link = Y.one('#ui-case-editor-filing-alt-download');
	        if( link ) {
		        link.set('href', securl);
		        link.removeClass('ui-invisible');
	        }
        },
	};

	Y.PrintPreview = Y.Base.create(NAME, Y.Base, [], protos, statics);

}, '1.0.0' ,{
	requires: [
		'base-build',
		'event-base',
		'spreadsheets',
		'user-case',
        'cookie'
	]
});
