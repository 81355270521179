YUI.add('case-editor-app-interview-page-multioverview', function(Y) {

    "use strict";

	/**
	 * Component for rendering and managing multiform and multifield overview areas.
	 *
	 * There are two distinct views, an initial one with a select box widget and a "real" one where crud operations are done.
	 * Both types are rendered using the same handlebars template, but please note that different code paths are necessary in various places.
	 *
	 * WARNING: Especially the index handling is very different, since multiforms starts at 1 and multifields start at 0 - so don't copy/paste but
	 * implement and test everything twice!
	 *
	 * @type {CaseEditorAppInterviewPageMultioverview}
     */
    Y.CaseEditorAppInterviewPageMultioverview =
		    Y.Base.create('CaseEditorAppInterviewPageMultioverview', Y.CaseEditorAppInterviewPageView, [Y.AppOverlays], {

		events : {
			'.ui-multif-add-initial-label' : {click: '_incrementMultiSelect'},
			'.ui-case-editor-overview-next' : {click: '_saveAndNext'},
			'.ui-case-editor-interview-multi-item-add'    : {click: '_add'},
			'.ui-case-editor-interview-multi-item-rename' : {click: '_rename'},
			'.ui-case-editor-interview-multi-item-remove' : {click: function(e) {this.showOverlay('removeMulti', {'idx': e.currentTarget.get('value')});}},
		},

		overlays : {
			renameMulti : {
				header:"Bitte geben Sie eine neue Bezeichnung an",
				footer:'<button type="button" class="btn btn-s btn-hilited" id="ui-mfx-ok">OK</button>',
				template: 'case-editor-interview-overlay-rename',
				events : {
					'#ui-mfx-ok' : { click : '_doRename'},
					'.yui3-widget' : { 'keypress' : '_doRename' }
				},
				notEscapable : true,
				plugins : [
					Y.Plugin.OverlayModal,
					Y.gorilla.overlay.AnimPlugin,
					Y.gorilla.widget.EscapeHidePlugin,
					Y.gorilla.widget.XButtonPlugin
				],
				mobileNode: '#case-editor-main'
			},
			removeMulti : {
				header:"Löschen bestätigen",
				footer:'<button type="button" class="btn btn-destructive ui-delete-mfx"><i class="fa fa-trash-o"></i> Eintrag löschen</button><button type="button" class="btn btn-hilited ui-delete-abort">Abbrechen</button>',
				template: 'case-editor-interview-overlay-delete',
				events : {
					'.ui-delete-mfx'  : { click : '_handleDelete' },
					'.ui-delete-abort' : { click : '_abortDelete' },
					'.yui3-widget'     : { keypress : '_abortDelete' }
				},
				notEscapable : true,
				plugins : [
					Y.Plugin.OverlayModal,
					Y.gorilla.overlay.AnimPlugin,
					Y.gorilla.widget.EscapeHidePlugin,
					Y.gorilla.widget.XButtonPlugin
				],
				mobileNode: '#case-editor-main'
			}
		},

		//-----------------------------MAIN RENDER FUNCTION----------------------------------------------

        _initPage: function(e) {
            var that = this,
                content = Y.one(Y.config.doc.createDocumentFragment());

			if(!Y._currentState.areaIsProcessed) {
				//this renders the select box mf/mfa overview
				var multiOverviewSelectLabel = Y._currentState.multiAreaMeta.multiOverviewSelectLabel;
				if(!multiOverviewSelectLabel) {
					multiOverviewSelectLabel = "Einträge anlegen";
				}

				var longLabel = false;
				if(multiOverviewSelectLabel.replace(/^\s+|\s+$/g, '').length > 50) {
					longLabel = true;
				}

				var indices = [];
				for(var i=0; i<=Y._currentState.multiAreaMeta.multiBaseFieldMaxIndex; i++) {
					indices.push({'name':i,'selected':(i == Y._currentState.multiAreaMeta.selectCount)});
				}

				Y.HandlebarsTemplates.append('case-editor-interview-multioverview-preselect', content,
					{
						'multiOverviewSelectLabel': multiOverviewSelectLabel,
						'indices' : indices,
						'longLabel' : longLabel
					}
				);
			}
			else if(Y._currentState.isMultiFieldBase) {
				//tile based rendering for MFA area
				var indices = that._buildIndices(Y._currentState.multiAreaMeta.maxMultiFieldAreaIndex+1);

				Y.HandlebarsTemplates.append('case-editor-interview-multioverview-mf', content,
					{
						'indices' : indices,
						'multiDefaultLabel': Y._currentState.multiAreaMeta.multiFieldAreaDefaultLabel
					}
				);

			}
			else if(Y._currentState.isMultiFormBase) {
				//tile based rendering for MF area
				var indices = that._buildIndices(Y._currentState.multiAreaMeta.maxFormIndex);

				Y.HandlebarsTemplates.append('case-editor-interview-multioverview-mf', content,
					{
						'indices' : indices,
						'multiDefaultLabel': Y._currentState.multiAreaMeta.multiFormDefaultLabel
					}
				);
			}

			Y.HandlebarsTemplates.append('case-editor-interview-multioverview-navbuttons', content);

		    that.get('container').setHTML(content);

			that.fire('removeWaiting');
		    that.fire('interview:multiOverviewRendered');
	    },

		//-----------------------------PLUS WIDGET ON SELECT VIEW----------------------------------------------

		_incrementMultiSelect : function(e) {
			var select = Y.one('#ui-case-editor-interview-multif-add-multiple-count');

			if(select) {
				var cnt = select.get('selectedIndex');
				if((cnt + 1) >  Y._currentState.multiAreaMeta.multiBaseFieldMaxIndex) {

					this.fire('alert', {'msg':'Sie können keine weiteren Einträge erzeugen. Die maximale Anzahl wurde erreicht.'});
				}
				else {
					select.set('selectedIndex', cnt + 1);
				}
			}
		},

		//-----------------------------SAVE AND ADD FUNCTIONS----------------------------------------------

		_add : function(e) {
			if(Y._currentState.isMultiFieldBase) {
				this._saveMFA(false, (Y._currentState.multiAreaMeta.maxMultiFieldAreaIndex+1), '');
			} else {
				this._saveMF((Y._currentState.multiAreaMeta.maxFormIndex+1), (Y._currentState.multiAreaMeta.maxFormIndex+1), '');
			}
		},

		_saveMFA : function(requestIndex, toIndex, label, changeToIndex, useShortCut) {
			var that = this,
				cfg;

			if(toIndex && toIndex > Y._currentState.multiAreaMeta.multiBaseFieldMaxIndex) {
				that.fire('alert', {'msg':'Sie können keine weiteren Einträge erzeugen. Die maximale Anzahl wurde erreicht.'});
				return;
			}

			var payload = {
				'toIndex' : '',
				'requestFormIndex' : '',
				'justCalculate': true,
				'processedInlineAreas' : '',
				'formIndex' : 1,
				'formId' : Y._currentState.formId,
				'multiFieldAreaIndex' : -1,
				'maxMultiFieldAreaIndex' : -1,
				'multiFieldAreaShortCut' : false
			}

			var maxIndex = Y._currentState.multiAreaMeta.maxMultiFieldAreaIndex;

			if((toIndex || toIndex == 0) && toIndex > maxIndex) {
				maxIndex = toIndex;
			}

			//console.log("toIndex: "+toIndex+", maxIndex:"+maxIndex+", label: " + label);

			var indices = that._buildIndices(maxIndex+1);

			for(var i=0; i<=maxIndex; i++) {
				var id = ('field_' + Y._currentState.multiAreaMeta.multiBaseFieldName) + '['+i+']';

				if(indices.length > i) {
					payload[id] = indices[i].itemLabel;
				}

				//override the current item label if specified for exactly this index
				if(changeToIndex && i == toIndex) {
					payload[id] = label;
				}

				//console.log("changeToIndex: "+changeToIndex+", i: "+i+", toIndex: "+toIndex +", label: "+payload[id] + ", id: "+id);
			}

			payload['maxMultiFieldArea'] = indices.length-1;

			//skips the crud management view and goes directly to the entry
			if(useShortCut) {
				payload['multiFieldAreaShortCut'] = "true";
			}

			cfg = {
				on: {
					start: function () {
						that.fire('waiting', {msg: 'Bitte warten …'});
					},
					success: function(id, res) {
						that.fire('updateState', {raw:res.responseText, src:'save'});
					},
					failure: function(id, o) {
						that.fire('removeWaiting');

						that.fire('alert', {msg:"Es gab einen Fehler bei der Kommunikation mit dem Server. " +
							"Bitte prüfen Sie Ihre Internetverbindung und aktualisieren Sie anschließend die Seite " +
							"und versuchen es erneut."});
					}
				},
				context: that,
				method: 'POST',
				data: payload
			};

			Y.io("api/state", cfg);
		},

		_saveMF : function(requestIndex, toIndex, label) {
			var that = this,
				cfg;

			if(toIndex && toIndex >  Y._currentState.multiAreaMeta.multiBaseFieldMaxIndex ||
			   requestIndex > Y._currentState.multiAreaMeta.multiBaseFieldMaxIndex) {
				that.fire('alert', {'msg':'Sie können keine weiteren Einträge erzeugen. Die maximale Anzahl wurde erreicht.'});
				return;
			}


			var payload = {
				'toIndex' : toIndex,
				'justCalculate': true,
				'processedInlineAreas' : '',
				'formIndex' : 1,
				'formId' : Y._currentState.formId,
				'multiFieldAreaIndex' : 0,
				'maxMultiFieldArea' : 0,
				'maxMultiFieldAreaIndex' : 0,
				'multiFieldAreaShortCut' : false
			}

			payload[('field_'+Y._currentState.multiAreaMeta.multiBaseFieldName)] = label;

			if(requestIndex) {
				payload['requestFormIndex'] = requestIndex;
			}

			cfg = {
				on: {
					start: function () {
						that.fire('waiting', {msg: 'Bitte warten …'});
					},
					success: function(id, res) {
						that.fire('updateState', {raw:res.responseText, src:'save'});
					},
					failure: function(id, o) {
						that.fire('removeWaiting');

						that.fire('alert', {msg:"Es gab einen Fehler bei der Kommunikation mit dem Server. " +
						"Bitte prüfen Sie Ihre Internetverbindung und aktualisieren Sie anschließend die Seite " +
						"und versuchen es erneut."});
					}
				},
				context: that,
				method: 'POST',
				data: payload
			};

			Y.io("api/state", cfg);
		},

		_saveAndNext : function(e) {
			var select = Y.one('#ui-case-editor-interview-multif-add-multiple-count');

			if(select && select.get('selectedIndex') != 0) {
				var cnt = Y.one('#ui-case-editor-interview-multif-add-multiple-count').get('selectedIndex');

				if(Y._currentState.isMultiFormBase) {
					this._saveMF('', cnt, '');
				}
				else {
					var useShortCut = cnt == 1;
					this._saveMFA('', cnt-1, '', false, useShortCut);
				}
			}
			else {
				this.fire('nextPage');
			}
		},

		//-----------------------------DELETE FUNCTIONS----------------------------------------------

		_abortDelete : function(e, payload) {
			if( e.keyCode && 13 !== e.keyCode ) {
				return;
			}
			payload.overlay.hide();
		},

		_handleDelete : function(e, payload) {
			var that = this;

			if(Y._currentState.isMultiFieldBase) {
				payload.url = "api/state/clearMultiFieldIndex?clearMultiFieldAreaIndex="+(payload.idx-1);
				payload.src = 'mfaOverview'
			}
			else {
				payload.url = "api/state/clearMultiFormIndex?clearMultiForm="+payload.idx;
				payload.src = 'mfOverview'
			}

			e.halt();

			var cfg = {
				on: {
					start: function () {
						that.fire('waiting', {msg: 'Bitte warten …'});
					},
					failure: function() {
						that.fire('alert', {msg:"Löschen fehlgeschlagen!"});
					},
					success : function(id, res) {
						that.fire('updateState', {raw:res.responseText,src:payload.src});
					}
				},
				context: that,
				method : 'DELETE'
			};

			Y.io(payload.url, cfg);

			payload.overlay.hide();
		},

		//-----------------------------RENAME FUNCTIONS----------------------------------------------

		_rename : function(e) {
			var that = this,
				usedIndex =  e.currentTarget.get('value'),
				nameNode, name;

			nameNode = Y.one("#ui-case-editor-interview-multi-item-label-"+ usedIndex);

			if( null == nameNode ) {
				name = ""
			} else {
				// safe escape the name
				name = Y.smst.Utils.textAreaEscape(nameNode.getHTML());
			}

			that.showOverlay('renameMulti', {idx:usedIndex}, {name:name});
		},

		_doRename : function(e, payload) {
			if( e.keyCode && 13 !== e.keyCode ) {
				return;
			}
			e.halt();
			// hide the overlay and remove it from the payload as we are going to pass this along.
			payload.overlay.hide();
			delete payload.overlay;

			payload.value = Y.Escape.html(Y.one('#ui-rename-name-input').get('value'));

			if(Y._currentState.isMultiFieldBase) {
				this._saveMFA('', payload.idx-1, payload.value, true);
			}
			else {
				this._saveMF(payload.idx, '', payload.value);
			}
		},

		//-----------------------------GENERAL HELPER FUNCTIONS----------------------------------------------

		_buildIndices : function(maxIndex) {
			var that = this, indices = [];

			for(var i=1; i<=maxIndex; i++) {
				indices.push({
					'itemLabel' : that._getFieldLabel(i),
					'i' : i,
					formIndex : Y._currentState.isMultiFormBase ? i : Y._currentState.formIndex,
                    multiFieldIndex : Y._currentState.isMultiFormBase ? -1 : (i-1),
					caseId: Y._currentState.caseId,
					formId: Y._currentState.formId,
					areaId: Y._currentState.area,
					hideMFASummary : Y._currentState.isMultiFieldBase ? 'true' : 'false',
					hideMFSummary : Y._currentState.isMultiFormBase ? 'true' : 'false'
				})
			}

			return indices;
		},

		//-----------------------------FUNCTIONS FOR LABEL ENHANCEMENT----------------------------------------------

		_getFieldLabel : function(index) {
			var that = this;

			if(Y._currentState.isMultiFieldBase)
			{
				var value = Y.UserCase.searchRealValueFromCase(Y._currentState.multiAreaMeta.multiBaseFieldName, Y._currentState.formIndex, index-1, true);

				if(value != undefined && value != null && value != '') {

					return that.fixEncoding(""+value);
				}
				else {
					return Y._currentState.multiAreaMeta.multiFieldAreaDefaultLabel + " " + index;
				}
			}
			else if(Y._currentState.isMultiFormBase) {
				var value = Y.UserCase.searchRealValueFromCase(Y._currentState.multiAreaMeta.multiBaseFieldName, index, -1, true);

				if(value != undefined && value != null && value != '') {

					return that.fixEncoding(""+value);
				}
				else {
					return Y._currentState.multiAreaMeta.multiFormDefaultLabel + " " + index;
				}
			}
		},

		//-----------------------------HELPER FUNCTIONS FOR LABEL ENHANCEMENT----------------------------------------------

		fixEncoding : function(str) {
			if(str == null) {
				return str;
			}
			var ret = Y.smst.Utils.textAreaEscape(str);

			return Y.Escape.html(ret);
		},

    }, {
        ATTRS: {

        }
    });



}, '1.0.0', {
    requires:[
		'app-overlays',
        'base-build',
		'case-editor-app-interview-page-view',
		'escape',
		'event',
	    'smart-handlebars-templates',
	    'smst-lang',
		'user-case',
	    'view'
    ]
});