YUI.add('app-header', function(Y) {
    "use strict";

    /**
     * Extension for the app framework. This will add "setHeader" to an app.
     *
     * @constructor
     */
    function AppHeader() {}


    /**
     * Definition of extra attributes this extension needs to do its job.
     *
     * @type {Object}
     */
    AppHeader.ATTRS = {

        activeHeader: {
            readOnly: true
        }

    };

    /**
     *
     * @type {Array}
     * @private
     */
    AppHeader._NON_ATTRS_CFG = ['headers'];


    AppHeader.prototype = {


        initializer: function(){
            // every time the active header changes this has to be carried through to the dom.
            this.after('activeHeaderChange', Y.bind('_afterActiveHeaderChange', this));

            // we have some rendering to do.
            Y.Do.after(this._renderActiveHeader, this, 'render');

        },

	    destructor : function() {
		    var activeHeader = this.get('activeHeader');

		    if( activeHeader && activeHeader.view ) {
			    activeHeader.view.destroy({remove: true});
		    }
	    },

        _renderActiveHeader : function() {
            var container             = this.get('container'),
                activeHeader          = this.get('activeHeader'),
                activeHeaderContainer = activeHeader && activeHeader.view.get('container');

            if (activeHeader && !container.contains(activeHeaderContainer)) {
                container.prepend(activeHeaderContainer);
                this._afterActiveHeaderChange({newVal:activeHeader});
            }
        },

        _afterActiveHeaderChange : function( e ) {
            // headers are always rendered into the apps container.
            // They get prepended to always stay on top.
            var container = this.get('container'),
                oldHeader = e.prevVal,
                newHeader = e.newVal;

            // sanity check... don't switch if there is noting to switch.
            if( oldHeader === newHeader ) {
                return;
            }

            // bring in the new Header
            if( newHeader ) {
                // we are now a bubble target of this header.
                newHeader.view.addTarget(this);
                container.prepend( newHeader.view.get('container'));
            }

            // if there is an header this has to be removed after the new one was added.
            if( oldHeader ) {
                oldHeader.view.destroy({remove: true});
                oldHeader.view.removeTarget(this);
            }
        },

        showHeader : function( name, attrs, options ) {
            var active = this.get('activeHeader'),
                newHeaderView;

            options || (options = {});

            // check if this header is active already.
            if( active && active.name === name ) {
                if(options.update) {
                    Y.log('Refreshing header ('+name+')', 'DEBUG', 'AppHeader');
	                active.view.setAttrs(attrs);
                }
                if(options.render) {
                    Y.log('Rerender header ('+name+')', 'DEBUG', 'AppHeader');
	                active.view.render();
                }
                // nothing more to do here.
                return;
            }

            if( this.headers[name] ) {
                Y.log('Showing header ('+name+')', 'INFO', 'AppHeader');
                newHeaderView = new this.headers[name](attrs);
                this._set('activeHeader', {
                    name: name,
                    view: newHeaderView.render()
                } );
            }
        }
    };

    // Add it to the namespace
    Y.AppHeader = AppHeader;

}, '1.0.0', { requires:[
    'event-custom'
]});