YUI.add('tool-routes-panel', function(Y) {

	"use strict";

	(function(){
		var proto, statics, clazz,
			NAME = "RoutesPanel",
			ORIGIN = "origin",
            ORIGIN_ADDRESS = "originAddress",
            OPTIONAL_ADDRESS = "optionalAddress",
			DESTINATION = 'destination',
			STREET = 'Street',
			NO = STREET+'No',
			EXT = NO+'Ext',
			ZIP_CODE = 'ZipCode',
			TOWN = 'Town';

		statics = {
			NAME: NAME,
			ATTRS: {
				originStreet : {
					value : ""
				},
				originStreetNo : {
					value : ""
				},
				originStreetNoExt : {
					value : ""
				},
				originZipCode : {
					value : ""
				},
				originTown: {
					value : ""
				},
                originAddress: {
                    value : ""
                },
                optionalAddress: {
                    value : ""
                },

				/**
				 * The destination as a string or as a functions returning a string.
				 */
				destination: { },

				result:{
					setter: function(val){
						if(Y.Lang.isNumber(val)) {
							// ONSE-9429
							// kms have to be rounded down.
							return Math.floor(val);
						}
						return val;
					}
				}

			}
		};

		proto = {

			_getAddress : function(prefix) {
				var that = this,
					address = that.get(prefix+STREET) + ' ' + that.get(prefix+NO),
					ext = that.get(prefix+EXT),
					zip = that.get(prefix+ZIP_CODE),
					town = that.get(prefix+TOWN);

                if(that.get(OPTIONAL_ADDRESS)) {
                    return that.get(ORIGIN_ADDRESS) ? that.get(ORIGIN_ADDRESS) : "";
                }

				if( ext ) {
					address += ext;
				}
				if( address && (zip || town ) ) {
					address += ', ';
				}
				if( zip ) {
					address += zip;
					if( town ) {
						address += ' ';
					}
				}
				if( town ) {
					address += town;
				}
				return  address;
			},

			initializer : function() {
				var destination;
				this.setAttrs({
					width: 960,
					height: 650,
					modal : true,
					buttons : []
				});

				destination = this.get(DESTINATION);

                if(destination && destination.value === "") {
                    destination = null;
                }

				this.add( new Y.RoutesWidget({
					origin:this._getAddress(ORIGIN),
                    destination: Y.Lang.isFunction( destination ) ? destination.apply() : (destination ? destination : '')

				}));

				this.plug(Y.gorilla.widget.XButtonPlugin);
				this.plug(Y.Plugin.OverlayModal);

				this._handlerList = [];
			},

			bindUI: function() {
				var that = this;
				
				that._handlerList.push( that.on('smart-routes:distanceChange', function(e) {
					that.hide();
					that.set('result', e.newVal );
				}, that));

				that._handlerList.push( that.on('visibleChange', function(e){
					that.centered();
				}), that);

				that._handlerList.push( that.after('visibleChange', function(e){
					var destination,
						origin;


					if( e.newVal ) {
						// get Origin
						origin = that._getAddress(ORIGIN);
						// set destination:
						destination = that.get(DESTINATION);

                        if(destination && destination.value === "") {
                            destination = null;
                        }

						that.item(0).setAttrs( {
							origin: origin,
							destination: Y.Lang.isFunction( destination ) ? destination.apply() : destination
						});

						that.item(0).start();
					}
				}, that));

			},

			destructor : function() {
				Y.Array.each( this._handlerList, function(it) {
					it.detach();
				});
			}
		};

	/**
	 * This will open a panel to embed the routes widget.
	 *
	 * @class Routes
	 * @extends Panel
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	clazz = Y.Base.create(NAME, Y.Panel, [Y.WidgetParent], proto, statics );

	Y.namespace("tool").RoutesPanel = clazz;

})();

}, '1.0.0', {
	requires:[
		'gallery-overlay-extras',
		'panel',
		'smart-routes',
		'widget-parent'
	]
});