// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-filing2', function (Y) {
    "use strict";

    Y.CaseEditorAppFiling2IntroductionView = Y.Base.create('CaseEditorAppFiling2IntroductionView', Y.HandlebarsView, [], {
        template: 'case-editor-filing2-introduction',

        render: function () {
            var that = this;
            that._render({
                filingState: that.get('filingState'),
                hasEuer: Y._currentState && Y._currentState.hasEuer
            });
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-preview" />');
                }
            }
        }
    });

    Y.CaseEditorAppFiling2PreviewView = Y.Base.create('CaseEditorAppFiling2PreviewView', Y.HandlebarsView, [], {
        template: 'case-editor-filing2-preview',

        render: function () {
            var that = this;
            that._render({
                filingState: that.get('filingState'),
                assessmentInfo: that.get('assessmentInfo'),
                csrfT: that.get('csrfT'),
                hasEuer: Y._currentState && Y._currentState.hasEuer,
                hasBusinessCase: Y._currentState && Y._currentState.hasBusinessCase
            });
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-preview" />');
                }
            },
            assessmentInfo: {},
            csrfT: {}
        }
    });

    Y.CaseEditorAppFiling2FilingView = Y.Base.create('CaseEditorAppFiling2FilingView', Y.HandlebarsView, [], {
        template: 'case-editor-filing2-filing',

        render: function () {
            var that = this;

            that._render({
                boUser: that.get('boUser'),
                filingState: that.get('filingState'),
                stateInfo: that.get('stateInfo'),
                product: that.get('productInfo'),
                hasEuer: Y._currentState && Y._currentState.hasEuer,
                hasBusinessCase: Y._currentState && Y._currentState.hasBusinessCase
            });
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-filing" />');
                }
            },
            stateInfo: {},
            productInfo: {}
        }
    });

    Y.CaseEditorAppFiling2FinalView = Y.Base.create('CaseEditorAppFiling2FinalView', Y.HandlebarsView, [], {
        template: 'case-editor-filing2-final',
        render: function () {
            var that = this;

            that._render({
                stateInfo: that.get('stateInfo'),
                filingState: that.get('filingState'),
                hasTaxArrears: that.get('hasTaxArrears'),
                hasEuer: Y._currentState && Y._currentState.hasEuer,
                transferDecreeData: that.get('transferDecreeData'),
                filingLink: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link'),
                sendLink: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/send').get('link')
            });

            Y.one('#case-editor-main-header').setStyle('display', 'none');
            Y.one('#case-editor-main-body').setStyle('marginTop', '10px');

            Y.onceAfter('CaseEditor:activeSectionChange', function () {
                if (Y.one('#case-editor-main-header')) {
                    Y.one('#case-editor-main-header').setStyle('display', '');
                    Y.one('#case-editor-main-body').setStyle('marginTop', '0px');
                }
            });
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-final" />');
                }
            },
            stateInfo: {},
            hasEuer: {
                getter: function () {
                    return Y._currentState && Y._currentState.hasEuer;
                }
            },
            hasTaxArrears: {
                getter: function () {
                    return Y._currentState.hasTaxArrears;
                }
            },
            isShortInterview: {
                getter: function () {
                    return Y._currentState.isShortInterview;
                }
            },
            isShortInterviewUsingLongOption: {
                getter: function () {
                    return Y._currentState.isShortInterviewUsingLongOption;
                }
            },
            transferDecreeData: {
                getter: function () {
                    return Y._currentState.transferDecreeData || Y._currentState.transferDecreeData2;
                }
            }
        }
    });

    Y.CaseEditorAppFiling2 = Y.Base.create('CaseEditorAppFiling2', Y.smst.CaseEditorAppBase, [Y.AppOverlays], {
        vueApp: null,

        _runningRequests: [],

        views: {
            introduction: {type: 'CaseEditorAppFiling2IntroductionView'},
            preview: {type: 'CaseEditorAppFiling2PreviewView'},
            filing: {type: 'CaseEditorAppFiling2FilingView'},
            documents: {
                type: Y.CaseEditorAppFiling2Documents
            },
            'final': {type: 'CaseEditorAppFiling2FinalView'}
        },
        overlays: {
            'what-next': {
                header: "Wie geht es weiter?",
                width: 700,
                footer: '<button type="button" class="btn btn-hilited" id="ui-what-next-ok">OK</button>',
                template: 'case-editor-filing2-whatNextOverlay',
                events: {
                    '#ui-what-next-ok': {click: '_closeOverlay'},
                    '.yui3-widget': {keypress: '_closeOverlay'}
                },
                plugins: [
                    {
                        fn: Y.gorilla.widget.CancelButtonPlugin,
                        cfg: {
                            template: '<button type="button" class="btn ui-cancel-btn ui-display-none">Ok</button>',
                            section: Y.WidgetStdMod.FOOTER
                        }
                    }
                ],
                notEscapable: false
            }
        },
        events: {
            '#ui-go-to-preview': {
                click: function (e) {
                    e.halt();
                    this.fire('open', {link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-preview').get('link')});
                }
            },
            '#ui-go-to-filing': {
                click: function (e) {
                    e.halt();
                    this.fire('open', { link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link') })
                }
            },
            '#ui-filing2-show-what-next': {
                click: function (e) {
                    e.halt();
                    this.showOverlay('what-next', null, {
                        hasEuer: Y._currentState && Y._currentState.hasEuer,
                        hasTaxArrears: Y._currentState && Y._currentState.hasTaxArrears
                    });
                }
            },
        },

        _cancelRunningRequests: function () {
            var that = this,
                requests = that._runningRequests,
                request;

            // reset the global field to not interfere with any ongoing async action.
            that._runningRequests = [];

            // now clean and cancel any running requests.
            if (requests) {
                while (0 < requests.length) {

                    request = requests.pop();
                    // make sure that this is cancelable.
                    request.abort && request.isInProgress() && request.abort();
                }
            }
        },

        /**
         * This will publish any events thrown by this class and register listeners on application events
         * (read: not DOM-events).
         */
        initializer: function () {
            var that = this;

            that.publish('newSubsection', {
                context: that,
                emitFacade: true
            });

            // we are going to cancel any running requests when ever a new section is activated.
            that.on('newSubsection', that._cancelRunningRequests, that);
        },

        destructor: function () {
            Y.all('.ui-case-editor-next').removeClass('btn-disabled').removeAttribute('disabled');
        },

        _closeOverlay: function (e, payload) {
            // only close on enter
            if (e.keyCode && 13 !== e.keyCode) {
                return;
            }
            e.halt();
            payload.overlay.hide();
        },

        render: function () {
            this.constructor.superclass.render.apply(this);

            Y.all('.ui-case-editor-next').addClass('btn-disabled').setAttribute('disabled', 'disabled');

            return this;
        },

        /**
         * This will publish new active sections to the case-editor. The information is needed to sync the navigation
         * accordingly. Also this app and any sub-apps (at this time only case-editor-app-filing2-documents) will cancel
         * any running requests to speed things up a little and to only show the most recent data.
         *
         * @param navInfo
         * @private
         */
        _activateSubSection: function (navInfo) {
            var area = navInfo.area,
                subArea = navInfo.subArea;

            // It is easier to handle all sub section activations here in one place. For the time being it is only
            // possible to go two levels deep. This should be enough for all of our use cases.
            if (area && !subArea) {
                this.fire('newSubSection', {section: 'filing2-process-' + area});
            } else if (subArea) {
                this.fire('newSubSection', {section: 'filing2-process-' + area + '/' + subArea});
            }
        },

        _updateFilingState: function (response) {
            var fs = Y.JSON.parse(response.responseText);
            this.set('filingState', fs);
            Y._currentFilingState = fs;
            return fs;
        },

        showView: function(view, config, options, callback) {
            this.constructor.superclass.showView.call(this, view, config, options, callback)

            switch (view) {
                case 'introduction':
                    Y.one('#case-editor-main').removeClass('case-editor-main-body')
                    this._loadIntroductionApp()
                    break
                case 'preview':
                    Y.one('#case-editor-main').removeClass('case-editor-main-body')
                    this._loadPreviewApp()
                    break
                case 'filing':
                    Y.one('#case-editor-main').removeClass('case-editor-main-body')
                    this._loadCheckoutApp()
                    break
                case 'final':
                    Y.one('#case-editor-main').removeClass('case-editor-main-body')
                    this._loadFinalizedFilingApp()
                    break
                case 'documents':
                    break
                default:
                    Y.one('#case-editor-main').addClass('case-editor-main-body')
                    Y.one('#case-editor-main-header').setStyle('display', '')
                    if (this.vueApp && Y.one('#app')) {
                        Y.one('#app').remove()
                    }
                    break
            }
        },

        // this is our new 'openArea'
        handleNavigation: function (navInfo) {
            Y.log('handleNavigation', 'DEBUG', 'CaseEditorAppFiling2');
            var that = this,
                cfg_assessment, cfg_filingState,
                area = navInfo.area;

            // remove default "Bitte warten..."
            that.fire('removeWaiting');
            // we will do an ajax request in a second, so we should indicate that we are waiting for its outcome.
            that.fire('waiting', {msg: 'Bitte warten …'});
            // as all these views rely on the filing state we will get it first with an ajax request
            // doing this here means we do this each time when moving

            // The navigation needs a little hint about the current section.
            // (case-editor only knows about top level sections)
            that._activateSubSection(navInfo);

            cfg_filingState = {
                method: 'GET',
                on: {
                    success: function (id, o) {
                        var fs = that._updateFilingState(o, that);
                        var data = {
                            filingState: fs,
                            filingLink: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link'),
                            sendLink: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/send').get('link')
                        };
                        var blockWaiting = false;

                        if (!area) {
                            // The user clicked the parent node - we will show a short introduction to this process.
                            that.showView('introduction');
                        } else {
                            switch (area) {
                                case 'preview':
                                    that.showView('preview', {csrfT: that.get('config').tName + '=' + that.get('config').t},
                                        function () {
                                            Y.io('elster/saveInterviewSessionParam', {
                                                method: 'POST',
                                                data: {
                                                    viewed: true
                                                }
                                            })
                                        }
                                    );
                                    break
                                case 'filing':
                                    blockWaiting = true
                                    that.updateAndLoadFilingView()
                                    break
                                case 'documents': // The documents area is handled by an extra app.
                                    var cb = function cb() {
                                        that.get('activeView').handleNavigation(navInfo)
                                    }
                                    that.showView('documents', data, {callback: cb})
                                    break
                                case 'final':
                                    that.showView('final', data)
                                    break
                                default:
                                    Y.error('unknown filing area!')
                                    break
                            }
                        }

                        if (Y.one('#case-editor-main-controls')) {
                            Y.one('#case-editor-main-controls').setStyle('display', 'none')
                        }

                        if (!blockWaiting) {
                            that.fire('removeWaiting');
                        }
                    }
                },
                context: that
            };

            that._runningRequests.push(Y.io('elster/getFilingState', cfg_filingState))
        },

        updateAndLoadFilingView: function () {
            var that = this,
                filingState = that.get('filingState'),
                viewConfig = {
                    boUser: that.get('config').boUser,
                    stateInfo: filingState,
                    filingState: filingState,
                    productInfo: null
                };
            // fixme: use an extra view per state.

            that.fire('removeWaiting')
            that.showView('filing', viewConfig)
        },

        _loadPreviewApp: function () {
            var that = this;
            var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('analysis').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-preview').get('link')
            let currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process').get('link')

            const yuiSections = {
                previous: previousSectionLink,
                current: currentYuiSectionUrl,
                next: nextSectionLink
            }
            let csrfToken = this.get('config').t
            let tokenName = this.get('config').tName

            that.vueApp = onse.default.makePreviewApp({
                hasBusinessCase: Y._currentState.hasBusinessCase,
                yuiSections: yuiSections,
                csrfToken: csrfToken,
                tokenName: tokenName
            })

            if (Y.one('#case-editor-main-header')) {
                Y.one('#case-editor-main-header').setStyle('display', 'none')
            }

            that.vueApp.app.$mount('#vue-preview-app')

            return false
        },

        _loadIntroductionApp: function () {
            var that = this;
            var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('analysis').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-preview').get('link')
            let currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process').get('link')

            const yuiSections = {
                previous: previousSectionLink,
                current: currentYuiSectionUrl,
                next: nextSectionLink
            }

            that.vueApp = onse.default.makeFilingIntroductionApp({
                yuiSections: yuiSections
            })

            //Remove case editor's main header before mounting the Vue Checkout App

            if (Y.one('#case-editor-main-header')) {
                Y.one('#case-editor-main-header').setStyle('display', 'none')
            }

            that.vueApp.app.$mount('#vue-introduction-app')

            return false
        },

        _loadCheckoutApp: function () {
            var that = this;
            var checkoutSteps = that.buildCheckoutSteps();
            let isAuthenticated = !this.get('filingState').needsWebIdAuthentication;

            let webidServiceUrl = this.get('filingState').webidServiceURL;

            var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-preview').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/print').get('link')
            let currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link')

            const yuiSections = {
                previous: previousSectionLink,
                current: currentYuiSectionUrl,
                next: nextSectionLink
            }

            that.vueApp = onse.default.makeCheckOutApp({
                components: checkoutSteps,
                authenticated: isAuthenticated,
                finApiEnabled: this.get('filingState').finApiEnabled,
                webidServiceUrl: webidServiceUrl,
                vzYear: this.get('config').vz,
                elsterAvailableFrom: this.get('filingState').elsterAvailableFrom,
                checkoutServiceBaseUri: this.get('config').checkoutServiceBaseUri,
                yuiSections: yuiSections,
                boUser: this.get('config').boUser,
            });

            //Remove case editor's main header before mounting the Vue Checkout App

            if (Y.one('#case-editor-main-header')) {
                Y.one('#case-editor-main-header').setStyle('display', 'none')
            }

            that.vueApp.app.$mount('#vue-case-filing')

            return false;
        },

        buildCheckoutSteps() {
            var checkoutSteps = [];
            if (this.get('filingState').needsPaymentForFiling) {
                checkoutSteps.push('payment-overview')
                checkoutSteps.push('checkout-web-component')
            }
            let isAuthenticated = !this.get('filingState').needsWebIdAuthentication;
            if (!isAuthenticated) {
                checkoutSteps.push('verification-overview');
            }
            checkoutSteps.push('checkout-filing');
            return checkoutSteps;
        },

        _loadFinalizedFilingApp: function () {
            var that = this

            var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/send').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('decree').get('link')

            let currentSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-final').get('link')

            const yuiSections = {
                previous: previousSectionLink,
                current: currentSectionUrl,
                next: nextSectionLink
            }

            that.vueApp = onse.default.makeFinalizedFilingApp({
                notYetSentViaElster: that.get('filingState').notYetSentViaElster,
                transferDecreeData: Y._currentState.transferDecreeData,
                yuiSections: yuiSections
            })
            that.vueApp.app.$mount('#vue-filing-final')
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-filing"/>');
                }
            },
            caseId: {readOnly: true}, // ToDo: do we need this??? check this
            filingState: {readOnly: false}
        }
    });
}, '1.0.0', {
    requires: [
        'app',
        'base-build',
        'smart-handlebars-templates',
        'case-editor-app-base',
        'case-editor-app-filing2-documents',
        'io-base',
        'json-parse',
        'node',
        'smst-lang'
    ]
});
