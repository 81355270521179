// YUI boilerplate
YUI.add('_interview-field-repository', function(Y) {

	// creates our namespace
    Y.sst = Y.sst || {};

	// The app will glue YUI to our code
	Y.sst.InterviewFieldRepository = InterviewFieldRepository();

}, '1.0.0', {requires:[]} );

var InterviewFieldRepository = function () {

	var InterviewFieldRepository = {};

	function parseFieldNode(fieldNode) {
		var jsonAttribute = fieldNode.getAttribute('data-json');
		var help = fieldNode.getAttribute('data-help');
		var moreHelp = fieldNode.getAttribute('data-more-help');
		var json = JSON.parse(jsonAttribute);
		json.help = help;
		json.moreHelp = 'true' == moreHelp;
		return json;
	}

	InterviewFieldRepository.getFieldDefinition = function(id) {
		// The ids can have two different forms:
		// 1st one is: 'field_<the-id-we-need>'
		// 2nd one is: 'field_<the-id-we-need>_<multi-field-index>'
		var fieldNode = document.querySelector("[id^=field_" + id + "]");
		if( fieldNode ) {
			return parseFieldNode(fieldNode);
		} else {
			return null;
		}
	};


	return InterviewFieldRepository;
};