YUI.add('dev-reports', function(Y) {

	"use strict";

	// handy constants and shortcuts used in the module 
	var clazz, proto, statics,
		NAME = 'DevReports',
		Bind = Y.bind;


	// Static members here, specially:
	statics = {
		NAME: NAME,
		ATTRS: {

		}
	};

	proto = {
		
		handler: [],
        windowHandler: [],
        config : null,
        reportWindow : null,

		initializer: function(conf) {
            var instance = this;

            Y.once('smartsteuer:ready', function(config){this.config = config}, this);

            instance.handler.push(Y.delegate('click', instance.toggleReports, '#body', '#ui-app-toggle-reports', instance));

            Y.on('interview:rendered', function() {
                if(this.reportWindow) {
                    Y.one(this.reportWindow.document.getElementById('app-backoffice-report-container')).setStyle('opacity', '1.0');
                    this.reloadReport();
                }
            }, this);

            Y.on('*:calculate', function() {
                if(this.reportWindow) {
                    Y.one(this.reportWindow.document.getElementById('app-backoffice-report-container')).setStyle('opacity', '0.2');
                }
            }, this);
		},

		destructor: function() {
			Y.Array.each(this.handler, function(h){h.detach()});
            Y.Array.each(this.windowHandler, function(h){h.detach()});
		},

       toggleReports : function() {
           if(this.reportWindow) {
               this.reportWindow.close();
               this.initialized = false;
           }

           var caseName = Y.one('#case-title-name').get('innerHTML');

           this.reportWindow = window.open('','Berichte für '+caseName,'location=1,status=0,toolbar=1,menubar=0,scrollbars=yes,width=1580,height=1050');

           this.reportWindow.document.writeln(
            '<html>'+
                '<head>'+
                    '<title>Berichte für '+caseName+'</title>'+
                '</head>'+
                '<body style="background-color:#c0c0c0;" id="body">'+
                    '<div id="app-backoffice-controls-reports"><span style="font-size: 14pt; font-weight: bold; color: #0080CC;">'+caseName+'</span>: '+
                        '<input type="radio" name="type" value="sc" checked="checked">Zusammenveranlagung <input type="radio" name="type" value="mc">EVE: Mann <input type="radio" name="type" value="fc">EVE: Frau <input type="radio" name="type" value="mc50">EVE50: Mann <input type="radio" name="type" value="fc50">EVE50: Frau <input type="radio" name="type" value="cc">Merged'+
                        ' &nbsp; KAP: <select id="kap-settings"><option value="0">Normal</option><option value="1">tariflich</option><option value="2">nicht tariflich</option><option value="3">tariflich, keine GP</option><option value="4">nicht tariflich, keine GP</option></select>'+
                        ' &nbsp; <button id="dev-reload" style="color:#0080ff;">Berechnen</button>'+
                    '</div>'+
                    '<br>'+
                    '<div id="report-nav-tree" style="float:left; width:500px; height:950px; overflow: auto; background-color:#f0f0f0; padding-top:5px;"></div>'+
                    '<iframe id="app-backoffice-report-container" width="990" height=950></iframe>'+
                '</body>'+
            '</html>'
           )
           this.reportWindow.document.close();

           Y.Array.each(this.windowHandler, function(h){h.detach()});

           this.windowHandler.push(Y.delegate('click', this.loadReport, this.reportWindow.document.getElementById('body'), '.report-link', this));
           this.windowHandler.push(Y.delegate('click', this.initTree, this.reportWindow.document.getElementById('body'), 'input', this));
           this.windowHandler.push(Y.delegate('click', this.reloadReport, this.reportWindow.document.getElementById('body'), '#dev-reload', this));
           this.windowHandler.push(Y.delegate('change', this.switchKAP, this.reportWindow.document.getElementById('body'), '#kap-settings', this));

           this.initTree();
           this.switchKAP();
       },

       switchKAP : function(e) {
           var that = this;

           var ioConfig = {
               on: {
                   success: function(id, o, args) {
                       that.reportWindow.document.getElementById('kap-settings').selectedIndex = o.responseText.replace(/"/g, '');
                       if(e) {
                           that.reloadReport();
                       }
                   },
                   context: that
               },
               data : {
                   kap: e ? e.target.get('value') : -1
               }
           };

           Y.io('/api/config/kap', ioConfig);
       },

       getCaseType : function() {
           var radios = this.reportWindow.document.getElementsByName('type');

           for (var i = 0, length = radios.length; i < length; i++) {
               if (radios[i].checked) {
                   return radios[i].value;
               }
           }
       },

       reloadReport : function() {
           var frame = Y.one(this.reportWindow.document.getElementById('app-backoffice-report-container'));
           frame.setStyle('opacity', '0.2');

           var old = frame.get('src');
           frame.set('src','');

           window.setTimeout(function() {frame.set('src',old)}, 300);

           frame.setStyle('opacity', '1.0');
       },

       loadReport : function(e) {
           if(!this.reportWindow) {
               return;
           }

           e.halt();

           Y.lastDevReportId = e.target.getAttribute('data-id');

           var uri = this.config.secureURI('reports/render.html', { useSessionCase:true, id: e.target.getAttribute('data-id'), dev:true});

            switch(e.target.getAttribute('data-type')) {
                case 'mc':
                    uri += '&useMC=true';
                    break;
                case 'fc':
                    uri += '&useFC=true';
                    break;
                case 'mc50':
                    uri += '&useMC50=true';
                    break;
                case 'fc50':
                    uri += '&useFC50=true';
                    break;
                case 'cc':
                    uri += '&useCC=true';
                    break;
            }

           Y.one(this.reportWindow.document.getElementById('app-backoffice-report-container')).set('src',uri);

       },


       initTree : function() {
           var that = this;


           var ioConfig = {
               on: {
                   success: function(id, o, args) {
                       var json = Y.JSON.parse(o.responseText);
                       var type = that.getCaseType();
                       var root = Y.Node(that.reportWindow.document.getElementById('report-nav-tree'));

                       if(root) {
                           root.get('childNodes').remove();
                       }

                       Y.Array.each(json.categories, function(it) {
                           var node = "<ul>"

                               node += "<li style=\"margin-top: 8px; margin-bottom:4px; font-size: 11pt;\">"+it.label+"</li>"

                               Y.Array.each(it.reports, function(report) {
                                   if(report.type == type) {
                                        node += "<li style='padding-left:10px; list-style: none; font-size:10pt;'><a style=\"color:#0080ff;\" class=\"report-link\" href=\"#\" data-type=\""+report.type+"\" data-id=\""+report.id+"\">"+report.label+"</a></li>";
                                   }
                               }, that);

                           node += "</ul>";

                           root.appendChild(node);
                       }, that);

                       //open first or last opened report
                       var node = Y.lastDevReportId ? root.one('a[data-id='+Y.lastDevReportId+']') : Y.one(that.reportWindow.document.getElementById('report-nav-tree')).one('a');

                       if(node) node.simulate('click');
                   },
                   context: that
               }
           };
           Y.io('reports/reports', ioConfig);

       }

	};

	clazz = Y.Base.create(NAME, Y.Base, [], proto, statics );

	Y[NAME] = clazz;


}, '1.0.0', {
		requires:[
            'base-build',
            'node',
            'json',
            'node-event-simulate'
		]
	}
);

