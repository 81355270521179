YUI.add('interview-focus', function(Y) {

	"use strict";

	// handy constants and shortcuts used in the module 
	var proto, statics;


	// Static members here, specially:
	statics = {
	};

	proto = {
		
		handler: [],

		initializer: function() {
			var instance = this,
				node = Y.one('#app-body');

            instance.publish('field-focus-disabled', {});

			instance.handler.push(
					node.delegate('click', instance._click, '.field', instance) );
            instance.handler.push(Y.after('*:field-focus', instance._markField, this));

            instance.handler.push(Y.after('interview:rendered', function(e) {this.interviewFields = e.fields;}, this));
		},

		destructor: function() {
			Y.Array.each(this.handler, function(h){h.detach()});
		},

		_triggerFocus : function(fields) {
			fields.some(function(it){
				if('hidden' != it.get('type')) {

					if(it.getStyle('visibility') == 'hidden') {
						//toggle widgets use an own view and hides the input, so we just trigger our internal event
						//the hiding was done by css tricks, so it doesn't have type hidden
						this.interviewFields[it.get('id')]._fireFocus();
					}
					else {
                        it.focus();
					}

                    if(it.get('disabled')) {
                        this.fire('field-focus-disabled', {fieldNode: it});
                    }
					return true;
				}
			}, this);
		},

        /**
         * This does a roundtrip from interview-fields.fireFocus so we can catch both clicks and tabs.
         *
         * @param e
         * @private
         */
        _markField : function(e) {
            if(!e || !e.fieldNode) return;

            var tmp = e.fieldNode;

            //search for top li
            if(!tmp.hasClass('case-interview-field')) {
                tmp = tmp.ancestor('.case-interview-field');
            }

            if(tmp.hasClass('case-interview-field-render-in-tiles')) {
            	//checkboxareas use focus state for selections instead
            	return;
			}

            //unfocus first other focused element
            Y.one('#case-editor-main').all('li').some(function(it){
                if(it.hasClass('case-interview-field-focused')) {
                    it.removeClass('case-interview-field-focused');
                    return true;
                }
            });
            //and focus the newly found one
            if(tmp) {
                tmp.addClass('case-interview-field-focused');
            }
        },

		_click: function(e) {
			var instance = this;
			// the target has to be the currentTarget or has to have one of the classes inside the container or
			// error-description

            //TODO: does this really have to be a so tremenduosly verbose whitelist?
			if(e.currentTarget === e.target ||
					e.target.hasClass('case-interview-field-body') ||
                    e.target.hasClass('case-interview-field') ||
                    e.target.hasClass('case-interview-field-label') ||
                    e.target.hasClass('case-interview-field-line-reference') ||
                    e.target.hasClass('case-interview-field-label-text') ||
                    e.target.hasClass('case-interview-field-input') ||
                    e.target.hasClass('ui-case-interview-field-mandatory-strong') ||
					e.target.hasClass('tool-box') ||
                    e.target.hasClass('case-interview-field-input-tool-container') ||
					e.target.hasClass('case-interview-field-input-chooser') ||
					e.target.hasClass('case-interview-field-input-checkbox-radio-container') ||
					e.target.hasClass('case-interview-field-input-chooser__positive') ||
					e.target.hasClass('case-interview-field-input-chooser__negative') ||
                    e.target.hasClass('case-interview-field-input-tool-btns') ||
                    e.target.hasClass('case-interview-field-input-tool-btns-label') ||
                    e.target.hasClass('ui-case-interview-field-input-tool-btn') ||
                    e.target.hasClass('ui-case-interview-field-input-tool-btns-label') ||
                    e.target.hasClass('ui-full-year-btn') ||
					e.target.hasClass('case-editor-interview-field-problem-details')) {
				instance._triggerFocus(e.currentTarget.all('.onse-field'));
			}
		}

	};

	/**
	 * 
	 * @class InterviewHelp
	 * @extends Base
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	Y.FocusInterview = Y.Base.create('FocusInterview', Y.Base, [], proto, statics );

}, '1.0.0', {
		requires:[
            'base-build',
            'event-delegate',
            'node-event-delegate'
        ]
	}
);

