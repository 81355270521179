/**
 * This module triggers and manages mobile optimizations, such as viewport resizes and css class management.
 *
 * Use /mobile.html to test the mobile view using a desktop browser, device type is autodetected (default: tablet).
 * Use /mobile-phone.html to test the phone view.
 * Use /desktop.html to switch back to desktop view.
 *
 * Use /mobile-device-width.html to test device-width scaling for the  mobile view.
 * Use /mobile-phone-device-width.html to test the phone view using device-width scaling.
 *
 * WARNING: make sure to resize the browser window for testing minimal resolutions if you're not using dev tools!
 *
 * @see app.jsp for #ui-is-mobile and other switches that are set on load by the Spring Mobile Device module
 * @see _l-only_mobile.scss for styles that are used after initMobile sets class is-mobile (and maybe others)
 * @see interview-help.js for field help enhancements
 * @see interview-field-mobile.js for paypal style number input
 * @see some other hacks and switches by searching for #ui-is-mobile
 */
YUI.add('mobile-transformer', function(Y) {

	"use strict";

	(function(){

		Y.MobileTransformer = Y.Base.create('MobileTransformer', Y.Base, [], {

			_eventHandlers : [],

			_windowWidth : 540,

			getComputedWidth : function() {
				if(Y.one('#ui-is-force-mobile') && Y.one('#ui-is-force-mobile').get('value') == "true") {
					return window.innerWidth;
				} else {
					return screen.width;
				}
			},

			initializer: function () {
				this._windowWidth = this.getComputedWidth();

				//init viewport for all main components after the view changed
                this._eventHandlers.push(Y.after('*:activeViewChange', this.initMobile, this));
				//init viewport for each interview page after it has rendered
				this._eventHandlers.push(Y.after('interview:rendered', this.initMobile, this));
				//init viewport for various views overview
				this._eventHandlers.push(Y.after('interview:multiOverviewRendered', this.initMobile, this));
				this._eventHandlers.push(Y.after('*:spreadsheetOpen', this.initMobile, this));
				this._eventHandlers.push(Y.after('*:spreadsheetClosed', this.initMobile, this));
				this._eventHandlers.push(Y.after('*:appHelpRendered', this.initMobile, this));
				this._eventHandlers.push(Y.after('*:appHelpClosed', this.initMobile, this));
				this._eventHandlers.push(Y.after('*:caseManagerRendered', this.initMobile, this));
				//listener for click on nav button
				this._eventHandlers.push(Y.delegate('click', function(e) {this.toggleNav(e);}, 'body', '#ui-mobile-toggle-navigator', this));
				//custom nav close button listener
				this._eventHandlers.push(Y.delegate('click', function(e) {this.toggleNav(e);}, 'body', '.ui-nav-close-button', this));
				//listener for after a navigation target in the tree was clicked
				this._eventHandlers.push(Y.delegate('click', this._handleNavigation, 'body', '#ui-nav-tree a.openArea', this));

				//reinitialize mobile view after each resize or device reorientation
                window.addEventListener('resize', this._reactToResizeEvent.bind(this));
				window.addEventListener("orientationchange", this._reactToResizeEvent.bind(this));

				//highlight the nav icon a little bit for first impressions
				this._eventHandlers.push(Y.onceAfter('interview:rendered', this._highlightNavigation, this));
			},

            /**
			 * Everything dynamic goes here. Make sure to understand from where and how often this is called.
			 * Every view should be able to have changes implemented here. When started the minimum (resize etc)
			 * should be done by the app. Nav toggle can call initMobile(), too - so please think of it like a
			 * filter / interceptor.
			 *
             */
			initMobile : function(targetView) {

				if(Y.one('#ui-is-mobile').get('value') == 'true') {
                    //-----------------BASE SETUP---------------------------------
                    //set base css that can be used in the sass files
                    Y.one('#body').addClass('is-mobile');

					//-----------------SIZING DECISION VARIABLES------------------
					//check if we're inside the interview and the navigation is shown, so the code below can make
					//the best decisions on how to size the viewport
					var isPhoneNotTablet = Y.one('#ui-is-phone').get('value') == 'true';
					var isCaseEditorView = Y.one('#case-editor-main');
                    var isOptimizedCaseEditorView = Y.one('#case-interview') || Y.one('#case-editor-summary');
                    var isDashboardView = Y.one('#cases-manager')

					//-----------------SIZING DECISION LOGIC----------------------

					//default for all views that are not handled
					var baseWidth = 764;
					var naviWidth = 0;

					//console.log("idth: "+this.getComputedWidth());

					if(isDashboardView) {
						baseWidth = 470;

						//scale a bt higher for tablets
						if(!isPhoneNotTablet) {
							baseWidth = Math.max(baseWidth, this.getComputedWidth());
							baseWidth = Math.min(baseWidth, 1000);
						}
					}
					else if(isCaseEditorView) {
						//minimum for tablet
						baseWidth = 540;
						naviWidth = !Y.one('#body').hasClass('is-inactive-sidebar') ? 260 : 0;

						/*
						Use something like this in your components:

						if(Y.one('#ui-is-mobile').get('value') == 'true') {
							Y.one("#body").addClass("ui-is-open-spreadsheet");
							this.fire('spreadsheetOpened');
						}
						 */
						var smallSizePreventingViewActive = Y.one("#body").hasClass("ui-is-open-spreadsheet")
														 || Y.one("#body").hasClass("ui-is-open-help-overlay")
														 || Y.one('.case-editor-interview-help-inliner iframe')
														 || Y.one('.case-editor-section-help iframe')
														 || Y.one('.case-editor-inline-report');

						if(isPhoneNotTablet) {
							if(!smallSizePreventingViewActive) {
								//we can use the small view
								baseWidth = 320;
							}
						}

						if(!isOptimizedCaseEditorView) {
							baseWidth = 764
						}

						if(!isPhoneNotTablet && this.getComputedWidth() >= 1280) {
							//or on bigger tablet displays, give them more space.
							//but leave some space for possible scrollbars
							baseWidth = 1000;

							if(naviWidth > 0) {
								naviWidth += 100;
								baseWidth -= 100;
							}
						}
					}

                    //-----------------INTERVIEW-SPECIFIC-------------------------
					//enhancements for all responsive views
					if(isPhoneNotTablet) {
						Y.one('#app-header') && Y.one('#app-header').one('.app-header-logo').setStyle("display", "none");
						Y.one(".ui-open-registration") && Y.one(".ui-open-registration").setContent("Anmelden");
						Y.one('.ui-mobile-other-year') && Y.one('.ui-mobile-other-year').setStyle('display', 'none');

						//multi overview
						if(Y.one('.ui-multif-add-initial-label')) {
							Y.one('.ui-multif-add-initial-label').setStyle('visibility','hidden');
							Y.one('.ui-multif-box').setStyle('padding', '10px 10px 10px 10px');
							Y.one('.ui-multif-box').setStyle('width', 'auto');
							Y.one('.ui-multif-box').one('h1').setStyle('width', 'auto');
						}

						//dashboard scaling case items
						if(Y.one('.caselist-item')) {
							Y.all('.caselist-item').setStyle('display','inline-table');
							Y.all('.caselist-item').setStyle('width','96%');
						}
					}


                    //special instruction for while we are in the interview
                    if(isOptimizedCaseEditorView) {

                        //don't render the help sidebar
                        if(Y.one('#case-interview-body') && Y.one('#case-interview-body').hasClass('case-interview-body-with-field-help')) {
                            Y.one('#case-interview-body').removeClass('case-interview-body-with-field-help');
							Y.one('#case-interview-field-help') && Y.one('#case-interview-field-help').setStyle('display', 'none');
                        }

                        //for small mobile phone displays
                        if(isPhoneNotTablet) {
                        	//wrap lines and labels
                            Y.all(".case-interview-field-body").setStyle("float", "none");
                            Y.all(".case-interview-field-body").setStyle("width", "auto");
                            Y.all(".case-interview-field-label").setStyle("float", "none");
                            Y.all(".case-interview-field-label").setStyle("width", "auto");
							//create ultra compact view by enhancing and hiding some stuff
							Y.all('.ui-case-interview-field-label-text').setStyle('marginBottom', '8px');
							//TODO: does not work on overview areas, streamline in whole app
							Y.one('.ui-case-editor-back') && Y.all('.ui-case-editor-back').setStyle('display', 'none');
							Y.one('.c-navigation__list').all("li").setStyle("margin-left", "2px");
							Y.one('.c-navigation__list').get('children').each(function(item) {
								item.one("span") && item.one("span").setStyle("display", "none");
							});
                        }

                        //experimental stuff
                        try {
                            //prevent auto zoom by setting font-size to 16px
                            //Y.all('.field-format-C').setStyle('font-size', '16px');
                            //Y.all('.field-format-Q').setStyle('font-size', '16px');
                        }
                        catch(ex) {

						}
					}

					//--------------MANDATORY SIZING------------------------------
                    //set final fixed width for app container and content areas

					//show normal navigator instead of overlay on big enough tablet sizes
					var showOverlayMenu = isPhoneNotTablet || baseWidth <= 800;
                    this.showOverlayMenu = showOverlayMenu;

                    //console.log(window.innerWidth+" / "+screen.width+" / "+this.getComputedWidth());

                    if(showOverlayMenu) {
                    	//an overlay does not need horizontal space, so we'll put it to zero here
                    	naviWidth = 0;

                    	//lazily adding a custom close button
						var nav = Y.one('.case-editor-sidebar-navigator-container');
                    	if(nav && !nav.one('.ui-nav-close-button')) {
							nav.prepend(
								Y.Node.create('<a class="ui-nav-close-button nav-close-button" href="javascript:void(0);"><i class="fa fa-window-close"></i></a>')
							);
						}

                    	//hide overlay menu by default
						if(nav && !this._navInitialized) {
							this._navInitialized = true;
							this.toggleNav();
						}
					}

                    //the final horizontal resolution we want to use for this view
                    var calculatedWidth = ""+(baseWidth+naviWidth);

					//child containers: adjust relative split
                    if(isCaseEditorView) {
						if(!this.showOverlayMenu) {
							//navigator is rendered on the left side
							Y.one('#case-editor-main').setStyle('width', Math.min(Math.ceil(baseWidth / calculatedWidth * 100 + 1), 100) + '%');
							Y.one('#case-editor-sidebar').setStyle('width', Math.floor(naviWidth / calculatedWidth * 100 - 2) + '%');
							Y.one('#case-editor-sidebar').setStyle('minHeight', '0px');
							Y.one('.case-editor-sidebar-navigator-container').setStyle('width', 'auto');
							Y.one('.case-editor-sidebar-navigator-container').setStyle('position', 'relative');
						}
						else {
							//navigator is rendered as an overlay
							Y.one('#case-editor-main').setStyle('width', '100%');
							Y.one('#case-editor-sidebar').setStyle('width', '0%');
							Y.one('.case-editor-sidebar-navigator-container').setStyle('position', 'absolute');

							if(!Y.one('#body').hasClass('is-inactive-sidebar')) {
								Y.one('.case-editor-sidebar-navigator-container').setStyle('width', '93%');
								Y.one('.case-editor-sidebar-navigator-container').setStyle('backgroundColor', 'white');
								Y.one('.case-editor-sidebar-navigator-container').setStyle('boxShadow', '8px 0px 20px rgba(0,0,0,0.1)');
								Y.one('.case-editor-sidebar-navigator-container').setStyle('padding', '12px');
								Y.one('#case-editor-sidebar').setStyle('minHeight', '1000px');
							}
							else {
								Y.one('.case-editor-sidebar-navigator-container').setStyle('width', '0%');
								Y.one('.case-editor-sidebar-navigator-container').setStyle('boxShadow', 'none');
								Y.one('#case-editor-sidebar').setStyle('minHeight', '0px');
							}
						}
                    }
                    //---------------VIEWPORT META-------------------------------
					//adjust the meta viewport dynamically so browsers max out the available scale

                    var content = "width=" + calculatedWidth;

                    if(calculatedWidth < 550) {
                    	content += ", user-scalable=no";
					}

                    if(Y.one('#ui-is-device-width-view').get('value') == 'true') {
                    	content = "width=device-width, initial-scale=1, user-scalable=no";
                    	//while the split ratio percentage has already been calculated we'll just use innerwidth here
						calculatedWidth = this.getComputedWidth();
					}

					//most important: app-container viewport
					Y.one('#app-container').setStyle('width', '100%');
					Y.one('#app-container').setStyle('width', calculatedWidth+'px');
					Y.one('#app-container').setStyle('min-width', calculatedWidth+'px');
					Y.one('#app-container').setStyle('height', 'auto');

					//meta viewport tag
                    document.getElementById("viewport").setAttribute("content", content);
				}

			},

			/**
			 * Safari on ios throws some additional events.
			 * We are only concerned about horizontal viewport changes.
			 */
			_reactToResizeEvent : function(e) {
				if (this.getComputedWidth() != this.windowWidth) {
					this.windowWidth = this.getComputedWidth()

					this.initMobile();
				}
			},

			/**
			 * After a link is clicked a possible navigator overlay should close.
			 *
			 */
			_handleNavigation : function(e) {
				if(this.showOverlayMenu) {
					this.toggleNav(e);
				}
			},

			/**
			 * Shows or hides the navigator and reinitalizes the viewport accordingly.
			 *
			 */
			toggleNav : function(e) {
				//console.log('toggling nav tree');

				if(Y.one('#body').hasClass('is-inactive-sidebar')) {
					Y.one('#body').removeClass('is-inactive-sidebar');
                    Y.one('#ui-mobile-toggle-navigator').setStyle('box-shadow', 'inset 0 0 0 2px #006099');

                    window.scrollTo(0,0);
				}
				else {
					Y.one('#body').addClass('is-inactive-sidebar');
                    Y.one('#ui-mobile-toggle-navigator').setStyle('box-shadow', 'none');
				}

				this.initMobile();
			},

			/**
			 * Small glow effect for the nav icon.
			 * Could use YUI transitions instead, but a 300ms one is already defined in css.
			 */
			_highlightNavigation: function() {
				if(Y.one('#ui-mobile-toggle-navigator')) {
					Y.later(3000, this, function () {Y.one('#ui-mobile-toggle-navigator').setStyle('background', '#ff7f01');});
					Y.later(3400, this, function () {Y.one('#ui-mobile-toggle-navigator').setStyle('background', '#fff');});
					Y.later(3800, this, function () {Y.one('#ui-mobile-toggle-navigator').setStyle('background', '#ff7f01');});
					Y.later(4200, this, function () {Y.one('#ui-mobile-toggle-navigator').setStyle('background', '#fff');});
				}
			}

		}, {
			ATTRS: {
			}
		});
	})();

}, '1.0.0', {
	requires:[
		'base-build',
		'event',
		'node',
		'view',
	]});
