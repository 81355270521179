CSS_config = {
  debug: false,
  groups : {
    css : {
      combine: true,
      comboBase: '/css/combo?',
      root: '',
      /* if combine is false */
      base: '/css/',
      modules: {
        'browserspecific-css': {
          path: 'browserspecific.css',
          type: 'css'
        },
        'smst-webkit-css': {
          path: 'smst-webkit.css',
          type: 'css',
          condition: {
            trigger: 'browserspecific-css',
            test: function(Y) {
              return 0 < Y.UA.webkit;
            }
          }
        },
        'smst-ff-css': {
          path: 'smst-ff.css',
          type: 'css',
          condition: {
            trigger: 'browserspecific-css',
            test: function(Y) {
              return 0 < Y.UA.gecko;
            }
          }
        }
      }
    }
  }
};