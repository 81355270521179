/*
YUI 3.17.2 (build 9c3c78e)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/

YUI.add('selector', function (Y, NAME) {



}, '3.17.2', {"requires": ["selector-native"]});
