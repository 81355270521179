/**
 *  Plugin, dass ein Widget versteckt sobald die escape-taste gedrückt wird.
 */
YUI.add('gorilla-widget-escape-hide-plugin', function(Y) {


    var EscapeHidePlugin = function(config) {
        EscapeHidePlugin.superclass.constructor.apply(this, arguments);
    };

    Y.extend(EscapeHidePlugin, Y.Plugin.Base, {

        host: null,

        handler: null,

        initializer: function() {
            this.host = this.get('host');
            this.handler = this.host.after('visibleChange', this.toggleListener, this);
        },

        toggleListener: function(e) {
            if( e.newVal ) {
                Y.log("setting up escape listener...");
                Y.on('key', function(e) {
                    e.preventDefault();
                    this.host.hide();
                }, 'body', "down:27", this);
            } else {
                Y.log("Host was hidden. Removing escape listener...");
                this.handler.detach();
            }
        }

    }, {
        NAME: 'escapeHide', // component name
        NS: 'esc' // plugin namespace
    });


    // add this to our namespace
    Y.namespace("gorilla.widget").EscapeHidePlugin = EscapeHidePlugin;

}, '1.0' ,{requires:['plugin','node']});