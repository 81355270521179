YUI.add('spreadsheet-help', function (Y, NAME) {

"use strict";
var Lang = Y.Lang,
		fromTemplate = Lang.sub,
		toArray = Y.Array,
		eachArrayItem = toArray.each,

		CONTAINER_TEMPLATE = '<div class="case-interview-spreadsheet-help-container {className}"></div>',

		HEADLINE_TEMPLATE = '<h3 class="yui3-spreadsheet-caption ' +
				'ui-case-interview-spreadsheet-help-headline">{text}</h3>',

		CONTENT_EXPANDED_TEMPLATE =
						'<div class="case-interview-spreadsheet-help">' +
						'<div class="case-interview-spreadsheet-help-content case-editor-interview-help">' +
						'{text}</div><div class="case-interview-spreadsheet-help-pointer"></div></div>',

		CONTENT_COLLABSED_TEMPLATE =
				'';
//		CONTENT_EXPANDED_TEMPLATE =
//				'<span class="case-interviewspreadsheet-help-toggle-container">' +
//						'<button class="btn btn-s case-interviewspreadsheet-help-toggle ' +
//						'ui-case-interviewspreadsheet-help-toggle"> Hilfe ausblenden </button></span>' +
//						'<div class="case-interview-spreadsheet-help">' +
//						'<div class="case-interview-spreadsheet-help-content case-editor-interview-help">' +
//						'{text}</div><div class="case-interview-spreadsheet-help-pointer"></div></div>',
//
//		CONTENT_COLLABSED_TEMPLATE =
//				'<span class="case-interviewspreadsheet-help-toggle-container">' +
//						'<button class="btn btn-s case-interviewspreadsheet-help-toggle ' +
//						'ui-case-interviewspreadsheet-help-toggle"> Hilfe einblenden </button></span>';


function getStyleValue(node, style) {
	var value = node.getComputedStyle(style);
	return parseFloat(value.replace(/\D/g, ''));
}

/**
 * Constructor function.
 * @constructor
 */
function Help() {
}


Y.mix(Help.prototype, {

	_helpHandlers: [],


	// -----------------------------------------------------------------------------------------------------------

	_collectHelpContent: function () {
		var that = this,
				colDefs = that.get('columns');

		that._helpTexts = {};

		eachArrayItem(colDefs, function (it) {
			var help = it.help;
			if (help) {
				that._helpTexts[it.key] = help;
				it.className += " " + that.getClassName("help", "configured");
			}
		});
	},

	// we have to prepare each row. We want to know where to place the result and if there is additional space for
	// a label.
	initializer: function () {
		var that = this,
				caption;


		// if this spreadsheet is set to readonly - we are done.
		if (true !== that.get('readOnly')) {
			// we have to collect the possible help texts
			that._collectHelpContent();
			if (!Y.Object.isEmpty(that._helpTexts)) {
				// when the bodyView was rendered we have to render the help container.
				that._helpHandlers.push(that.on('*:render', that._renderHelp, that));
				that._helpHandlers.push(that.after('helpChange', that._renderHelpUI, that));
				that._helpHandlers.push(that.after('helpExpandedChange', that._renderHelpUI, that));

				// we have to check if there is a caption configured.
				// if so we have to rearrange the dom.
				caption = that.get('caption');
				if (caption) {
					that.set('caption', null);
					that._caption = caption;
				}
			}
		}

	},

	_createHelpNode: function () {
		return Y.Node.create(fromTemplate(CONTAINER_TEMPLATE, {
			className: this.getClassName('help')
		}));
	},


	_renderHelpUI: function () {
		var that = this,
				container = that.get('contentBox'),
				pointer, helpContent,
				helpNode = that._helpNode ||
						(that._helpNode = that._createHelpNode()),
				template = (true === that.get('helpExpanded') ? CONTENT_EXPANDED_TEMPLATE : CONTENT_COLLABSED_TEMPLATE);

		helpNode.setHTML(fromTemplate(template, {
			text: that.get('help')
		}));

		pointer = helpNode.one('.case-interview-spreadsheet-help-pointer');
		helpContent = helpNode.one('.case-interview-spreadsheet-help-content');

		if (that._currentHelpNode) {
			// this is not the first run - the pointer has to be positioned
			// but only if it is visible.
			if (pointer) {
                if(Y.one('#ui-is-mobile').get('value') == 'true') {

                }
				(function () {
					// first get the offset of the current node.
					var offsetHelpNode = that._currentHelpNode.getX(),
							offsetHelpContainer = helpNode.getX(),
							contentWidth = helpContent.get('offsetWidth'),
							containerWidth = getStyleValue(helpNode, 'width'),
							widthDifference;
					// position the pointer
					pointer.setX(offsetHelpNode);
					// That was easy now we have to do some math to align the help-content
					widthDifference = (offsetHelpNode + contentWidth) - (offsetHelpContainer + containerWidth);
					if (widthDifference > 0) {
						offsetHelpNode -= widthDifference;
					}
					if (0 > offsetHelpNode) {
						offsetHelpNode = 0;
					}
					helpContent.setX(offsetHelpNode);

				})();
			}
		} else if (helpContent) {
			helpContent
					.addClass('case-interview-spreadsheet-help-initial');
			// any pointers need to be invisible on start.
			if (pointer) {
				pointer.addClass('ui-invisible');
			}
		}

		if (helpNode.get('parentNode') !== container) {
			// insert the help above the table
			container.insert(helpNode, 'table');
			// and - if there is one - the caption.
			if (that._caption) {
				container.insert(fromTemplate(HEADLINE_TEMPLATE, {text: that._caption}), helpNode);
			}
		}
	},

	_updateHelp: function (e) {
		var that = this,
		// find the td/th around the current target - the cell might be the target by itself.
				target = e.target.ancestor('th,td', true),// true -> start with the target not with its first ancestor.
				columnId, help;

		// first we have to check if there is any target:
		if (target) {
			columnId = target.getData('yui3-col-id');
			// if we have found a column we can set it's help as current help.
			if (columnId) {
				help = that._helpTexts[columnId];
				if (help) {
					// we need to store this node to calculate the offsets of our help-content and of the pointer.
					that._currentHelpNode = target;
					that.set('help', help);
				}
			}
		}
	},

	_toggleHelp: function (e) {
		var that = this;
		// this is a button click - stop it.
		e.halt();
		that.set('helpExpanded', !that.get('helpExpanded'));
	},

	_bindHelpUI: function () {
		var that = this,
				contentBox = that.get('contentBox');

		// listen for mouse enter events above our headers.
		that._helpHandlers.push(contentBox.delegate('mouseenter', Y.rbind(that._updateHelp, that),
				'th.yui3-spreadsheet-help-configured'));

		// listen for focus
		that._helpHandlers.push(contentBox.delegate('focus', Y.rbind(that._updateHelp, that),
				'input,select,button'));

		// listen for clicks on the toggle button
		that._helpHandlers.push(contentBox.delegate('click', Y.rbind(that._toggleHelp, that),
				'.ui-case-interviewspreadsheet-help-toggle'));

	},

	/**
	 * We have to render our markup and anotate some of the editors.
	 * @private
	 */
	_renderHelp: function () {
		this._renderHelpUI();
		this._bindHelpUI();
	},

	destructor: function () {
		eachArrayItem(this._helpHandlers, function (handler) {
			handler.detach();
		});
	}
});

Y.mix(Help, {
	ATTRS: {
		help: { value: '<strong>Feldhilfe</strong><br>Klicken Sie auf ein Eingabefeld oder bewegen Sie Ihre Maus ' +
				'\u00FCber eine Spalten\u00FCberschrift, um hier detaillierte Erl\u00E4uterungen angezeigt ' +
				'zu bekommen.' },
		helpExpanded: {value: true}
	}
});

Y.namespace('spreadsheet').Help = Help;

}, '1.0.0', {"requires": ["base", "event-focus", "event-mouseenter", "node"]});
