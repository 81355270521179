// YUI boilerplate
YUI.add('_google-analytics-repository', function(Y) {

	// creates our namespace
    Y.sst = Y.sst || {};

	Y.sst.GA = GoogleAnalyticsRepository();

}, '1.0.0', {requires:[]} );

var GoogleAnalyticsRepository = function () {

	var GoogleAnalyticsRepository = {};

	GoogleAnalyticsRepository.logEvent = function(category, action, opt_label, opt_value, nonInteraction) {
		if( window.ga && category && action ) {
			var fieldsObject = {
				eventCategory: category,
				eventAction: action
			};
			if(opt_label ) {
				fieldsObject.eventLabel = opt_label;
			}
			if(opt_value) {
				fieldsObject.eventValue = opt_value;
			}
			if(nonInteraction) {
				fieldsObject.nonInteraction = nonInteraction;
			}
			ga('send', 'event', fieldsObject);
		}

	};


	return GoogleAnalyticsRepository;
};