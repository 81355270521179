/**
 *  Plugin, dass ein Widget versteckt sobald die escape-taste gedrückt wird.
 */
// TODO: check: two times the same plugin? (change the template)

/**
 * config keys:
 * section ->  Y.WidgetStdMod.HEADER, Y.WidgetStdMod.BODY or Y.WidgetStdMod.FOOTER
 * template -> <span class="cancel-button">X</span>
 * append -> _true_, false // insert the button at the end of the container or at the beginning.
 */
YUI.add('gorilla-widget-cancel-button-plugin', function(Y) {

    var HOST = 'host',

        OVERLAY = 'overlay',
        CANCEL = 'cancel',

        getCN = Y.ClassNameManager.getClassName,

        CLASSES = {
            cancel : getCN(OVERLAY, CANCEL)
        };

    var VERBOSE = false;

    var CancelButtonPlugin = function(config) {
        CancelButtonPlugin.superclass.constructor.apply(this, arguments);
    };

    Y.extend(CancelButtonPlugin, Y.Plugin.Base, {

        _uiHandles: null,

        _buttonNode: null,

        initializer: function( config ) {
            // sanity check
            if(!config.host.getStdModNode) {
                Y.log("The host is not using StdMod.", "error", CancelButtonPlugin.NAME );
            }

            this._uiHandles = {};

            this.afterHostEvent('render', this.render);

            // is it rendered already?
            if (this.get(HOST).get('rendered')) {
                this.render();
            }
        },

        destructor : function () {

            if (this._buttonNode) {
                this._buttonNode.remove(true);
            }
            this._detachUIHandlers();
        },

        render: function() {
            this.renderUI();
            this.bindUI();
            this.syncUI();
        },



        renderUI: function() {
            if(VERBOSE) {
                Y.log("renderUI", null, CancelButtonPlugin.NAME);
            }

            var host = this.get(HOST);
            var button = Y.Node.create( this.get('template'));
            button.addClass( this.get('classes').cancel );
            this._buttonNode = button;

            var box = host.getStdModNode( this.get('section') );
            if( null == box ) {
                // error and exit.
                Y.log('Target box: "' + this.get('section') + '" not found for host: "' +this.get(HOST)+ '"!',
                        "error", CancelButtonPlugin.NAME);
                return;
            }
            if( this.get('append')) {
                // append
                box.append(button);
            } else {
                // prepend
                box.prepend(button);
            }

            if(this.get('centered')) {
                box.setStyle('textAlign', 'center');
            }
        },

        bindUI: function() {
             this.afterHostEvent('visibleChange', this._afterHostVisibleChange);
        },

        syncUI: function() {
            this._setHostVisibleChange( this.get(HOST).get('visible') );            
        },

        _afterHostVisibleChange: function(e) {
            this._setHostVisibleChange( e.newVal );
        },

        _setHostVisibleChange: function(visible) {
            if( visible ) {
                this._attachUIHandlers();
            } else {
                this._detachUIHandlers();
            }
        },

        // listen for clicks
        _attachUIHandlers: function() {
            if(VERBOSE) {
                Y.log("setting up cancel listener...");
            }

            var uiHandles = this._uiHandles;
            if( !uiHandles.click ) {
                uiHandles.click = this._buttonNode.on('click', function(e) {
                    e.preventDefault();
                    this.get(HOST).hide();
                }, this );
            }
        },


        _detachUIHandlers: function() {
            if(VERBOSE) {
                Y.log("removing cancel listener...");
            }

            var uiHandles = this._uiHandles;
            Y.Object.each(uiHandles, function(h, key) {
                h.detach();
                delete uiHandles[key];
            });
        }

    }, {
        NAME: 'CancelButton', // component name
        NS: 'cancelbutton', // plugin namespace,
        ATTRS: {

            /**
             * Gibt an in welche box des Hosts der Button geschrieben werden soll.
             */
            section: {
                value: Y.WidgetStdMod.FOOTER,
                validator: function(val) {
                    return (val == Y.WidgetStdMod.HEADER ||
                            val == Y.WidgetStdMod.BODY ||
                            val == Y.WidgetStdMod.FOOTER ); 
                },
                writeOnce: true
            },

            template: {
                value: '<span>Abbrechen</span>',
                writeOnce: true

            },

            append: {},

            centered : false,

            classes: {
                value: CLASSES,
                validator: function( val ) {
                    return Y.Object.hasKey(val, 'cancel');
                }
            }
        }
    });


    // add this to our namespace
    Y.namespace("gorilla.widget").CancelButtonPlugin = CancelButtonPlugin;

}, '1.0' ,{requires:['plugin','widget', 'widget-stdmod']});