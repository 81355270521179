// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-interview', function(Y) {
    "use strict";

    var Slang = Y.smst.Lang;

    (function() { Y.CaseEditorAppInterview =
		    Y.Base.create('CaseEditorAppInterview', Y.smst.CaseEditorAppBase, [Y.AppOverlays], {

            // we are using some overlays to perform certain tasks. These are configured here.
            // All of them will be created only if they are requested.
            overlays : {
                unsavedChanges : {
                    header:"Ungesicherte Änderungen",
                    footer:'<button type="button" class="btn" id="unsaved-discard">Verwerfen</button><button type="button" class="btn btn-hilited" id="unsaved-save">Übernehmen</button>',
                    template: 'case-editor-interview-overlay-unsaved',
                    events : {
                        '#unsaved-save' : {
                            click : '_saveOnNavigate',
                            keypress : '_saveOnNavigate'
                        },
                        '#unsaved-discard' : {
                            click : '_forceLoad',
                            keypress : '_forceLoad'
                        }
                    },
                    // don't add escape listeners and buttons
                    notEscapable : true,
                    mobileNode: '#case-editor-main'
                },
                invalidValues : {
                    header:"Ungültige Angaben auf dieser Seite",
                    footer:
                        '<button type="button" class="btn" id="invalid-save">'
                        + ((Y.one('#ui-is-mobile') && Y.one('#ui-is-mobile').get('value') == 'true') ? 'Weiter' : 'Trotzdem übernehmen') //FIXME: hack, streamline?
                        +'</button>',
                    template: 'case-editor-interview-overlay-invalid',
                    mobileNode: '#case-editor-main',
                    events : {
                        '#invalid-save' : {
                            click : '_forceSave',
                            keypress : '_forceSave'
                        }
                    },
	                plugins : [
		                Y.Plugin.OverlayModal,
		                Y.gorilla.overlay.AnimPlugin,
		                Y.gorilla.widget.EscapeHidePlugin,
		                Y.gorilla.widget.XButtonPlugin,
		                {
			                fn:Y.gorilla.widget.CancelButtonPlugin,
			                cfg: {
				                template: '<button type="button" class="btn btn-hilited ui-cancel-btn">Korrigieren</button>',
				                section: Y.WidgetStdMod.FOOTER,
				                append: true
		                    }
		                }
	                ]
                }

            },

            _eventHandlers : [],

            // --- lifecycle management ----------------------------------------------------------------------------------

            initializer : function() {
                var that = this;

	            that._eventHandlers.push( Y.after('CaseEditor:pageStateChange', that.updateView, that) );
	            that._eventHandlers.push( Y.on('*:calculate', that._saveAndStay, that));
	            that._eventHandlers.push( Y.on('*:internalNext', that._handleInternalNext, that));

                /**
                 * This event gets fired whenever a new page is about to get loaded.
                 * It is preventable though. If prevented it will raise an alert to inform the user about unsaved
                 * values.
                 */
                that.publish('load', {
                    defaultFn   : that._loadDefault,
                    preventable : true,
                    preventedFn : that._loadPrevented
                });

                /**
                 * This event gets fired whenever one of the "next" buttons is clicked. It is nearly identical to the
                 * load event. The only difference is that it will react differently when prevented. In this case
                 * it will fire the event "save".
                 */
                that.publish('next', {
                    defaultFn   : that._loadDefault, // <--- this is the same default action as in the load event!!!
                    preventable : true,
                    preventedFn : that._nextPrevented
                });

                /**
                 * This event gets fired whenever one of the "next" buttons is clicked and changes where found on the
                 * current page. It has no default function. This action is taken care of in our sub apps.
                 *
                 * When prevented this will ask the user what to do. Discard the changes or save invalid values.
                 */
                that.publish('save', {
                    preventable : true,
                    preventedFn : that._savePrevented
                });

                that.publish('interview:viewRendered', {
                    emitFacade: true,
                    preventable : false
                });
            },

            destructor : function() {
                Slang.detachEventListener(this._eventHandlers);
            },


	        // --- navigation handling ---------------------------------------------------------------------------------

	        /**
             * Default action of the load event. This will execute the actual ajax request to get the state of
             * the interview. There is one special case: When this is the last page of the interview the navInfo
	         * is <code>null</code> but we can handle this. We will fire 'nextSection' in this case.
             *
             * @private
             */
            _loadDefault:function(e) {
                var that = this,
                    src = e.src,
                    navInfo = e.navInfo,
                    cfg;

                if(e.activateSmartCheck) {
                    that.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('summary').get('link')});
                }
		        else if( navInfo ) {
                    // check for an existing navInfo. If there is none we don't have a target to go to. In that case we
		            // have to leave the interview and open the next section.
			        cfg = {
				        on:{
					        success:function (id, res) {
						        this.fire('updateState', {raw:res.responseText,src:src});
					        },
					        failure:function () {
						        Y.log("Request failed or was aborted", 'warn', 'CaseEditorAppInterview');
					        }
				        },
				        data:navInfo,
				        context:that
			        };

			        Y.log(e.type + ' loading new state', 'DEBUG', 'CaseEditorAppInterview');

			        Y.io("api/state", cfg);
		        }
                else {
			        // The end of the interview. Jump to the next section.
			        that.fire('nextSection');
		        }
            },

            _loadPrevented : function(e) {
                var that = this;
                Y.log('Load was prevented...', 'DEBUG', 'CaseEditorAppInterview');
                if(e.force) {
                    Y.log('... but force is set to true. Loading anyway.', 'DEBUG', 'CaseEditorAppInterview');
                    that._loadDefault(e);
                } else {
                    Y.log('... showing Overlay', 'DEBUG', 'CaseEditorAppInterview');
                    that.showOverlay('unsavedChanges', {loadEvent:e});
                }
            },

            /**
             * This function is called when the users wants to discard unsaved changes and open an other page.
             *
             * @param e The dom event which triggered the overlay to call this function. click or keypress
             * @param overlayPayload Contains the initial load event.
             * @private
             */
            _forceLoad : function(e, overlayPayload) {
                // return early if return wasn't hit.
                if( e.keyCode && 13 !== e.keyCode ) {
                    return;
                }
                e.halt();
                overlayPayload.overlay.hide();
                Y.log('Forcing load...', 'INFO', "CaseEditorAppInterview");
                // call the default action of our load event directly. This way nobody can prevent it.
                this._loadDefault(overlayPayload.loadEvent);
            },


            // this is our new 'openArea'
            _doNavigate : function (navInfo, src) {
                var that = this;

                // save the direction here
                that._navDirection = navInfo._navDirection;

                // We have to broadcast each attempt to request a new state. This way any active view or subapp gets the
                // opportunity to prevent it. Changes on a form would be a reason to stop this event and instead
                // submit the changes.
                that.fire('load', {src:src, navInfo:navInfo});
            },

            /**
             * this will call the api to update the interview state with new navigation coordinates a.k.a.
             * area and form information.
             */
            handleNavigation : function(navInfo) {
                this._doNavigate(navInfo, 'navigation');
            },

            /**
             * Called when the user hits the 'weiter' button.
             */
            handleNext : function() {
                var that = this,
                    nextInfo = this._pageState.next,
                    navInfo = {};

	            // information about the next area exists. That means there is a next area we can jump to.
	            // next info is already in a new format. We have to reconstruct the old layout.
	            var payload = {src:'next'};

	            if( nextInfo ) {
		            Y.mix(navInfo, nextInfo, false, ['area', 'formId', 'formIndex', 'multiFieldIndex']);
		            navInfo.hmfas  = !nextInfo.mfaBase;

		            navInfo.hmfoas = !nextInfo.mfBase;
		            payload.navInfo = navInfo;
	            }
	            // if there is no next area in the interview the navInfo will be null. The default and the preveted
	            // functions of the next-event do know how to handle this.

	            that.fire('next', payload);
            },

            handleNextAndSmartCheck : function() {
                var that = this;
                that.fire('next', {src:'next', activateSmartCheck: true});
            },

			_handleInternalNext : function(e) {
				e.halt();
				this.handleNext();
			},

            /**
             * This is the action which takes place in the event that 'next' was prevented. This will fire the save
             * event to get changes to the server.
             *
             * @param e the prevented next event containing the navigation information.
             * @private
             */
            _nextPrevented : function(e) {

                this.fire('save' ,{
	                // we have to add an empty container for possible error messages. That way every event handler is able
	                // to add it's own error messages to this container and we can handle them in our handlebars templates.
	                errorDescriptions:[],
	                // important: don't provide nav info here. We want the server to decide where to go next.
	                /*,navInfo:e.navInfo*/
                    activateSmartCheck : e.activateSmartCheck
                });

            },

            /**
             * Called when navigation was stoped because of unsaved changes and the user chose to save the changes first.
             *
             * @param e The event (click of keypress) which triggered this method out of the unsavedChanges overlay.
             * @param payload Payload provided to the overlay. This contains the information about the requested page.
             * @private
             */
            _saveOnNavigate : function(e, payload) {
                if( e.keyCode && 13 !== e.keyCode ) {
                    return;
                }
                e.halt();
                payload.overlay.hide();
                this.fire('save', {navInfo:payload.loadEvent.navInfo});
            },

            /**
             * Save is prevented when there are invalid values which normally should not be saved. This function will
             * ask the user if (s)he wants to save them anyway.
             *
             * @param e The prevented save event. Might contain important navigation information.
             * @private
             */
            _savePrevented : function(e) {
                Y.log('Save was prevented');
                // The error descriptions might be there - they don't have to.
                this.showOverlay('invalidValues', { navInfo: e.navInfo, activateSmartCheck: e.activateSmartCheck}, {errorDescriptions:e.errorDescriptions, activateSmartCheck: e.activateSmartCheck});
            },

            /**
             * This is getting called when the user insists to save invalid values.
             *
             * @param e       The event (click of keypress) which triggered this method out of the invalidValues
             *                overlay.
             * @param payload Payload provided to the overlay. This might contain the information about the requested
             *                page.
             * @private
             */
            _forceSave : function(e, payload) {
                if( e.keyCode && 13 !== e.keyCode ) {
                    return;
                }
                e.halt();

                if(payload) payload.overlay.hide();

                this.fire('save', {
                    force:true,
                    activateSmartCheck: payload ? payload.activateSmartCheck : false,
                    navInfo: payload ? payload.navInfo : null
                });
            },


			save : function(navInfo) {
				this.fire('save', {navInfo:navInfo});
			},

            /**
             * Like a normal save action but will stay on the same page.
             *
             * @private
             */
            _saveAndStay : function(e) {
                var el = Y.one('#justCalculate');
                el && el.set('value', 'true');

                if(e && e.force) {
                    this._forceSave(e);
                }
                else {
                    this.fire('save');
                }
            },

		    /**
             * Updates the current active view with the content send from the server.
             *
             * @param e The pageStateChange event.
             */
            updateView : function(e) {
                var that = this,
                    cfg, view, viewConfig,
		            state = Y._currentState = that._pageState = e.newVal,
		            activeView = that.get('activeView');

                // shortcut to prevent double loading
                if( 'navigation' !== e.src ) {
                    return;
                }

                Y.log("update view", 'DEBUG', 'CaseEditorAppInterview');

                viewConfig = { render : true };
                viewConfig.callback = function (activeView) {
                    that.get('viewContainer').addClass('case-interview-body-with-field-help');
                    activeView.set('pageContent', '');
                    that.fire('interview:viewRendered');
                }

                if( state.isSummary ) {
                    view = new Y.CaseEditorAppInterviewPageSummary({pageState:state});
                } else if( state.isChapterOverview ) {
                    view = new Y.CaseEditorAppInterviewPageChapters({pageState:state});
                } else if( state.isBase ) {
                    view = new Y.CaseEditorAppInterviewPageMultioverview({pageState:state});
                } else {
                    view = new Y.CaseEditorAppInterviewPageForm({pageState:state});

                    viewConfig.callback = function (activeView) {
                        that.get('viewContainer').addClass('case-interview-body-with-field-help');
                        view._initForm();
                    };
                }

			    // update header information
			    that.fire('sectionMeta', {
				    title: state.qualifiedPath,
				    help:  state.help,
				    image: state.headerImage
			    });

	            // now we have to add our self as a event target to this view. This is done by 'showView' aswell but
	            // this would be to late in our case.
	            view.addTarget(that);

                // and then bring in the new view. Start the request for the real form when the new view is in place.
                that.showView(view, {}, viewConfig);
            },

// --------------------------------------------------------------------------------------------------------------------
// ----- INTERVIEW CONTENT - NOT REFACTORED YET -----------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------


        }, {
            ATTRS : {

                container: {
                    valueFn: function(){
                        return Y.Node.create('<div id="case-interview"/>');
                    }
                },

                viewContainer : {
                    valueFn: function () {
                        return Y.Node.create('<div id="case-interview-body" />');
                    }
                }
            }
        });
    })();

}, '1.0.0', {
    requires:[
        'app',
        'app-overlays',
        'base-build',
        'case-editor-app-base',
        'case-editor-app-interview-page-chapters',
        'case-editor-app-interview-page-form',
        'case-editor-app-interview-page-multioverview',
        'case-editor-app-interview-page-summary',
	    'case-editor-app-interview-registerCTA',
        'case-editor-app-interview-reports',
        'io-base',
        'json-parse',
        'node',
        'querystring-stringify-simple',
        'smst-lang',
        'user-case'
    ]});