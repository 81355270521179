YUI.add('interview-ref-field-plugin', function(Y) {

	"use strict";

	var proto, statics, clazz,
		NAME = "RefFieldPlugin",
		READ_ONLY = 'readOnly',
		REF_VALUES = 'refValues',
		YObject = Y.Object;

	statics = {
		NAME: NAME,
		NS: 'ref',
		ATTRS: {
			refValues : {
				valueFn : function() {
					return {};
				}
			}
		}
	};

	proto = {

		_changeHandler: null,

		initializer : function() {
			// listen for refValues changes
			this._changeHandler = this.after('refValuesChange', this._handleRefValuesChange, this);
		},

		_buildValue : function() {
			var refs = this.get(REF_VALUES),
				finalValue = 's. Anl. ',
				idx = 1,
				max = YObject.size(refs);

			YObject.each(refs, function(val) {
				if( 1 < idx && idx == max) {
					finalValue += ' und ';
				} else if( 1 < idx && idx != max) {
					finalValue += ', ';
				}
				idx++;

				finalValue += val;
			});

			return finalValue;
		},

		_handleRefValuesChange: function(e) {
			var refs = e.newVal,
				h = this.get('host');

			if( YObject.isEmpty(refs) ) {
				h.set('value', '', {src:'tool'});
				h.set(READ_ONLY, false);
			} else {
				h.set(READ_ONLY, true);
				h.set('value', this._buildValue(), {src:'tool'} );
			}
			//TODO: handle this in field by src after refactoring
			h.set('templateValueHadFocus', true);
			h._removeShallow();
		},

		/**
		 * Public API: add a reference Value.
		 */
		setRefValue: function(ref, val) {
			var refVals = this.get(REF_VALUES);
			refVals[ref] = val;
			this.set(REF_VALUES, refVals);
		},

		/**
		 * Public API: remove a reference Value.
		 */
		removeRefValue: function(ref) {
			var refVals = this.get(REF_VALUES);
			delete refVals[ref];
			this.set(REF_VALUES, refVals);
		},

		/**
		 * Public API: get the current size of the refValue list.
		 */
		size : function() {
			return YObject.size(this.get(REF_VALUES));
		},


		destructor : function() {
			this._changeHandler.detach();
			this._changeHandler = null;
		}
	};

	/**
	 * A field with this plugin will become readonly. The value is from than on provided by this plugin which in turn
	 * gets its values from tools which have difined this field as a reference.
	 *
	 * @class RefFieldPlugin
	 * @extends Y.Plugin.Base
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	clazz = Y.Base.create(NAME, Y.Plugin.Base, [], proto, statics );

	Y.namespace("interview").RefFieldPlugin = clazz;

}, '1.0.0', {requires:[
	'base',
	'plugin',
	'node'
]} );