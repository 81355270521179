YUI.add('app-help', function (Y) {

	// creates our namespace
	Y.sst = Y.sst || {};

	Y.sst.AppHelpTabRow = Y.Base.create('AppHelpTabRow', Y.HandlebarsView, [], {

		template: 'app-help-_tabRow',

		setHelpAttributes: function (fieldDefinition) {
			if (!fieldDefinition) {
				return;
			}

			this.set('faq', false);
			this.set('moreHelp', fieldDefinition.moreHelp);
			this.set('examples', fieldDefinition.examples);
			this.set('lexiconLinks', fieldDefinition.lexiconLinks);
			this.render();
		}

	}, {
		ATTRS: {
			faq: {value: true},
			moreHelp: {value: false},
			examples: {value: false},
			lexiconLinks: {value: false}
		}
	});
	// requires : 'base-build','handlebars-view'

	Y.sst.AppHelpFaqTab = Y.Base.create('AppHelpFaqTab', Y.HandlebarsView, [], {
		template: 'app-help-_faq',
	}, {
		ATTRS: {}
	});

	Y.sst.AppHelpSupportTab = Y.Base.create('AppHelpSupportTab', Y.HandlebarsView, [], {

		template: 'app-help-support-form',

		partials: ['app-help-support-text', 'app-help-support-done', 'app-help-support-waiting'],

		events: {
			'#ui-app-help-support-submit': {'click': '_submit'},
			'#ui-app-help-support-back': {'click': 'render'}
		},

		_submit: function (e) {
			var that = this,
				csrf = {};

			e.halt();

			if (that._isValid()) {
				that._disableLiveValidation();

				var csrfToken = that.get('csrfValue');
				csrf[that.get('csrfName')] = csrfToken;

				var cfg = {
					on: {
						start: that._renderWaiting,
						complete: function (id, o) {
							that._renderDone(o.responseText === 'OK');
						}
					},
					context: that,
					method: 'POST',
					data: csrf,
					headers: {
						'X-CSRF-Token': csrfToken
					},
					form: {
						id: 'support-form',
						upload: true
					}
				};

				Y.io("support/support", cfg);
			} else {
				that._markError(true);
				that._enableLiveValidation();
			}
		},

		_isValid: function () {
			var that = this,
				container = that.get('container'),
				name,
				feedback;

			name = container.one('#ui-app-help-support-name').get('value').trim();
			feedback = container.one('#ui-app-help-support-feedback').get('value').trim();

			return name && feedback;
		},

		_markError: function (hasError) {
			var that = this,
				container = that.get('container');
			var firstRow = container.one('.ui-error-marker');
			var labels = container.all('label.ui-error-marker');

			labels.each(function (label) {
				var input = container.one('#' + label.get('htmlFor'));
				var elements = Y.all([label, input]);
				if (hasError && !input.get('value').trim()) {
					elements.addClass('is-error');
				} else {
					elements.removeClass('is-error');
				}
			});

			if (hasError) {
				firstRow.addClass('is-error');
			} else {
				firstRow.removeClass('is-error');
			}
		},

		_enableLiveValidation: function () {
			var that = this,
				container = that.get('container');

			this._validationHander = container.delegate('change', function () {
				that._markError(!that._isValid());
			}, "textarea, input", that);
		},

		_disableLiveValidation: function () {
			if (this._validationHander) {
				this._validationHander.detach();
			}
		},

		_renderWaiting: function () {
			var that = this,
				container = that.get('container'),
				template = that.getPartial('app-help-support-waiting');

			container.setHTML(template());
		},

		_renderDone: function (ok) {
			var that = this,
				container = that.get('container'),
				template = that.getPartial('app-help-support-done');

			container.setHTML(template({ok: ok}));
		}

	}, {
		ATTRS: {
			csrfName: {value: ''},
			csrfValue: {value: ''}
		}
	});

	Y.sst.AppHelpAnonymousSupportTab = Y.Base.create('AppHelpAnonymousSupportTab', Y.HandlebarsView, [], {

		template: 'app-help-support-anonymous',

		partials: ['app-help-support-_anonymous_contactData']

	}, {
		ATTRS: {}
	});
	// requires : base-build','handlebars-view'

	Y.sst.AppHelpMoreHelpTab = Y.Base.create('AppHelpMoreHelpTab', Y.HandlebarsView, [], {

		template: 'app-help-_moreHelp',

		update: function (fieldDefinition) {
			this.set('content', fieldDefinition.moreHelp);
			this.render();
		}

	}, {
		ATTRS: {
			content: {value: null}
		}
	});
	// requires : base-build','handlebars-view'

	Y.sst.AppHelpExamplesTab = Y.Base.create('AppHelpExamplesTab', Y.HandlebarsView, [], {

		template: 'app-help-_examples',

		update: function (fieldDefinition) {
			this.set('content', fieldDefinition.examples);
			this.render();
		}

	}, {
		ATTRS: {
			content: {value: null}
		}
	});
	// requires : base-build','handlebars-view'

	Y.sst.AppHelpLexiconLinksTab = Y.Base.create('AppHelpLexiconLinksTab', Y.HandlebarsView, [], {

		template: 'app-help-_lexiconLinks',

		_hideLexikonWaitingMask: function () {
			var mask = this.get('container').one('#ui-app-help-lexiconLinks-iframe-waitingmask');
			var iframe = this.get('container').one('#ui-app-help-lexiconLinks-iframe');

			iframe.once('load', function () {
				mask.addClass('app-dialog-waiting-wrapper-hidden');
			});
		},

		_setLinkActive: function (link) {
			var shortLink;
			if (-1 < link.indexOf('?')) {
				shortLink = link.substring(0, link.indexOf('?'));
			} else {
				shortLink = link;
			}
			var clickedLink = this.get('container').one('[href^=' + shortLink + ']');
			if (clickedLink) {
				clickedLink.addClass('is-active');
			}
		},

		loadArticle: function (link) {
			this.set('activeLink', link);

			this.render();
			this._hideLexikonWaitingMask();
			this._setLinkActive(link);
		},

		handleLexiconLinkClicked: function (e) {
			e.halt();
			var link = e.currentTarget.get('href');
			this.loadArticle(link);
		},

		_loadFirstArticle: function () {
			var links = this.get('lexiconLinks');
			if (links) {
				this.loadArticle(links[0].link);
			}
		},

		update: function (fieldDefinition) {
			this.set('lexiconLinks', fieldDefinition.lexiconLinks);
			this.render();
			this._loadFirstArticle();
		}

	}, {
		ATTRS: {
			activeLink: {
				value: null,
				setter: function (link) {
					if (!link) {
						return;
					}
					var url = new URL(link);
					url.searchParams.set('onse_iframe', 1);
					return url.toString();
				}

			},
			lexiconLinks: {value: null}
		}
	});
	// requires : base-build','handlebars-view'


	// The app will glue YUI to our code
	Y.sst.AppHelp = Y.Base.create('AppHelp', Y.Base, [Y.AppOverlays], {

		overlays: {
			'app-help-overlay': {
				header: "",
				width: 992,
				template: 'app-help-overlay',
				// don't add escape listeners and buttons
				notEscapable: true,
				// now we have to add the escape listeners manually
				plugins: [
					Y.Plugin.OverlayModal,
					Y.gorilla.overlay.AnimPlugin,
					Y.gorilla.widget.EscapeHidePlugin,
					Y.gorilla.widget.XButtonPlugin
				],
				events: {
					'.ui-app-help-tab': {
						click: '_handleTabClicked'
					},
					'.ui-app-help-lexiconLinks-link, .case-editor-interview-help-lexicon-link': {
						click: '_handleLexiconLinkClicked'
					},
					'.ui-open-registration, .ui-app-help-close': {
						click: '_hideOverlay'
					}
				}
			}
		},

		_handlers: [],
		_service: null,
		_tabRow: null,

		initializer: function () {
			var that = this;

			that._handlers = [];

			Y.once('smartsteuer:ready', that._init, that);
		},

		_init: function () {
			var that = this;

			that._service = createHelpService(Y);

			// event listener to open the help overlay.
			that._handlers.push(Y.one('body').delegate('click', that._openOverlay, '.ui-app-help-open', that));
			that._handlers.push(Y.one('body').delegate('click', that._openSupportOverlay, '.ui-case-editor-support', that));
		},

		_extractFieldIdFromEvent: function (e) {
			return e.target.getData('field-id');
		},

		_createTabs: function (overlay) {
			var config = {
				container: overlay.get('contentBox').one('#ui-app-help-tabRow')
			};
			var tabs = new Y.sst.AppHelpTabRow(config);
			tabs.render();

			return tabs;
		},

		_createTab: function (overlay, name, TabClass, config) {
			config = config || {};

			config.container = overlay.get('contentBox').one('#ui-app-help-' + name);

			var tab = new TabClass(config);
			tab.render();
			return tab;
		},

		_createFaqTab: function (overlay) {
			return this._createTab(overlay, 'faq', Y.sst.AppHelpFaqTab);
		},

		_createSupportTab: function (overlay) {
			var config = Y._config;
			var anonymous = config.anonymous;
			var supportTab;

			if (anonymous) {
				supportTab = this._createTab(overlay, 'support', Y.sst.AppHelpAnonymousSupportTab);
			} else {
				supportTab = this._createTab(overlay, 'support', Y.sst.AppHelpSupportTab, {
					csrfName: config.tName,
					csrfValue: config.t
				});
			}

			return supportTab;
		},

		_createMoreHelpTab: function (overlay) {
			return this._createTab(overlay, 'moreHelp', Y.sst.AppHelpMoreHelpTab);
		},

		_createExamplesTab: function (overlay) {
			return this._createTab(overlay, 'examples', Y.sst.AppHelpExamplesTab);
		},

		_createLexiconLinksTab: function (overlay) {
			return this._createTab(overlay, 'lexiconLinks', Y.sst.AppHelpLexiconLinksTab);
		},

		_loadAdvancedHelp: function (fieldId) {
			var that = this;

			var createTabsWhenAdvancedHelpLoaded = function (fieldDefinition) {
				if (!fieldDefinition) {
					return;
				}

				that._tabRow.setHelpAttributes(fieldDefinition);

				// the field 'moreHelp' can have two states:
				// 1. true - there is additional help for this field available.
				// 2. text - the actual advanced help for this field.
				// we only want the second state
				if (true !== fieldDefinition.moreHelp) {
					that._moreHelpTab.update(fieldDefinition);
				}
				if (fieldDefinition.examples) {
					that._examplesTab.update(fieldDefinition);
				}
				if (fieldDefinition.lexiconLinks) {
					that._lexiconLinksTab.update(fieldDefinition);
				}

				that._activateFirstTab(that._overlay);
			};

			that._service.loadAdvanceHelp(
				fieldId,
				Y._currentState.formIndex,
				Y._currentState.multiFieldIndex,
				createTabsWhenAdvancedHelpLoaded
			);
		},

		_activateFirstTab: function (overlay) {
			var contentBox = overlay.get('contentBox');
			var tabRow = contentBox.one('#ui-app-help-tabRow');
			var firstTab = tabRow.one('.ui-app-help-tab');
			var firstTabName = firstTab.get('id').replace(/^(ui-app-help-tab-)/, "");
			this._activateTab(overlay, firstTabName);
		},

		_setAllTabsDisabled: function (contentBox) {
			contentBox.all('.ui-app-help-tab').removeClass('is-active');
			contentBox.all('.ui-app-help-tab-content').removeClass('is-active');
		},

		_setTabActive: function (contentBox, name) {
			var tab = contentBox.one('#ui-app-help-tab-' + name);
			var tabContainer = contentBox.one('#ui-app-help-' + name);

			if (tab) {
				tab.addClass('is-active');
			}
			if (tabContainer) {
				tabContainer.addClass('is-active');
			}
		},

		_activateTab: function (overlay, name) {
			var contentBox = overlay.get('contentBox');
			this._setAllTabsDisabled(contentBox);
			this._setTabActive(contentBox, name);
		},

		_openOverlay: function (e) {
			e.halt();
			var that = this;

			var fieldId = that._extractFieldIdFromEvent(e);
			that.set("fieldId", fieldId);

			if (Y.one('#ui-is-mobile').get('value') == 'true') {

				Y.one("#body").addClass("ui-is-open-help-overlay");
				that.fire('appHelpRendered');

				this.overlays['app-help-overlay'].constrain = '#app-body';
				this.overlays['app-help-overlay'].width = Y.one('#app-body').getComputedStyle('width');
			}

			var overlay = that._overlay = that.showOverlay('app-help-overlay', {yOffset: 90}, null, 'modal--alternative');

			that._tabRow = that._createTabs(overlay);
			that._faqTab = that._createFaqTab(overlay);
			that._supportTab = that._createSupportTab(overlay);

			if (Y._currentState) {
				that._moreHelpTab = that._createMoreHelpTab(overlay);
				that._examplesTab = that._createExamplesTab(overlay);
				that._lexiconLinksTab = that._createLexiconLinksTab(overlay);

				that._loadAdvancedHelp(fieldId);
			}

			if (Y.one('#ui-is-mobile').get('value') == 'true') {
				//resize after close to normal resolution
				//the added tabs mess with the computed positioning, so we have to be safe here (mobile chrome)
				Y.later(400, this, function () {
					overlay.move(overlay.get("x"), 200);
					window.scrollTo(0, 0);

					overlay.onceAfter('visibleChange', function () {
						Y.one("#body").removeClass("ui-is-open-help-overlay");
						that.fire('appHelpClosed');
					});
				});
			}

			return overlay;
		},

		_openSupportOverlay: function (e) {
			var overlay = this._openOverlay(e);
			this._displayFaqTab(overlay);
		},


		_handleTabClicked: function (e) {
			e.halt();
			var name = e.currentTarget.getAttribute('href').substring(1);
			this._activateTab(this._overlay, name);
		},

		_handleLexiconLinkClicked: function (e) {
			this._activateTab(this._overlay, 'lexiconLinks');
			this._lexiconLinksTab.handleLexiconLinkClicked(e);
		},

		_displayFaqTab: function (overlay) {
			this._activateTab(overlay, "faq");
			this._hideFaqWaitingMask();
			this._registerOpenSupportTabListener();
		},

		_hideFaqWaitingMask: function () {
			var iframe = Y.one('#ui-app-help-faq-iframe');
			var waitingMask = Y.one('#ui-app-help-faq-iframe-waitingmask');

			var IFRAME_LOAD_TIMEOUT = 10000;

			var timeout = window.setTimeout(function () {
				waitingMask.addClass('app-dialog-waiting-wrapper-hidden');
			}, IFRAME_LOAD_TIMEOUT);

			iframe.once('load', function () {
				window.clearTimeout(timeout);
				waitingMask.addClass('app-dialog-waiting-wrapper-hidden');
			});
		},

		_registerOpenSupportTabListener: function () {
			var that = this;

			// bind() creates a new reference, which needs to be stored to allow removing of the event listener
			that._openSupportTabHandler = that._onOpenSupportTab.bind(this);
			window.addEventListener("message", that._openSupportTabHandler);

			that._overlay.on('visibleChange', function (event) {
				var overlayIsHidden = event.prevVal;
				if (overlayIsHidden) {
					window.removeEventListener("message", that._openSupportTabHandler);
				}
			});
		},

		_openSupportTabHandler: null,

		_onOpenSupportTab: function (event) {
			if (event.data === 'onse-open-support-tab') {
				this._activateTab(this._overlay, "support");
			}
		},

		_hideOverlay: function () {
			this._overlay.hide();
		}

	}, {
		ATTRS: {
			fieldId: {value: null}
		}
	});

}, '1.0.0', {
	requires: [
		'_advanced-help-repository',
		'_interview-field-repository',
		'app-overlays',
		'base-build',
		'event-base',
		'event-custom',
		'handlebars-view',
		'frame',
		'smart-handlebars-templates'
	]
});


var createHelpService = function (global) {

	var HelpService = {};

	var fieldRepro = global.sst.InterviewFieldRepository;
	var advancedHelpRepro = global.sst.AdvancedHelpRepository;

	HelpService.hasAdvanceHelp = function (fieldId) {
		var fieldDefinition = fieldRepro.getFieldDefinition(fieldId);
		return fieldDefinition && fieldDefinition.moreHelp == true;
	};

	HelpService.loadAdvanceHelp = function (fieldId, formIndex, multiFieldIndex, callback) {
		var fieldDefinition = fieldRepro.getFieldDefinition(fieldId);

		advancedHelpRepro.attachAdvancedHelpToField(fieldDefinition, formIndex, multiFieldIndex, callback);
	};

	return HelpService;

};
