YUI.add('case-editor-app-interview-locked-area', function(Y) {

    "use strict";

    /**
     * When charged certain areas shall be read-only to prevent the user from filing new cases for free.
     *
     * @type {CaseEditorAppInterviewLockedArea}
     */
    var LockedArea = Y.Base.create('CaseEditorAppInterviewLockedArea', Y.Base, [], {

        initializer: function() {
            Y.onceAfter('smartsteuer:ready', this._initialize, this);
        },

        _initialize: function() {
            Y.on('interview:rendered', function(e) {

	            // TODO: move down.
                Y.one('#case-interview-form').insert(this._getTemplate(), 'before');

                this._setReadOnlyFields(e.fields, e.state);

                if(e.state.disabledWhenCharged) {
                    Y.one('#ui-case-editor-area-locked-message').setStyle('display', 'block');
                }

            }, this);
        },

        _getTemplate : function() {
            return Y.HandlebarsTemplates.lookup('case-editor-interview-area-lock')();
        },

        _setReadOnlyFields : function(fields, pageState) {

            Y.each(fields, function (f) {

                if(pageState.disabledWhenCharged) {
                    f.set('readOnly', true);
                }
                else {
                    Y.each(pageState.forceReadOnly, function (fo) {
                        if(f.get('name') == fo) {
                            f.set('readOnly', true);
                        }
                    }, this);
                }
            }, this);
        }

    }, {
        ATTRS : {
        }
    });

    Y.CaseEditorAppInterviewLockedArea = new LockedArea()

}, '1.0.0', {
    requires:[
        'base-build',
        'event',
        'smart-handlebars-templates',
        'smst-lang',
        'view'
    ]
});