YUI.add('case-editor-app-interview-link-viewer', function(Y) {

    "use strict";

    /**
     * Show content, like a youtube video, in an overlay using an iframe.
     *
     * Examples for help text:
     *
     * <p><span class="fa fa-youtube-play">&nbsp;</span> <a class="ui-help-link-youtube" href="https://www.youtube.com/embed/nMMmEzximNo">Video ansehen</a></p>
     *
     * <p><span class="fa fa-file">&nbsp;</span> <a class="ui-help-link-document" href="https://www.smartsteuer.de/online/service/faq/">FAQ aufrufen</a></p>
     *
     */
     Y.LinkViewer = Y.Base.create('LinkViewer', Y.Base, [Y.AppOverlays], {

        overlays : {
            'app-video-overlay' : {
                header:"Mehr Informationen",
                template: 'case-editor-interview-overlay-videoviewer',
                footer:'',
                events : {},
                notEscapable : true,
                plugins : [
                    Y.gorilla.overlay.AnimPlugin,
                    Y.gorilla.widget.EscapeHidePlugin,
                    Y.gorilla.widget.XButtonPlugin
                ],
            },

            'app-link-overlay' : {
                header:"Mehr Informationen",
                template: 'case-editor-interview-overlay-linkviewer',
                footer:'',
                events : {},
                notEscapable : true,
                plugins : [
                    Y.gorilla.overlay.AnimPlugin,
                    Y.gorilla.widget.EscapeHidePlugin,
                    Y.gorilla.widget.XButtonPlugin
                ],
            }
        },

        initializer: function() {
            this._eventHandlers = [];

            this._eventHandlers.push(
                Y.one('#body').delegate('click', this._handleHelpLinkOverlay, '.ui-help-link-youtube', this)
            );
            this._eventHandlers.push(
                Y.one('#body').delegate('click', this._handleHelpLinkContentOverlay, '.ui-help-link-document', this)
            );
        },

         _handleHelpLinkContentOverlay : function(e) {
             e.halt();

             //better to initialize lazily so mobile can transform first
             this.overlays['app-link-overlay'].constrain = '#case-editor-main-body';
             this.overlays['app-link-overlay'].width = Y.one('#case-editor-main-body').getComputedStyle('width');

             var overlay = this._overlay = this.showOverlay(
                 'app-link-overlay',
                 {},
                 {link: e.currentTarget.get('href')}
             );

             //clean up so we don't just hide running videos
             this._eventHandlers.push(this._overlay.after('visibleChange', function() {
                 if(!this._overlay.get('visible')) {
                     if(Y.one('.ui-link-overlay-content-container')) {
                         Y.one('.ui-link-overlay-content-container').remove(true);
                     }
                 }
             }, this));
         },

        _handleHelpLinkOverlay : function(e) {
            e.halt();

            //better to initialize lazily so mobile can transform first
            this.overlays['app-video-overlay'].constrain = '#case-editor-main-body';
            this.overlays['app-video-overlay'].width = Y.one('#case-editor-main-body').getComputedStyle('width');

            var overlay = this._overlay = this.showOverlay(
                'app-video-overlay',
                {},
                {link: e.currentTarget.get('href')}
            );

            //clean up so we don't just hide running videos
            this._eventHandlers.push(this._overlay.after('visibleChange', function() {
                if(!this._overlay.get('visible')) {
                    if(Y.one('.ui-link-overlay-content-container')) {
                        Y.one('.ui-link-overlay-content-container').remove(true);
                    }
                }
            }, this));
        },

        destructor : function() {
            Y.Array.each(this._eventHandlers, function(it){
                it.detach();
            });
        }

    }, {
        ATTRS : {
        }
    });

}, '1.0.0', {
    requires:[
        'app-overlays',
        'base-build',
        'event',
        'smart-handlebars-templates',
        'smst-lang'
    ]
});